import React from 'react';
import AccordionItem from './AccordionItem';

const AccordionFaq = ({ id, title, content }) => {
  return (
    <AccordionItem
      key={id}
      title={title}
      content={content}
    />
  );
};

export default AccordionFaq;