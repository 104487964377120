import React from 'react'
import Image from '../../molecules/Images/Image'
import Heading from '../../molecules/Headings/Heading';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import privacyPolicyImage from '../../../assets/img/privacy-policy.jpg';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
const PrivacyPolicy = () => {
  return (
    <>
        <section className='position-relative innerBanner'>
            <Image src ={privacyPolicyImage} alt="About Banner" width="1920" height="450"  />
            <div className='bannerContent'>
            <Container>
                <Row>
                    <Col>
                        <Heading tagName="h1" className="h1" title="Privacy Policy" />
                        <Breadcrumbs />
                    </Col>
                </Row>
                
            </Container>
            </div>
        </section>
        <section className='sectionPolicy sectionTop'>
            <Container>
                <Row>
                    <Col className='col-12'>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Grownet Finance - Privacy Policy" />
                            <p className='mb-0'>Grownet Finance understands the importance of maintaining the privacy and security of personal information shared with us. This privacy policy applies to information we collect through our website, branches, associates, and partners for providing various financial services.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Information We Collect" />
                            <p>We collect personal identification data like name, date of birth, contact information, PAN details, etc. as submitted by you in loan/account opening applications and forms. We also collect financial information related to income, assets, existing loans/liabilities, etc. for assessing eligibility.</p>
                            <p>If you only browse our website, we automatically collect technical details related to visitors' browsers, operating systems, IP addresses, etc. This data does not identify individuals. If you provide personal information to access website services, its privacy is covered under this policy.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="How We Use Information " />
                            <p className='mb-0'>The personal and financial information collected from applicants and customers is used solely for loan application assessment, verification, underwriting, sanction process and establishment of lending relationships. We use aggregated and anonymized data for internal analytics and reporting. </p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Sharing of Information" />
                            <p className='mb-0'>We do not sell the personal data of customers. Personal information will only be shared with third parties such as credit bureaus, rating agencies, companies outsourced for verification purposes, etc. as part of the lending process and risk management. This is done on a strictly need-to-know basis to fulfill regulatory requirements. We may also share data for legal compliance if required under law or requested by government authorities.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Data Security" />
                            <p className='mb-0'>We use administrative, technical, and physical safeguards to maintain the security and confidentiality of personal data. We ensure reasonable security practices and procedures as per industry standards to protect against loss, misuse, unauthorized access etc. in storage as well as transmission of the data.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Changes in Privacy Policy" />
                            <p className='mb-0'>Any changes to the privacy policy will be posted on our website. Continued usage of products/services after policy changes constitutes acceptance of the modified privacy policy.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Data Access and Update Rights" />
                            <p className='mb-0'>At Grownet Finance, customers have rights under law to request access to their personal data, correction of any inaccurate data and restrictions regarding use of personal data. You may raise such data requests by contacting our privacy team at <a href='mailto:support@grownetfinance.com'>support@grownetfinance.com</a></p>    
                        </div>
                        <div>
                            <Heading tagName="h3" className="h3" title="Governing Law" />
                            <p>This privacy policy and practices of Grownet Finance shall be governed by the laws of India and adjudicated by the courts in <strong>Pune Maharashtra</strong> India. All disputes regarding privacy shall be resolved as per regulations laid down by the Reserve Bank of India and relevant laws of India.</p>    
                            <p className='mb-0'>Please direct any questions, concerns, disputes, or complaints regarding this privacy policy or use/sharing of personal information to our privacy team at the email provided above. The team shall respond to queries within 30 days via email or letter.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}

export default PrivacyPolicy
