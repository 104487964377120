import React from 'react'
import Card from './Cards';
import travelImageOne from '../../../assets/img/travel-1.svg';
import travelImageTwo from '../../../assets/img/travel-2.svg';
import travelImageThree from '../../../assets/img/travel-3.svg';
import travelImageFour from '../../../assets/img/travel-4.svg';
const loanCardDetails = [
    {
        id:1,
        title: 'Freedom to Explore: ',
        description: 'With our Travel Loan, you have the financial freedom to explore the destinations you have always dreamed of without compromise.',
        imageUrl: travelImageOne,
 
    },
    {
        id:2,
        title: 'Budget-friendly Travel: ',
        description: 'Travel on your terms without breaking the bank. Our competitive interest rates & transparent fee structure make your journey exciting & budget-friendly.',
        imageUrl: travelImageTwo,
    },
    {
        id:3,
        title: 'Easy Financial Planning: ',
        description: 'Our Travel Loan empowers you to plan your trip with clear and concise financial information, allowing you to make informed decisions.',
        imageUrl: travelImageThree,
      },
      
      {
        id:4,
        title: 'Memories, Not Worries: ',
        description: 'Focus on creating memories, not worrying about finances. Our travel financing is designed to allow you to savor every moment of your adventure stress-free.',
        imageUrl: travelImageFour,
      },
      
]
const LoanCardDetails = () => {
  return (
    <>
        {loanCardDetails.map((card, index) => (
            <Card key={index} {...card} />
        ))}
    </>
  )
}

export default LoanCardDetails
