import React from 'react'
import '../Services/Services.css'
import Image from '../../molecules/Images/Image'
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import aboutBannerImage from '../../../assets/img/servicesImage.jpg';
import Heading from '../../molecules/Headings/Heading';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import HomeHowItWorks from '../HomePage/HomeHowItWorks/HomeHowItWorks';
import ServiceData from '../../molecules/ServicesCard/ServiceData';
const Services = () => {
  return (
    <>
      
      <section className='position-relative innerBanner'>
        <Image src ={aboutBannerImage} alt="About Banner" width="1920" height="450"  />
        <div className='bannerContent'>
          <Container>
            <Row>
              <Col>
                <Heading tagName="h1" className="h1" title="Services" />
                <Breadcrumbs />
              </Col>
            </Row>
            
          </Container>
        </div>
      </section>
      <section className="srvicesSection sectionBottom">
        <Container>
          
            <ServiceData />
          
        </Container>
      </section>
      <HomeHowItWorks />
    </>
  )
}

export default Services
