import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';

const MenuItem = ({ item }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  return (
    <>
      <li>
        <Link
          to={item.url}
          key={item.id}
          onClick={handleDropdownClose}
        >
          {item.text}
        </Link>
        {item.submenu && (
          <NavDropdown
            show={isDropdownOpen}
            id={`dropdown-${item.id}`}
            onMouseEnter={handleDropdownToggle}
            onMouseLeave={handleDropdownClose}
          >
            {item.submenu.map((subItem) => (
              <Link
                key={subItem.id}
                to={subItem.url}
                className="dropdown-item"
                onClick={handleDropdownClose}
              >
                {subItem.text}
              </Link>
            ))}
          </NavDropdown>
        )}
      </li>
    </>
  );
};

export default MenuItem;
