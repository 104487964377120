import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Heading from '../../../molecules/Headings/Heading'
import Image from '../../../molecules/Images/Image'
import loanEligibilityImage from '../../../../assets/img/loanEligibility.jpg'
// import { Link } from 'react-router-dom'
// import EligibilityForm from '../../../molecules/Form/Eligibility/EligibilityForm'


const HomeCheckYourEligibility = () => {
    return (
        <section className='Eligibility '>
            <Image src ={loanEligibilityImage} alt="About Banner" width="1920" height="515"  />
            <div className='bannerContent'>
                <Container >
                    <Row className='justify-content-center'>
                        <Col className='text-center col-12'>
                            <Heading tagName="h2" className="h2" title="Discover your  loan eligibility now!" />
                            {/* <EligibilityForm /> */}
                            <div className='mt-3'><a href="/check-your-eligibility/" className="btnfilled button-2 btn-custom">Check Your Eligibility</a></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}

export default HomeCheckYourEligibility
