import React from 'react'
import Col from 'react-bootstrap/Col';
import Image from '../../molecules/Images/Image';
import Heading from '../Headings/Heading';

const Team = ({ title, description, imageUrl, onClick  }) => {

  return (
    <>
      <Col className='col-12 col-lg-4 col-md-6 teamCards card border-0' onClick={onClick}>
        <div className='teamInner card-body '>  
            <figure className='mb-0'><Image src={imageUrl} alt={title} className="teamIimage" /></figure>
            <div className='teamDescription'>
                <Heading tagName="h5" className="h5" title={title} />
                <p>{description}</p>
            </div>
        </div>
      </Col>
     </>
  )
}

export default Team
