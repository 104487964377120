
import React, { useState, useEffect } from 'react';
import AccordionItem from '../Accordion/AccordionItem';
import { useQuery } from 'react-query';
// import { useParams } from 'react-router-dom';
import axios from 'axios';
const fetchData = async () => {
  const formFaq = new FormData();
  formFaq.append('service_id', localStorage.getItem('serviceId'));
// http://demo.yironchat.com/api/Whatsapp_busniess/get_campaigns
  try {
    const response = await axios.post('https://api.grownetfinance.com/api/services-faq', formFaq, 
    // {
    //   headers: {
    //     'Authorization': process.env.REACT_APP_SECRET_KEY,
    //   },
    // }
    );

    
    if (!response.data) {
      throw new Error('Empty response data');
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error.message);
    throw new Error('No Data Found');
  }
};
const Accordion = () => {
  
  const { data: servicesFaq, isLoading, error } = useQuery('services-faq', () => fetchData(), {
    refetchOnWindowFocus: false,
  });

  const [allServicesFaq, setAllServicesFaq] = useState([]);

  useEffect(() => {
    if (servicesFaq) {
      setAllServicesFaq(servicesFaq.data);
    }
  }, [servicesFaq]);

  
  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="accordion-main">
      <div className="accordion">
        
        {allServicesFaq.length > 0 ? (
          allServicesFaq.map((item) => (
            <AccordionItem
              key={item.id}
              title={item.question}
              content={item.answer}
            />
          ))
        ) : (
          <p>No data found</p>
        )}

      </div>
    </div>
  );
};

export default Accordion;
