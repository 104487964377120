import React, { useState, useEffect } from 'react';
import SelectField from '../../../atoms/Select/SelectField ';
import useRequest from '../../../../useRequest';

const StateDropdown = ({  onChange, value,disabled }) => {
  const { data: statesList, sendRequest, isLoading: queryLoading, error: queryError } = useRequest();
  const [states, setStates] = useState([]);
  useEffect(() => { 
    sendRequest({ path: '/country-state', key: 'country-state',
    // headers: {
    //   'Custom-Header': 'header-value',
    // },
     });
  }, [sendRequest]);
  
  useEffect(() => {
    if (statesList) {
      // const filteredStates = statesList.data.filter((state) => state.country_id === selectedCountry);
      setStates(statesList.data);
    }
  }, [statesList, ]);

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    console.log('state:', selectedState)
    if (onChange && typeof onChange === 'function') {
      onChange(selectedState);
    } else {
      console.error('onChange is not a valid function');
    }
  };

  if (queryLoading) {
    return <p>Loading...</p>;
  }

  if (queryError) {
    return <p>Error: {queryError.message}</p>;
  }

  return (
    <SelectField
      label={<span>State<span style={{ color: 'red' }}>*</span></span>}
      id="state"
      disabled={disabled}
      className="firstName"
      options={[
        { label: 'Select an state', value: ' ' },

        ...states.map((state) => ({ label: state.name, value: state.id })),
      ]}
      value={value}
      onChange={handleStateChange}
    />
  );
};

export default StateDropdown;
