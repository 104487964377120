import React, { useState, useEffect } from 'react';
import Seo from './Seo';
import useRequest from '../../useRequest';
import { links } from '../Constant';
import { Helmet } from 'react-helmet';


const PageList = ({ currentRoute }) => {
    const { data: seoDataList, sendRequest, isLoading: queryLoading, error: queryError } = useRequest();
    const [seoData, setSeoData] = useState([]);

    useEffect(() => {
        sendRequest({ path: '/seo-data-list', key: 'seo-data-list', 
            // headers: {
            //     'Custom-Header': 'header-value',
            // },
         });
    }, [sendRequest]);

    // useEffect(() => {
    //     if (!queryLoading && !queryError && seoDataList) {
    //         if (Array.isArray(seoDataList.data)) {
    //             const filteredSeoData = seoDataList.data.filter((item) => item.permalink === currentRoute);
    //             if (Array.isArray(filteredSeoData) && filteredSeoData.length > 0) {
    //                 setSeoData(filteredSeoData);
    //             } 
    //         } else {  
    //         }
    //     }
    // }, [queryLoading, queryError, seoDataList, currentRoute]);
    // useEffect(() => {
    //     if (!queryLoading && !queryError && seoDataList) {
    //         const filteredSeoData = seoDataList.data.filter((item) => item.permalink === currentRoute);
    //         setSeoData(filteredSeoData);
             
    //     }
    // }, [queryLoading, queryError, seoDataList, currentRoute]);
    useEffect(() => {
        if (!queryLoading && !queryError && seoDataList) {
            const filteredSeoData = seoDataList.data.filter((item) => item.permalink === currentRoute);
            //console.log("Filtered SEO Data:", filteredSeoData); // Add this line for debugging
            setSeoData(filteredSeoData);
        }
    }, [queryLoading, queryError, seoDataList, currentRoute]);
    
    if (queryLoading) {
        return <p>Loading...</p>;
    }

    if (queryError) {
        //console.error('Error:', queryError.message);
        return <p>Error: {queryError.message}</p>;
    }

    if (seoData.length === 0) {
        return null;
        
    }

    const currentLink = links.find((link) => link.url === currentRoute);
    const currentPageData = seoData[0];

    //console.log("Current Page Data:", currentPageData); 
    return (
        <>
            {currentLink && currentPageData && (
                <>
                <Helmet>
                    <title>
                        {/* {currentPageData.page_title} */}
                        {currentPageData.page_title.length > 75 ? 
                            `${currentPageData.page_title.substring(0, 75)}...` :
                            currentPageData.page_title
                        }
                    </title>
                </Helmet>
                <Seo 
                    key={currentPageData.id}
                    permalink={currentPageData.permalink}
                    description={currentPageData.meta_description}
                    keywords={currentPageData.meta_keywords}
                    canonicalUrl={currentPageData.canonical_url}  
                    ogTitle={currentPageData.og_title} 
                    ogDescription={currentPageData.og_description}
                    ogImage={currentPageData.og_image} 
                    ogUrl={currentPageData.og_url}  
                    type={currentPageData.og_type} 
                />
                </>
            )}
        </>
    );
};

export default PageList;
