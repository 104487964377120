import React from 'react'
import '../UspSection/UspSection.css'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Heading from '../../../molecules/Headings/Heading'

import UspCardDeatils from '../../../molecules/UpsCard/UspCardDeatils'


const UspSection = () => {
  return (
    <>
      <div className='uspHome sectionPadding'>
        <Container>
          <Row className='usp--inner'>
              <Col md={9} className='text-center'>
                  <Heading tagName="h2" className="h2" title="Our USP: Why Choose Grownet?" />
                  <p>The USP of loans lies in our commitment to a customer-centric approach, tailoring financial solutions to individual needs.</p>
              </Col>
          </Row>
          <Row  className='mt-4 uspCard'>
            <UspCardDeatils />
          </Row>
        </Container>
      </div>
    </>
  )
}

export default UspSection
