import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Image from '../Images/Image'
import Heading from '../Headings/Heading'
import { Link } from 'react-router-dom'
const FinancialCard = ({title, description, imageUrl, linkTo}) => {
  return (
    <>
      <Col className='financialCards card border-0 col-12 col-lg-3 col-md-6 my-4'>
        <div className='financialInner card-body text-center'>
            <figure ><Image src={imageUrl} alt={title} className="cardIcon" /></figure>
            <Heading tagName="h6" className="h6" title={title} />
            <p>{description}</p>
            <div className='w-100 buttonCards'>
                <Link to={linkTo} className='btnfilled btn-custom'>Go To Calculator</Link>
            </div>
        </div>
      </Col>
    </>
  )
}

export default FinancialCard
