import React from 'react'
import AccordionFaq from './AccordionFaq';
const rdCalculatorFaqData = [
    { 
        id:1, 
        title: 'How does the RD Calculator work?', 
        content: 'The calculator uses your monthly deposit, interest rate, tenure, and compounding frequency to project the maturity amount of your recurring deposit.' 
    },
    { 
        id:2, 
        title: 'Can I use the calculator for different RD schemes?', 
        content: "Yes, the calculator is versatile and can be used for various recurring deposit schemes, allowing you to tailor it to your specific financial institution's terms." 
    },
    { 
        id:3, 
        title: 'Can I adjust the tenure to explore different savings scenarios?', 
        content: "Yes, the calculator allows you to easily modify the tenure, providing instant updates on how changes impact the maturity amount of your recurring deposit."
    },
    { 
        id:4, 
        title: 'How accurate are the interest rate projections in the RD Calculator?', 
        content: "The calculator provides accurate interest rate projections based on the inputted variables. For the latest interest rates, consider checking with the bank or contact our support team." 
    },
  
];
const RDCalculatorFaqData = () => {
  return (<>{rdCalculatorFaqData.map((item)=>(
    <AccordionFaq key={item.id} title={item.title} content={item.content} />
  ))}</>)
}

export default RDCalculatorFaqData
