import React, { useState } from 'react';
import arrowDownImage from '../../../assets/img/arrowDown.svg'
import arrowRightImage from '../../../assets/img/arrowRight.svg'
import Image from '../../molecules/Images/Image';
import Heading from '../../molecules/Headings/Heading';
const AccordionItem = ({ title, content, isFirstItem  }) => {
  const [isOpen, setIsOpen] = useState(isFirstItem );

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={toggleAccordion}>
        <Heading tagName="h5" className="h5" title={title} />
        <span>{isOpen ? <Image src={arrowDownImage} alt="arrow" className="arrowImage"/>:  <Image src={arrowRightImage} alt="arrow" className="arrowImage"/>}</span>
      </div>
      {isOpen && 
        <div className="accordion-content">
            <p>{content}</p>
        </div>
    }
    </div>
  );
};
export default AccordionItem
