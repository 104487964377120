import React, { useState } from 'react'
import axios from 'axios'
import Input from '../../../atoms/Input/Input'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import LoanType from '../Eligibility/LoanType'
// import CountryDropdown from '../CountryDropdown/CountryDropdown'
import StateDropdown from '../StateDropdown/StateDropdown'
import CityDropdown from '../CityDropdown/CityDropdown'
import { Link } from 'react-router-dom'
import Button from '../../../atoms/Button/Button'
// import ReCAPTCHA from 'react-google-recaptcha';
const AppointmentForm = () => {
  const [appointmentData, setAppointmentData] = useState({
    full_name: '',
    email: '',
    appointment_date: '',
    appointment_time: '',
    address: '',
    loan_type: '',
    phone_number: '',
    country_name: '',
    state: '',
    city: '',
    zip_code: '',
    date_of_birth: '',
    accepted_terms_and_conditions: false,
  })
  const [errorMessages, setErrorMessages] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false); 
  const [selectedState, setSelectedState] = useState('');
  // const [captchaValue, setCaptchaValue] = useState('');

  const handleStateChange = (selectedState) => {
    setSelectedState(selectedState);

    setAppointmentData((prevData) => ({
      ...prevData,
      state: 'selectedState',
    }));
    appointmentHandler({
      target: {
        name: 'state',
        value: selectedState,
      },
    });
  };
  const handleCityChange = (selectedCity) => {

    setAppointmentData((prevData) => ({
      ...prevData,
      city: 'selectedCity',
    }));

    appointmentHandler({
      target: {
        name: 'city',
        value: selectedCity,
      },
    });
  };

  const appointmentHandler = (e) => {
    const { name, value, type, checked } = e.target;
    //console.log('Checkbox state:', checked)
    if (type === 'checkbox') {
      setAppointmentData((prevData) => ({
        ...prevData,
        [name]: checked ? 1 : 0,
      }));
    } else {
      const newValue = type === 'checkbox' ? checked : e.target.value;
      
      if (name === 'date_of_birth') {
        const selectedDate = new Date(newValue);
        const currentDate = new Date();
        //console.log('Error condition met:', selectedDate, currentDate);
        if (selectedDate > currentDate) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [name]: 'Please select a date of birth that is not in the future.',
          }));
          setSuccessMessage('');
          return;
        }
      }

      if (name === 'appointment_date') {
        const selectedDate = new Date(newValue);
        const currentDate = new Date();

        if (selectedDate <= currentDate) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [name]: 'Please select a future date for the appointment.',
          }));
          setSuccessMessage('');
          return;
        }
      }

      setAppointmentData((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
    }
    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setErrorMessages((prevErrors) => ({
          ...prevErrors,
          [name]: emailRegex.test(value) ? '' : 'Invalid email format.',
      }));
  } else {
      setErrorMessages((prevErrors) => ({
          ...prevErrors,
          [name]: value.trim() ? '' : `${name.replace('_', ' ')} is required.`,
      }));
  }

  setSuccessMessage('');

    // setErrorMessages((prevErrors) => ({
    //   ...prevErrors,
    //   [name]: e.target.value.trim() ? '' : `${name.replace('_', ' ')} is required.`,
    // }));


    setSuccessMessage('');
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (loading) return; 
    setLoading(true);
    // if (!captchaValue) {
    //   alert('Please complete the CAPTCHA.');
    //   return;
    // }
    const requiredFields = ['full_name', 'email', 'appointment_date', 'appointment_time', 'loan_type', 'phone_number', 'state', 'city', 'zip_code'];
    const newErrorMessages = {};

    requiredFields.forEach((field) => {
      const value = appointmentData[field];
      if (typeof value === 'string' && !value.trim()) {
        newErrorMessages[field] = `${field.replace(/_/g, ' ')} is required.`;
      }
    });

    if (!appointmentData.accepted_terms_and_conditions) {
      newErrorMessages['accepted_terms_and_conditions'] = 'You must agree to the terms and conditions to submit the form.';
    }

    setErrorMessages(newErrorMessages);

    if (Object.keys(newErrorMessages).length > 0) {
      setLoading(false);
      setSuccessMessage('');
      return;
    }
    try {
      const formAppointmentData = new FormData();
      formAppointmentData.append('full_name', appointmentData.full_name);
      formAppointmentData.append('email', appointmentData.email);
      formAppointmentData.append('appointment_date', appointmentData.appointment_date);
      formAppointmentData.append('appointment_time', appointmentData.appointment_time);
      formAppointmentData.append('loan_type', appointmentData.loan_type);
      formAppointmentData.append('phone_number', appointmentData.phone_number);
      formAppointmentData.append('state', appointmentData.state);
      formAppointmentData.append('city', appointmentData.city);
      formAppointmentData.append('zip_code', appointmentData.zip_code);
      // formAppointmentData.append('accepted_terms_and_conditions', appointmentData.accepted_terms_and_conditions);
      formAppointmentData.append('accepted_terms_and_conditions', String(appointmentData.accepted_terms_and_conditions));


      const response = await axios.post('https://api.grownetfinance.com/api/appointment', formAppointmentData,
      // {
      //   headers: {
      //     'Authorization':process.env.REACT_APP_SECRET_KEY, 
      //   },
      // }
      );


      if (response.data && response.data.status === false) {
        const messagesArray = response.data.message || [];
        const errorMessagesObj = messagesArray.reduce((acc, message, index) => {
          acc[index.toString()] = message;
          return acc;
        }, {});
        setErrorMessages(errorMessagesObj);
        setSuccessMessage('');
      } else {
        setErrorMessages({});
        setSuccessMessage('Form submitted successfully!');
        setAppointmentData({ full_name: '', email: '', appointment_date: '', appointment_time: '',  loan_type: '', phone_number: '',  state: '', city: '', zip_code: '', accepted_terms_and_conditions: '', });
      }
    } catch (error) {
      console.error('API Error:', error);
      setErrorMessages({ general: 'An error occurred while submitting the form.' });
      setSuccessMessage('');
    }finally {
      setLoading(false);
    }
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setAppointmentData({
      full_name: '',
      email: '',
      appointment_date: '',
      appointment_time: '',
      loan_type: '', 
      phone_number: '',
      state: '',
      city: '',
      zip_code: '',
      accepted_terms_and_conditions: false,
    });
    setErrorMessages({});
    setSuccessMessage('');
  };


  return (
    <>
      <Row>
        <Col className='col-12' >
          <form className='appointmentForm'>
            <div className='input-group first' >

              <Input
                type="date"
                name="appointment_date"
                value={appointmentData.appointment_date}
                onChange={appointmentHandler}
                label={<span>Appointment Date<span style={{ color: 'red' }}>*</span></span>}
                
              />
              {errorMessages.appointment_date && (
                <p className='errormessage'>{errorMessages.appointment_date}</p>
              )}
            </div>
            <div className='input-group last' >
              <Input
                type="time"
                name="appointment_time"
                value={appointmentData.appointment_time}
                onChange={appointmentHandler}
                label={<span>Appointment Time<span style={{ color: 'red' }}>*</span></span>}
              />
              {errorMessages.appointment_time && (
                <p className='errormessage'>{errorMessages.appointment_time}</p>
              )}
            </div>

            <div className='input-group first' >
              <Input
                type="text"
                name="full_name"
                placeholder="Full Name*"
                value={appointmentData.full_name}
                onChange={appointmentHandler}
                label={<span>Full Name<span style={{ color: 'red' }}>*</span></span>}
              />
              {errorMessages.full_name && (
                <p className='errormessage'>{errorMessages.full_name}</p>
              )}
            </div>
            <div className='input-group last' >
              <Input
                type="text"
                name="email"
                placeholder="Enter Your Email Address*"
                onChange={appointmentHandler}
                value={appointmentData.email}
                label={<span>Email<span style={{ color: 'red' }}>*</span></span>}
              />
              {errorMessages.email && (
                <p className='errormessage'>{errorMessages.email}</p>
              )}
            </div>

            <div className='input-group first' >
              <Input
                type="text"
                name="phone_number"
                placeholder="Contact number*"
                //onChange={appointmentHandler}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^\d+]/g, '');
                  const truncatedValue = numericValue.slice(0, 14);
                  appointmentHandler({
                      target: {
                          name: 'phone_number',
                          value: truncatedValue,
                      },
                  });
              }}
                value={appointmentData.phone_number}
                label={<span>Phone No<span style={{ color: 'red' }}>*</span></span>}
              />
              {errorMessages.phone_number && (
                <p className='errormessage'>{errorMessages.phone_number}</p>
              )}
            </div>
            <div className='input-group last' >
              <LoanType
                value={appointmentData.loan_type}
                onChange={(selectedLoanType) => appointmentHandler({ target: { name: 'loan_type', value: selectedLoanType } })}
              />
              {errorMessages.loan_type && (
                <p className='errormessage'>{errorMessages.loan_type}</p>
              )}
            </div>

            
            <div className='input-group first' >
              <Input
                type="text"
                name="zip_code"
                placeholder="Pin Code"
                //onChange={appointmentHandler}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, '');
                  const truncatedValue = numericValue.slice(0, 6);
                  appointmentHandler({
                      target: {
                          name: 'zip_code',
                          value: truncatedValue,
                      },
                  });
              }}
                value={appointmentData.zip_code}
                label={<span>Pin Code<span style={{ color: 'red' }}>*</span></span>}

              />
              {errorMessages.zip_code && (
                <p className='errormessage'>{errorMessages.zip_code}</p>
              )}

            </div>
            
            
              <div className='input-group last'>
                <Input
                  type="hidden"
                  name="email"
                  value="101"
                />
                
                <StateDropdown
                  value={appointmentData.state}
                 
                  onSelectState={(selectedState) => {
                    handleStateChange(selectedState);
                    appointmentHandler({ target: { name: 'state', value: selectedState } });
                  }}
                  onChange={(selectedState) => handleStateChange(selectedState)}
                />
                {errorMessages.state && (
                  <p className='errormessage'>{errorMessages.state}</p>
                )}
              </div>
           
              <div className='input-group first'>

                <CityDropdown
                  value={appointmentData.city}
                  selectedState={selectedState}
                  onSelectCity={(selectedCity) => {
                    handleCityChange(selectedCity);
                    appointmentHandler({ target: { name: 'city', value: selectedCity } });
                  }}
                  onChange={(onSelectCity) => handleCityChange(onSelectCity)}
                  
                />
                {errorMessages.city && (
                  <p className='errormessage'>{errorMessages.city}</p>
                )}
              </div>
          
           
            <div className='input-group checkbox d-flex' style={{flexWrap:'nowrap'}}>
            <label><input
                type="checkbox"
                name="accepted_terms_and_conditions"
                checked={appointmentData.accepted_terms_and_conditions}
                onChange={appointmentHandler}
              />
              
                <span>
                  I agree to the{' '}
                  <Link to="/terms-and-condition" rel="terms & conditions" target="_blank">
                    terms & conditions
                  </Link>{' '}
                  and{' '}
                  <Link to="/privacy-policy" rel="privacy policy" target="_blank">
                    privacy policy
                  </Link>
                </span>
              
              {errorMessages.accepted_terms_and_conditions && (
                <p className='errormessage'>{errorMessages.accepted_terms_and_conditions}</p>
              )}</label>
            </div>
            {/* <div className='input-group' >
              <ReCAPTCHA
                  sitekey="your-recaptcha-site-key"
                  onChange={(value) => setCaptchaValue(value)}
              />
            </div> */}
            <div className='input-group submitButton mb-0' >
              <Button 
                type="submit"
                label={loading ? 'Submitting...' : 'Submit'}
                className="submit me-4"
                onClick={submitHandler}
                disabled={loading}
              />
              <Button onClick={resetHandler} label="Reset" className="reset" />
            </div>
            {successMessage && (
              <div className='succesMessage'>
                {successMessage}
              </div>
            )}
          </form>
        </Col>
      </Row>
    </>
  )
}

export default AppointmentForm
