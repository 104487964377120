import React from 'react'
import Col from 'react-bootstrap/Col';
import Image from '../../molecules/Images/Image';
import Heading from '../Headings/Heading';
const Card = ({ title, description, imageUrl, phoneNumber }) => {
  return (
    <>
      <Col className='productsCards card border-0 '>
        <div className='cardsInner card-body '>
          <div className='productsTitle'>
            <Image src={imageUrl} alt={title} className="cardIcon" />
            <Heading tagName="h5" className="h5" title={title} />
          </div>
          <p>{description}</p>
          <p>{phoneNumber}</p>
        </div>
      </Col>
    </>
  )
}

export default Card
