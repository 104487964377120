import React from 'react'
import Heading from '../../molecules/Headings/Heading'
import { Link } from 'react-router-dom'
const ServicesLinks = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className='footerLinks'>
      <Heading className="h4" tagName="h4" title="Services" />
      <ul className='links'>
        <li><Link to="/services/travel-loan" onClick={scrollToTop} >Travel Loan </Link></li>
        <li><Link to="/services/education-loan" onClick={scrollToTop} >Education Loan</Link></li>
        <li><Link to="/services/salary-advance" onClick={scrollToTop}>Salary Advance </Link></li>
        <li><Link to="/services/credit-card-balance-transfer" onClick={scrollToTop}>Credit Card Balance Transfer</Link></li>
      </ul>
    </div>
  )
}

export default ServicesLinks