
import React, {useState, useEffect} from 'react'
import Heading from '../Headings/Heading';
import Image from '../Images/Image';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import useRequest from '../../../useRequest';

const WorkPageCard = () => {
  const { data: howItWorks, sendRequest, isLoading: queryLoading, error: queryError } = useRequest();
  useEffect(() => {
    
    sendRequest({ path: '/how-it-work-step-list', key: 'how-it-work-step-list',
    // headers: {
    //   'Custom-Header': 'header-value',
    // },
     });
  }, [sendRequest]);
  const [allHowItWorksPage, setAllHowItWorksPage]= useState([]);
  useEffect(()=>{
      if(howItWorks){
        setAllHowItWorksPage(howItWorks.data)
      }
  }, [howItWorks]);
  if (queryLoading) {
    return <p>Loading...</p>;
  }

  if (queryError) {
      return <p>Error: {queryError.message}</p>;
  }
  return (
    <>
      {allHowItWorksPage.length > 0 && allHowItWorksPage.map((item)=>(
        <Row className='align-items-center sectionBottom' key={item.id}>
            <Col className='workCards card border-0 col-12 col-md-6 '>
                <div className='workpageInner card-body text-center'>
                    <Image src={item.action_image} alt={item.heading} className="cardIcon" />
                    
                </div>
            </Col>
            <Col className='workCards card border-0 col-md-6 '>
                <Heading tagName="h2" className="h2" title={item.heading} />
                <Heading tagName="h5" className="h5" title={item.sub_heading} />
                <p>{item.information}</p>
            </Col>
        </Row>
      ))}
    </>
  )
}

export default WorkPageCard
