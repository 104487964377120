import React from 'react'
import Heading from '../../molecules/Headings/Heading'
import { Link } from 'react-router-dom'
const FinancialToolsLinks = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className='footerLinks'>
      <Heading className="h4" tagName="h4" title="Financial Tools" />
      <ul className='links'>
        <li><Link to="/check-your-eligibility" onClick={scrollToTop}>Check Your Eligibility</Link></li>
        <li><Link to="/financial-tools/home-loan-emi-calculator"  onClick={scrollToTop}>Home Loan EMI Calculator </Link></li>
        <li><Link to="/financial-tools/business-loan-emi-calculator"  onClick={scrollToTop}>Business Loan EMI Calculator  </Link></li>
        <li><Link to="/financial-tools/compound-interest-calculator" onClick={scrollToTop} >Compound Interest Calculator</Link></li>
        <li><Link to="/financial-tools/loan-prepayment-calculator" onClick={scrollToTop} >Loan Prepayment Calculator </Link></li>
        <li><Link to="/financial-tools/rd-calculator" onClick={scrollToTop} >RD Calculator</Link></li>
        <li><Link to="/financial-tools/fd-calculator" onClick={scrollToTop} >FD Calculator</Link></li>
      </ul>
    </div>
  )
}

export default FinancialToolsLinks
