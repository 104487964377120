import React, { useState } from 'react';
import Input from '../../../atoms/Input/Input';
import Button from '../../../atoms/Button/Button';
import axios from 'axios';
import iconCheck from '../../../../assets/img/icon-check.svg'
import closeCircleImage from '../../../../assets/img/close-circle-otp.svg'
import Image from '../../Images/Image'
const ValidateOTP = ({ email }) => {
  const [enteredOTP1, setEnteredOTP1] = useState('');
  const [otpErrorMessages1, setOtpErrorMessages1] = useState({});
  const [otpSuccessMessage1, setOTPSuccessMessage1] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValidationDone1, setIsValidationDone1] = useState(false);

  const validateOTPHandler = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const requiredFields = ['enteredOTP1'];
    const newErrorMessages = {};

    requiredFields.forEach((field) => {
      const value = enteredOTP1.trim();
      if (!value) {
        newErrorMessages[field] = `${field.replace(/_/g, ' ')} is required.`;
      }
    });

    if (Object.keys(newErrorMessages).length > 0) {
      setOtpErrorMessages1(newErrorMessages);
      return;
    }

    try {
      const validateOTPFormData = new FormData();
      validateOTPFormData.append('otp', enteredOTP1);
      validateOTPFormData.append('email', email);
      const response = await axios.post('https://api.grownetfinance.com/api/validate-otp', validateOTPFormData,
      // {
      // headers: {
      //   'Authorization':process.env.REACT_APP_SECRET_KEY, 
      // },
      // }
      );
      
      if (response.status === 200 ) {
        setIsLoading(false);
        if (response.data.status === true) {
          setOTPSuccessMessage1(response.data.message);
          setOtpErrorMessages1({});
          setIsValidationDone1(true);
          //onValidateOTP();
      
          
        } else if (response.data.status === false) {
          setIsLoading(false);
          setOtpErrorMessages1({ otp: response.data.message });
          setIsValidationDone1(true);
          
        } else {
          setIsLoading(false);
          throw new Error(response.data.message);
        }

      } else {
        setIsLoading(false);
        throw new Error('Failed to validate OTP');
      }
    } catch (error) {
      setIsLoading(false);
      if (axios.isAxiosError(error)) {
        console.error('API Error:', error.response?.data || error.message);
      } else {
        console.error('Error:', error.message);
      }
    }
  };
  return (
    <>
      {isLoading? "Loading...":
        <>
          <div className='position-relative withicon w-100'>
            <Input
              type="hidden"
              name="email"
              value={email}
            />
            <Input
              type="text"
              name="otp"
              placeholder="Enter OTP*"
              onChange={(e) => setEnteredOTP1(e.target.value)}
              value={enteredOTP1}
              label={<span>OTP <span style={{ color: 'red' }}>*</span></span>}
              disabled={isValidationDone1}
            />
            {otpSuccessMessage1 && !otpErrorMessages1.otp && (
              <Image src={iconCheck} alt="Check Icon" className="check-icon" />
            )}
            {otpErrorMessages1.otp && !otpSuccessMessage1  && (
              <Image src={closeCircleImage} alt="Check Icon" className="check-icon" />
            )}
            
          </div>
          {enteredOTP1 && (
            <Button
              onClick={validateOTPHandler}
              label="Validate OTP"
              className="submit"
              disabled={otpSuccessMessage1 || isValidationDone1}
            />
          )}

          {otpErrorMessages1.otp && (
            <p className={`message errormessageApply`}>{otpErrorMessages1.otp}</p>
          )}
          {otpSuccessMessage1 && (
            <p className={`message successMessageApply`}>{otpSuccessMessage1}</p>
          )}
        </>
      }

     

    </>
  );
}

export default ValidateOTP;
