import React from 'react'
import { Link } from 'react-router-dom'

const Image = ({src, alt,height, width, link,className }) => {
  return (
    <>
      {link ? <Link to={link}><img src={src} alt={alt} className={className} width={width} height={height} /></Link> : <img src={src} alt={alt} className={className} width={width} height={height} />}
    </>
  )
}

export default Image
