import React from 'react'
import UspCard from './UpsCard'

const servicesSingleCard=[
    {
        id:1,
        title: 'Customized Travel Loans: ',
        description: 'The tailored travel financing that aligns perfectly with your travel plans.',
    },
    {
        id:2,
        title: 'Flexible Repayment Options:',
        description: 'We give you the freedom to choose a schedule that fits seamlessly into your budget and lifestyle.',
    },
    {
        id:3,
        title: 'Online Account Management: ',
        description: 'Manage your Travel Loan effortlessly through our online account management platform.',
    },
    {
        id:4,
        title: 'No Collateral Required: ',
        description: 'Get Travel Loans without any collateral & ensure an unhindered journey devoid of any complex financial requirements.',
    },
]
const ServicesSingleCard = () => {
  return (
    <>
      {servicesSingleCard.map((item, index)=>(
        <UspCard key={index} {...item}/>
      ))}
    </>
  )
}

export default ServicesSingleCard
