
import React, {useState, useEffect} from 'react'

import CardWork from './CardWork';
import useRequest from '../../../useRequest';

const WorkData = () => {

  const { data: howItList, sendRequest, isLoading: queryLoading, error: queryError } = useRequest();
    useEffect(() => {
      sendRequest({ path: '/how-it-work-step-list', key: 'how-it-work-step-list',
      // headers: {
      //   'Custom-Header': 'header-value',
      // },
       });
    }, [sendRequest]);
  const [allHowItWorks, setAllHowItWorks]= useState([]);
  useEffect(()=>{
      if(howItList){
        setAllHowItWorks(howItList.data)
      }
  }, [howItList]);
  if (queryLoading) {
    return <p>Loading...</p>;
  }

  if (queryError) {
      return <p>Error: {queryError.message}</p>;
  }
  return (
    <>
      {allHowItWorks.length > 0 && allHowItWorks.map((item)=>(
          <CardWork key={item.id} imageUrl={item.action_image} title={item.haading} subHeading={item.sub_heading}   />
      ))}
    </>
  )
}

export default WorkData
