import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../../molecules/Images/Image'
import Heading from '../../../molecules/Headings/Heading'
import Breadcrumbs from '../../../atoms/Breadcrumbs/Breadcrumbs'
import creditCardImage from '../../../../assets/img/creditCardImage.jpg'
import FaqServices from '../../../templates/FaqServices/FaqServices'
import CreditCardFeaturesData from '../../../molecules/UpsCard/CreditCardFeaturesData'
import CreditCardAdvantagesDetails from '../../../molecules/Card/CreditCardAdvantagesDetails'
import CreditCardCalculator from '../../../templates/CreditCardCalculator/CreditCardCalculator'

const CreditCardBalanceTransfer = () => {

    return (
        <>
            
            <section className='position-relative innerBanner'>
                <Image src={creditCardImage} alt="About Banner" width="1920" height="450" />
                <div className='bannerContent'>
                    <Container>
                        <Row>
                            <Col>
                                <Heading tagName="h1" className="h1" title="Credit Card Balance Transfer" />
                                <Breadcrumbs />
                            </Col>
                        </Row>
                        
                    </Container>
                </div>
            </section>
            <section className='calulatorSection sectionPadding'>
        <Container>
          <Row className='calulator--inner'>
            <h2 className='h2'>Credit Card Balance Transfer - <span style={{'color':'#EF8200'}}>Check Your EMI</span></h2>
            <p>A perfect solution to consolidate and simplify your payments for reduced financial stress, and a clear path to financial freedom.</p>
          </Row>
          <Row className='calulator--inner'>
            <Col className='col-12'>
              <CreditCardCalculator />
            </Col>
          </Row>
        </Container>
      </section>
            <section className='facilitiesSection sectionPadding'>
                <Container>
                    <Row className='usp--inner'>
                        <Col md={9} className='text-center'>
                            <Heading tagName="h2" className="h2" title="Credit Card Balance Transfer Features" />
                            <p className='white'>Escape the cycle of high-interest debt with Grownet's Credit Card Balance Transfer service. </p>
                        </Col>
                    </Row>
                    <Row className='mt-4 uspCard'>
                        <CreditCardFeaturesData />
                    </Row>
                </Container>
            </section>
            <section className='loanDetailsSection sectionTop'>
                <Container>
                    <Row className='usp--inner'>
                        <Col md={9} className='text-center'>
                            <Heading tagName="h2" className="h2" title="Advantages" />
                            <p>Choose our Credit Card Balance Transfer and take control of your financial journey.  Experience the advantages of simplified and cost-effective financial management.</p>
                        </Col>
                    </Row>
                    <Row className='mt-4 '>
                        <CreditCardAdvantagesDetails />
                    </Row>
                </Container>
            </section>
            <section className='sectionTop faqSection'>
                <Container>
                    <Row>
                        <FaqServices />
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default CreditCardBalanceTransfer
