import React from 'react'
import UspCard from './UpsCard'

const creditCardFeaturesData=[
    {
        id:1,
        title: 'Consolidate Your Debt: ',
        description: 'We let you streamline your finances by consolidating multiple credit card debts into a single, more manageable payment.',
    },
    {
        id:2,
        title: 'Competitive Interest Rates: ',
        description: 'Enjoy the benefit of lower interest rates, helping you save on interest payments and accelerate your journey toward financial freedom.',
    },
    {
        id:3,
        title: 'Flexible Repayment Terms: ',
        description: 'Tailor your repayment plan to fit your life and budget. Choose from flexible repayment terms to make the process more manageable.',
    },
    {
        id:4,
        title: 'No Hidden Fees: ',
        description: 'Grownet believes in transparency. Our Credit Card Balance Transfer service comes with a transparent fee structure, ensuring you know the costs upfront.',
    },
]
const CreditCardFeaturesData = () => {
  return (
    <>
      {creditCardFeaturesData.map((item, index)=>(
        <UspCard key={index} {...item}/>
      ))}
    </>
  )
}

export default CreditCardFeaturesData

