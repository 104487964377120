import React from 'react'

const SelectField  = ({ label, options, value, onChange, key,disabled , className}) => {
  return (
    <>
      <label>{label}</label>
      <select value={value} onChange={onChange} disabled={disabled} className={className} >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  )
}

export default SelectField 
