import React from 'react'
import Card from './Cards';
import salaryImageOne from '../../../assets/img/salary-1.svg';
import salaryImageTow from '../../../assets/img/salary-2.svg';
import salaryImageThree from '../../../assets/img/salary-3.svg';
import salaryImageFour from '../../../assets/img/salary-4.svg';
const salaryAdvanceAdvantagesDetails = [
    {
        id:1,
        title: 'Immediate Financial Relief: ',
        description: 'Our Salary Advance provides you with immediate financial relief, allowing you to address unexpected expenses without delay.',
        imageUrl: salaryImageOne,
 
    },
    {
        id:2,
        title: 'Flexibility in Repayment:  ',
        description: 'Repay the advance on your terms. The flexible repayment options ensure that you can adjust the repayment schedule to fit your financial circumstances.',
        imageUrl: salaryImageTow,
    },
    {
        id:3,
        title: 'Accessible to All: ',
        description: 'Regardless of your credit history, our Salary Advance is accessible to all individuals. Your eligibility is primarily based on your employment and salary, making it a convenient option for many.',
        imageUrl: salaryImageThree,
      },
      
      {
        id:4,
        title: 'Privacy and Security: ',
        description: 'Grownet prioritizes the privacy and security of your information, enabling you to conduct financial transactions with confidence, knowing that your data is treated with the highest level of confidentiality.',
        imageUrl: salaryImageFour,
      },
      
]
const SalaryAdvanceAdvantagesDetails = () => {
  return (
    <>
        {salaryAdvanceAdvantagesDetails.map((card, index) => (
            <Card key={index} {...card} />
        ))}
    </>
  )
}

export default SalaryAdvanceAdvantagesDetails
