import React, { useState, useEffect } from 'react';
//import { useQuery } from 'react-query';
import Card from './Cards';
import useRequest from '../../../useRequest';
const CardData = () => {
  // const maxWords = 13;
  const { data: services, sendRequest, isLoading: queryLoading, error: queryError } = useRequest();
  useEffect(() => {
    sendRequest({ path: '/all-services', key: 'All services',
    // headers: {
    //   'Custom-Header': 'header-value',
    // },
     });
  }, [sendRequest]);
  const [allServices, setAllServices] = useState([]);
  useEffect(() => {
    if (services) {
      setAllServices(services.data)
    }
  }, [services]);
  if (queryLoading) {
    return <p>Loading...</p>;
  }

  if (queryError) {
    return <p>Error: {queryError.message}</p>;
  }
  return (
    <>
      {allServices.length > 0 && allServices.map((item) => (
        <Card
          key={item.id}
          imageUrl={item.service_logo}
          title={item.name}
          description={item.description}
        />
        //{/* <p>{truncateText(item.description, maxWords)}</p> */}   
      ))}

    </>
  )
}

export default CardData
