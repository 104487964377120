import React from 'react';
// import { Puff } from 'react-loader-spinner'
import { BounceLoader } from 'react-spinners';

const MainLoader = () => {
    return (
    <>
   
   <div className='mainLoader'>
      {/* <Puff color="#009B35" height={100} width={100} /> */}
      <BounceLoader color="#009B35" size={60} />
    </div>
    
    </>
  )
}

export default MainLoader
