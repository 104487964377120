import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import Information from './Information'
import ServicesLinks from './ServicesLinks'
import FinancialToolsLinks from './FinancialToolsLinks'
import Support from './Support'

const FooterLinks = () => {
  return (
    <Row>
      <Col className='col-12 col-lg-3  col-md-6'>
        <Information />
      </Col>
      <Col className='col-12 col-lg-3 col-md-6'>
        <ServicesLinks />
      </Col>
      <Col className='col-12 col-lg-3 col-md-6'>
        <FinancialToolsLinks />
      </Col>
      <Col className='col-12 col-lg-3 col-md-6'>
        <Support />
      </Col>
     
    </Row>
  )
}

export default FooterLinks
