import React from 'react'
import '../GetAppointment/GetAppointment.css'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../molecules/Images/Image'
import Heading from '../../molecules/Headings/Heading'
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs'
import appointmentImage from '../../../assets/img/appointmentImage.jpg'
import AppointmentForm from '../../molecules/Form/Appointment/AppointmentForm'
const GetAppointment = () => {
  return (
    <>
        
        <section className='position-relative innerBanner'>
            <Image src ={appointmentImage} alt="About Banner" width="1920" height="450"  />
            <div className='bannerContent'>
            <Container>
                <Row>
                <Col>
                    <Heading tagName="h1" className="h1" title="Get Appointment" />
                    <Breadcrumbs />
                </Col>
                </Row>
                
            </Container>
            </div>
        </section>
        <section className='appointmentSction sectionPadding'>
            <Container>
                <Row>
                    <Col>
                        <Heading tagName="h2" className="h2" title="Need To Expert Advice For Fill The Details" />
                        <p>Uniquely matrix economically sound value through cooperative technology. Competently parallel task fully researched data and enterprise process improvements. Collaboratively expedite quality manufactured products via client-focused results quickly communicate enabled technology and turnkey leadership skills. Uniquely enable accurate supply chains rather than Grownet technology.</p>
                    </Col>
                </Row>
                <AppointmentForm />
            </Container>
        </section>
    </>
  )
}

export default GetAppointment
