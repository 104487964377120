import React from 'react'
import Input from '../../../atoms/Input/Input'
import Button from '../../../atoms/Button/Button'
const emailHolder=()=>{

}
const newsLetterSubscribe=()=>{

}
const NewsLetterForm = () => {

  return (
    <form className="mt-3">
      <div className='input-group'>
        <Input type="email" name="email" placeholder="Enter Your Email" onChange={emailHolder} />
        <Button onClick={newsLetterSubscribe} label="Subscribe" className="submit" />
        
      </div>
    </form>
  )
}

export default NewsLetterForm
