import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../../molecules/Images/Image'
import Heading from '../../../molecules/Headings/Heading'
import Breadcrumbs from '../../../atoms/Breadcrumbs/Breadcrumbs'
import educationLoanImage from '../../../../assets/img/educationLoanImage.jpg'
import FaqServices from '../../../templates/FaqServices/FaqServices'
import EducationLoanCalculator from '../../../templates/EducationLoanCalculator/EducationLoanCalculator'
import EducationFeaturesData from '../../../molecules/UpsCard/EducationFeaturesData'
import EducationAdvantagesDetails from '../../../molecules/Card/EducationAdvantagesDetails'
const EducationLoan = () => {
  return (
    <>
      
      <section className='position-relative innerBanner'>
        <Image src={educationLoanImage} alt="About Banner" width="1920" height="450" />
        <div className='bannerContent'>
          <Container>
            <Row>
              <Col>
                <Heading tagName="h1" className="h1" title="Education Loan" />
                <Breadcrumbs />
              </Col>
            </Row>
            
          </Container>
        </div>
      </section>
      <section className='calulatorSection sectionPadding'>
        <Container>
          <Row className='calulator--inner'>
            <h2 className='h2'>Education Loan Calculator - <span style={{'color':'#EF8200'}}>Check Your EMI</span></h2>
            <p>Your pursuit of knowledge starts with informed financial planning! To support your academic aspirations, we have developed an Education Loan Calculator - a powerful tool designed to empower you with accurate and transparent information for effective financial planning. It provides personalized loan calculations based on your preferred loan amount, interest rate, and repayment period, your education loan aligns & fits perfectly with your educational journey. At Grownet, we believe in the transformative power of education!</p>
          </Row>
          <Row className='calulator--inner'>
            <Col className='col-12'>
              <EducationLoanCalculator />
            </Col>
          </Row>
        </Container>
      </section>
      <section className='facilitiesSection sectionPadding'>
        <Container>
          <Row className='usp--inner'>
            <Col md={9} className='text-center'>
              <Heading tagName="h2" className="h2" title="Grownet Education Loan: Features" />
              <p className='white'>Unlock the door to academic success with Grownet's Education Loan, a financial solution designed to support your educational aspirations.</p>
              <Heading tagName="h4" className="h4" title="Nurturing Futures, Empowering Dreams" />
            </Col>
          </Row>
          <Row className='mt-4 uspCard'>
            <EducationFeaturesData />
          </Row>
        </Container>
      </section>
      <section className='loanDetailsSection sectionTop'>
        <Container>
          <Row className='usp--inner'>
            <Col md={9} className='text-center'>
              <Heading tagName="h2" className="h2" title="Advantages" />
              <p>We let you embark on a journey where academic dreams become accomplishments. Take the first step toward a brighter future.</p>
            </Col>
          </Row>
          <Row className='mt-4 '>
            <EducationAdvantagesDetails />
          </Row>
        </Container>
      </section>
      <section className='sectionTop faqSection'>
        <Container>
          <Row>
            <FaqServices />
          </Row>
        </Container>
      </section>
    </>
  )
}

export default EducationLoan
