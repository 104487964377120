import React, { useState, useEffect } from 'react'
import Image from '../../molecules/Images/Image'
import facebookImage from '../../../assets/img/facebook.svg'
import xImage from '../../../assets/img/icons-x.svg'
import linkedinImage from '../../../assets/img/linkedin.svg'
// import youtubeImage from '../../../assets/img/youtube.svg'
import instragarmImage from '../../../assets/img/instragarm.svg'
import { Link } from 'react-router-dom'
import useRequest from '../../../useRequest'
const ContactInfo = () => {
  const { data: footerContactDetail, sendRequest, isLoading: queryLoading, error: queryError } = useRequest();
  useEffect(() => {
    sendRequest({ path: '/grownet-contact-detail', key: 'grownet-contact-detail',
    // headers: {
    //   'Custom-Header': 'header-value',
    // },
    });
  }, [sendRequest]);
  const [allFooterContactDetail, setAllFooterContactDetail] = useState([]);
  useEffect(() => {
    if (footerContactDetail) {
      setAllFooterContactDetail(footerContactDetail.data)
    }
  }, [footerContactDetail]);
  if (queryLoading) {
    return <p>Loading...</p>;
  }

  if (queryError) {
    return <p>Error: {queryError.message}</p>;
  }
  return (
    <>
      {allFooterContactDetail.length > 0 && allFooterContactDetail.map((item) => (
        <div key={item.id}>
          <div className='info'>
            <Image src={item.branch_footer_icon} alt="20" height="20" className="map" />
            <p className='white m-0'><strong>Address:</strong> {item.branch_address}</p>
          </div>
          <div className='info'>
            <Image src={item.email_footer_icon} alt="20" height="20" className="map" />
            <p className='white m-0'><strong>Email:</strong> <Link className='white' to={`tel:${item.email_address}`}>{item.email_address}</Link></p>
          </div>
          <div className='info'>
            <Image src={item.phone_footer_icon} alt="20" height="20" className="map" />
            <p className='white m-0'><strong>Call us at:</strong> <Link className='white' to={`tel:${item.phone_number}`}>{item.phone_number}</Link></p>
          </div>
        </div>
      ))}
      <ul className='socialIcons'>
        <li><Link to="https://www.facebook.com/people/Grownet-Finance/61555717733460/" target='_blank'><Image src={facebookImage} alt="20" height="20" className="map" /></Link></li>
        <li><Link to="https://twitter.com/grownetfinance" target='_blank'><Image src={xImage} alt="20" height="20" className="map" /></Link></li>
        <li><Link to="https://www.instagram.com/grownetfinance/" target='_blank'><Image src={instragarmImage} alt="20" height="20" className="map" /></Link></li>
        <li><Link to="https://www.linkedin.com/company/grownet-finance/" target='_blank'><Image src={linkedinImage} alt="20" height="20" className="map" /></Link></li>
        
      </ul>

    </>

  )
}

export default ContactInfo
