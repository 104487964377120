import React from 'react'
import '../About/About.css'
import Image from '../../molecules/Images/Image'
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import aboutBannerImage from '../../../assets/img/about-banner.jpg';
import Heading from '../../molecules/Headings/Heading';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import missionImage from '../../../assets/img/mission.svg'
import visionImage from '../../../assets/img/vision.svg'
import aboutInfo from '../../../assets/img/aboutInfo.jpg'
import TeamData from '../../molecules/TeamCard/TeamData';


const About = () => {

  return (
    <>
      
      <section className='position-relative innerBanner'>
        <Image src ={aboutBannerImage} alt="About Banner" width="1920" height="450"  />
        <div className='bannerContent'>
          <Container>
            <Row>
              <Col>
                <Heading tagName="h1" className="h1" title="About" />
                <Breadcrumbs />
              </Col>
            </Row>
            
          </Container>
        </div>
      </section>
      <section className='sectionPadding aboutInfo'>
        <Container>
          <Row>
            <Col className='col-12 col-lg-6'>
              <Heading tagName="h2" className="h2" title="Who Are We?" />
              {/* <Heading tagName="h5" className="h5" title="Insight loan advisors is completely independent loan advising service and our directory of lenders gives you all the information lorem ipsums sitamets."/> */}
              <p>Grownet is a leading financial service provider dedicated to offering a range of tailored solutions to meet your unique needs. With a team of seasoned professionals and a customer-centric approach, we take pride in building lasting relationships that go beyond transactions. Our commitment to your financial well-being sets us apart, and we look forward to being a part of your journey toward financial freedom. Avail of our loan services for travel, education, credit card balance transfer, & advanced salary, and take the first step towards achieving your financial goals with Grownet.</p>
              <div className='missionSection'>
                <div className='mission--inner  d-flex'>
                  <Image src={missionImage} alt="Mission" width="" height="" className="missionImage" />
                  <div>
                    <Heading tagName="h4" className="h4" title="Mission" />
                    <p>We understand that each financial need is unique, and our mission is to provide flexible, transparent, and customer-centric solutions that cater to your specific requirements.</p>
                  </div>
                </div>
                <div className='mission--inner d-flex'>
                  <Image src={visionImage} alt="Mission" width="" height="" className="missionImage" />
                  <div>
                    <Heading tagName="h4" className="h4" title="Vision" />
                    <p>At Grownet, we envision a world where financial empowerment transcends boundaries, enabling individuals to pursue their dreams without constraints. Our vision is to be the catalyst in the form of financial support for travel loans, education loans, credit card balance transfers, & advance salary. We strive to redefine the landscape of these financial services through our commitment to transparency, customer-centric approach, and continuous innovation, making a positive and lasting impact on the lives of those availing our services.</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col className='col-12 col-lg-6'>
              <Image src={aboutInfo} alt="aboutInfo" width="" height="" className="aboutInfo" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className='sectionPadding aboutTeam'>
        <Container>
          <Row className='justify-content-center'>
            <Col className='text-center col-md-8'>
              <Heading tagName="h2" className="h2" title="Our Leadership Team" />
              <p>We love our team. And apparently, they love us back. Watch their stories about how they helped them get ahead. We look forward to working with you for all of your lending needs.</p>
            </Col>
          </Row>
          <Row className='mt-3'>
            <TeamData />
          </Row>
        </Container>
      </section>
    </>
 
  )
}

export default About
