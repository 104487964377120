import React from 'react'
import '../HomeHowItWorks/HomeHowItWorks.css'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Heading from '../../../molecules/Headings/Heading'
import WorkData from '../../../molecules/WorkCard/WorkData'
const HomeHowItWorks = () => {
  return (
    <div className='howItWorksHome sectionPadding'>
        <Container>
            <Row>
                <Col className='text-center'>
                    <Heading tagName="h2" className="h2" title="How does it work?" />
                    <p className='white'>We are your partner in achieving your financial aspirations with ease.</p>
                </Col>
            </Row>
        <Row className='mt-4 work--inner'>
            <WorkData />
        </Row>
        </Container>
    </div>
  )
}

export default HomeHowItWorks
