

import { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';

//const BASE_URL = 'https://api.grownetfinance.com/api';
const BASE_URL = 'https://api.grownetfinance.com/api';

  



const useRequest = (requestData) => {
  const [path, setPath] = useState(null);
  const [key, setKey] = useState(null);
  const [isGet, setIsGet] = useState(false);
  //const [headers, setHeaders] = useState({});

  const fetchData = async () => {
    try {
          const url = `${BASE_URL}${path}`;
          
          const res = await axios.post(url, requestData,
            // {
            //   headers: {
            //     'Authorization': process.env.REACT_APP_SECRET_KEY,
            //     ...headers,
            //   },
            // }
          );
    
          return res.data;
        } catch (error) {
          throw error;
        }
  };

  const response = useQuery([key, path], fetchData, {
    enabled: isGet,
    refetchOnWindowFocus: false,
  });

  const setRequest = useCallback((e) => {
    setPath(e.path);
    setKey(e.key);
    //setHeaders(e.headers);
    setIsGet(true);
  }, []);

  return {
    ...response,
    sendRequest: (e) => setRequest(e),
    isLoading: response.status === 'loading',
    isError: response.status === 'error',
    error: response.error,
  };
};

export default useRequest;
