import React from 'react'
import { Helmet } from 'react-helmet';
const Seo = ({ title, description, keywords, canonicalUrl, ogTitle, ogDescription, ogImage, ogUrl, type }) => {
    return (
      <Helmet>
        
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:type" content={type} />
      </Helmet>
    );
  };

export default Seo
