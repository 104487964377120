import React, { useState } from 'react'
import axios from 'axios';
import Input from '../../../atoms/Input/Input';
import Button from '../../../atoms/Button/Button'
import LoanType from './LoanType';
// import ReCAPTCHA from 'react-google-recaptcha';
const EligibilityPageForm = () => {
    const [eligibilityData, setSligibilityData] = useState({
        full_name: '',
        phone_number: '',
        email: '',
        loan_type: '',
        prefered_meeting_date_time: ''
    });
    const [errorMessages, setErrorMessages] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    // const [captchaValue, setCaptchaValue] = useState('');
    const eligibilityHandlar = (event) => {
        const { name, value } = event.target;

        setSligibilityData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [name]: emailRegex.test(value) ? '' : 'Invalid email format.',
            }));
        } else {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [name]: value.trim() ? '' : `${name.replace('_', ' ')} is required.`,
            }));
        }
        // setErrorMessages((prevErrors) => ({
        //     ...prevErrors,
        //     [name]: value.trim() ? '' : `${name.replace('_', ' ')} is required.`,
        // }));

        setSuccessMessage('');
    };
    const continueHandlar = async (e) => {
        e.preventDefault();
        if (loading) return;
        setLoading(true);
        // if (!captchaValue) {
        //     alert('Please complete the CAPTCHA.');
        //     return;
        //   }
        const requiredFields = ['full_name', 'phone_number', 'email', 'loan_type', 'prefered_meeting_date_time'];
        const newErrorMessages = {};
        requiredFields.forEach((field) => {
            if (!eligibilityData[field].trim()) {
                newErrorMessages[field] = `${field.replace(/_/g, ' ')} is required.`;
            }
        });
        setErrorMessages(newErrorMessages);
        if (Object.keys(newErrorMessages).length > 0) {
            setLoading(false);
            setSuccessMessage('');
            return;
        }

        try {
            const formEligibility = new FormData();
            formEligibility.append('full_name', eligibilityData.full_name);
            formEligibility.append('phone_number', eligibilityData.phone_number);
            formEligibility.append('email', eligibilityData.email);
            formEligibility.append('loan_type', eligibilityData.loan_type);
            formEligibility.append('prefered_meeting_date_time', eligibilityData.prefered_meeting_date_time);

            const response = await axios.post('https://api.grownetfinance.com/api/user-loan-eligibility', formEligibility,
                // {
                //     headers: {
                //         'Authorization': process.env.REACT_APP_SECRET_KEY,
                //     },
                // }
            );

            console.log('API Response:', response);

            if (response.data && response.data.status === false) {
                const messagesArray = response.data.message || [];
                const errorMessagesObj = messagesArray.reduce((acc, message, index) => {
                    acc[index.toString()] = message;
                    return acc;
                }, {});
                setErrorMessages(errorMessagesObj);
                setSuccessMessage('');
            } else {
                setErrorMessages({});
                setSuccessMessage('Form submitted successfully!');
                setSligibilityData({
                    full_name: '',
                    phone_number: '',
                    email: '',
                    contact_number: '',
                    loan_type: '',
                    prefered_meeting_date_time: ''
                });
            }
        } catch (error) {
            console.error('API Error:', error);
            setErrorMessages({ general: 'An error occurred while submitting the form.' });
            setSuccessMessage('');
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            <form className='FormEligibility'>
                <div className='input-group '>
                    <Input
                        type="text"
                        name="full_name"
                        label={<span>Full Name <span style={{ color: 'red' }}>*</span></span>}
                        placeholder="Full Name*"
                        value={eligibilityData.full_name}
                        onChange={eligibilityHandlar}
                    />
                    {errorMessages.full_name && (
                        <p className='errormessage'>{errorMessages.full_name}</p>
                    )}
                </div>
                <div className='input-group  ' >
                    <Input
                        type="text"
                        label={<span>Email <span style={{ color: 'red' }}>*</span></span>}
                        name="email"
                        placeholder="Enter Your Email Address*"
                        onChange={eligibilityHandlar}
                        value={eligibilityData.email}
                    />
                    {errorMessages.email && (
                        <p className='errormessage'>{errorMessages.email}</p>
                    )}
                </div>

                <div className='input-group  ' >
                    <Input
                        type="text"
                        name="phone_number"
                        label={<span>Contact number <span style={{ color: 'red' }}>*</span></span>}
                        placeholder="Contact number*"
                        //onChange={eligibilityHandlar} 
                        value={eligibilityData.phone_number}
                        onChange={(e) => {
                            const numericValue = e.target.value.replace(/[^\d+]/g, '');
                            const truncatedValue = numericValue.slice(0, 14);
                            eligibilityHandlar({
                                target: {
                                    name: 'phone_number',
                                    value: truncatedValue,
                                },
                            });
                        }}
                    />
                    {errorMessages.phone_number && (
                        <p className='errormessage'>{errorMessages.phone_number}</p>
                    )}
                </div>
                <div className='input-group labelhide ' >

                    <LoanType
                        value={eligibilityData.loan_type}
                        onChange={(selectedLoanType) => eligibilityHandlar({ target: { name: 'loan_type', value: selectedLoanType } })}
                    />
                    {errorMessages.loan_type && (
                        <p className='errormessage'>{errorMessages.loan_type}</p>
                    )}
                </div>
                <div className='input-group labelhide ' >
                    <Input
                        label={<span>Representative to reach you <span style={{ color: 'red' }}>*</span></span>}
                        type="datetime-local"
                        id="datetime"
                        placeholder="Representative to reach you*"
                        name="prefered_meeting_date_time"
                        value={eligibilityData.prefered_meeting_date_time}
                        onChange={eligibilityHandlar}
                    />
                    {errorMessages.prefered_meeting_date_time && (
                        <p className='errormessage'>{errorMessages.prefered_meeting_date_time}</p>
                    )}
                </div>

                {/* <div className='input-group' >
              <ReCAPTCHA
                  sitekey="your-recaptcha-site-key"
                  onChange={(value) => setCaptchaValue(value)}
              />
            </div> */}
                <div className='input-group submitButton mb-0' >

                    <Button
                        type="submit"
                        label={loading ? 'Submitting...' : 'Submit'}
                        className="submit"
                        onClick={continueHandlar}
                        disabled={loading}
                    />
                </div>
                {successMessage && (
                    <div className='succesMessage'>
                        {successMessage}
                    </div>
                )}
            </form>
        </>
    )
}

export default EligibilityPageForm
