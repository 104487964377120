import React from 'react';

const MapSection = () => {
  return (
    <section className='mapSection'>
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3783.3870459644018!2d73.9221447!3d18.5114037!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c117fb98df9b%3A0x486afb31cf7ffbeb!2sPentagon!5e0!3m2!1sen!2sin!4v1701347698083!5m2!1sen!2sin"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </section>
  );
};

      
export default MapSection;
