import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../../molecules/Images/Image'
import Heading from '../../../molecules/Headings/Heading'
import Breadcrumbs from '../../../atoms/Breadcrumbs/Breadcrumbs'
import financialInnerImage from '../../../../assets/img/financialInnerImage.jpg'
import CompoundInterest from '../../../templates/CompoundInterest/CompoundInterest'
import AboutImage1 from "../../../../assets/img/About-1.png"
import AboutIcon1 from "../../../../assets/img/About-icon1.svg"
import AboutIcon2 from "../../../../assets/img/About-icon2.svg"
import AboutIcon3 from "../../../../assets/img/About-icon3.svg"
import AboutIcon4 from "../../../../assets/img/About-icon4.svg"
import CompoundInterestCalculatorFaq from '../../../templates/Faq/CompoundInterestCalculatorFaq'
const CompoundInterestCalculator = () => {
    return (
        <>
            
            <section className='position-relative innerBanner'>
                <Image src={financialInnerImage} alt="About Banner" width="1920" height="450" />
                <div className='bannerContent'>
                    <Container>
                        <Row>
                            <Col>
                                <Heading tagName="h1" className="h1" title="Compound Interest Calculator" />
                                <Breadcrumbs />
                            </Col>
                        </Row>
                        
                    </Container>
                </div>
            </section>
            <section className='calulatorSection sectionPadding'>
                <Container>
                    <Row className='calulator--inner'>
                        <Heading tagName="h2" className="h2" title="Compound Interest Calculator" />
                        <Heading tagName="h4" className="h4" title="Unlock the Power of Your Investments!" />
                        <p>Our Compound Interest Calculator is a valuable tool to help you understand the potential growth of your investments. Input your principal amount, interest rate, compounding frequency, and tenure to visualize the compounded growth of your investments. Our calculator provides clear insights into the power of compounding, empowering you to make strategic financial decisions.</p>
                    </Row>
                    <Row className='calulator--inner'>
                        <Col className='col-12'>
                            <CompoundInterest />
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className='homeAbout sectionPadding'>
                <Container>
                    <Row className='AboutTop'>
                        <Col className='col-12 col-lg-6'>
                            <figure className='mb-0'>
                                <Image src={AboutImage1} alt="About" width="650" height="500" className="aboutImage" />
                            </figure>
                        </Col>
                        <Col className='col-12 col-lg-6'>
                            <Heading tagName="h2" className="h2" title="Why Choose Grownet's Compound Interest Calculator?" />
                            <p>Unlock the potential of your investments by using Grownet's Compound Interest Calculator to plan and visualize the compounded growth of your savings.</p>
                            <ul className='about--list'>
                                <li>
                                    <Image src={AboutIcon1} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Accurate Projections" />
                                </li>
                                <li>
                                    <Image src={AboutIcon2} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Transparent Results" />
                                </li>
                                <li>
                                    <Image src={AboutIcon3} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Flexible Inputs" />
                                </li>
                                <li>
                                    <Image src={AboutIcon4} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Financial Empowerment" />
                                </li>

                            </ul>
                        </Col>
                    </Row>

                </Container>

            </div>
            <section className='sectionPadding faqSection'>
                <Container>
                    <Row>
                        <CompoundInterestCalculatorFaq />
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default CompoundInterestCalculator
