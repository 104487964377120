import React from 'react'
import AccordionFaq from './AccordionFaq';
const loanPrepaymentCalculatorFaqData = [
    { 
        id:1, 
        title: 'How does the Loan Prepayment Calculator work?', 
        content: 'The calculator uses your outstanding loan amount, interest rate, remaining tenure, and prepayment amount to show how prepayments impact your loan, including potential interest savings.' 
    },
    { 
        id:2, 
        title: 'Can I use the calculator for different types of loans?', 
        content: 'Yes, the calculator is versatile and can be used for various loans, including personal loans, home loans, and other installment-based debts.' 
    },
    { 
        id:3, 
        title: 'Is the data entered into the calculator secure?', 
        content: "Absolutely. Your data is treated with the utmost confidentiality, and we prioritize the security and privacy of your financial details."
    },
    { 
        id:4, 
        title: 'How frequently can I make prepayments?', 
        content: "The calculator allows you to input prepayments based on your preferred frequency, whether it's a one-time lump sum or recurring prepayments. Adjust the inputs to suit your financial strategy." 
    },
  
];
const LoanPrepaymentCalculatorFaqData = () => {
  return (<>{loanPrepaymentCalculatorFaqData.map((item)=>(
    <AccordionFaq key={item.id} title={item.title} content={item.content} />
  ))}</>)
}

export default LoanPrepaymentCalculatorFaqData
