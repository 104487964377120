import React from 'react'
import AccordionFaq from './AccordionFaq';
const businessLoanEMICalculatorFaqData = [
    { 
        id:1, 
        title: 'How accurate are the EMI calculations for business loans?', 
        content: 'Our calculator employs advanced algorithms for precise EMI calculations, ensuring accuracy in your repayment estimates.' 
    },
    { 
        id:2, 
        title: 'Can I adjust the loan tenure to explore different EMI scenarios?', 
        content: 'Yes, our calculator allows easy adjustments to the loan tenure, providing instant updates on how changes impact your monthly installments.' 
    },
    { 
        id:3, 
        title: 'Is the information entered into the calculator secure?', 
        content: "Absolutely. Your data is treated with the utmost confidentiality, and we prioritize the security and privacy of your financial details."
    },
    { 
        id:4, 
        title: 'Can I use the Business Loan EMI Calculator for other types of loans?', 
        content: ' While specifically designed for business loans, our calculator can provide EMI estimates for various loan types with similar repayment structures.' 
    },
  
];
const BusinessLoanEMICalculatorFaqData = () => {
  return (<>{businessLoanEMICalculatorFaqData.map((item)=>(
    <AccordionFaq key={item.id} title={item.title} content={item.content} />
  ))}</>)
}

export default BusinessLoanEMICalculatorFaqData
