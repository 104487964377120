import React, {useState, useEffect} from 'react'
// import { useQuery } from 'react-query';
import '../FooterTop/FooterTop.css'
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Heading from '../../molecules/Headings/Heading';
import Image from '../../molecules/Images/Image'
import Container from 'react-bootstrap/esm/Container';
import { Link } from 'react-router-dom';
import useRequest from '../../../useRequest';
// const fetchData = async () => {
//   try {
//   //   const username = 'admin';
//   //   const password = '1234';

//   //   const basicAuth = 'Basic ' + btoa(`${username}:${password}`);
//     const response = await fetch('https://api.grownetfinance.com/api/grownet-contact-detail', {
//       method: 'POST', 
//     });
    

//     if (!response.ok) {
//       throw new Error(`Failed to fetch data. Status: ${response.status}`);
//     }

//     return response.json();
//   } catch (error) {
  
//     throw new Error('Failed to fetch data');
//   }
// };
const FooterTop = () => {
  // const { data:contactDetail, isLoading, error } = useQuery('grownet-contact-detail', fetchData);
  const { data: contactDetail, sendRequest, isLoading: queryLoading, error: queryError } = useRequest();

  useEffect(() => {
    
    sendRequest({ path: '/grownet-contact-detail', key: 'grownet-contact-detail',
    // headers: {
    //   'Custom-Header': 'header-value',
    // },
     });
  }, [sendRequest]);
  const [allContactDetail, setAllContactDetail]= useState([]);
  useEffect(()=>{
      if(contactDetail){
        setAllContactDetail(contactDetail.data)
      }
  }, [contactDetail]);
  if (queryLoading) {
    return <p>Loading...</p>;
  }

  if (queryError) {
      return <p>Error: {queryError.message}</p>;
  }
  return (
    <>
    {allContactDetail.map((item)=>(
      <Container key={item.id}>
        <Row >
          <Col className='text-center'>
            <Heading tagName="h2" className="h2" title={item.main_heading} />
            <p>{item.sub_heading}</p>
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col className='productsCards card border-0'>
            <div className='cardsInner card-body '>
              <div className='productsTitle'>
                <Image src={item.branch_icon} alt={item.branch_title} className="cardIcon"  />
                <Heading tagName="h5" className="h5" title={item.branch_title}/>
              </div>
              <p>{item.branch_address}</p>
              
            </div>
          </Col>
          <Col className='productsCards card border-0'>
            <div className='cardsInner card-body '>
              <div className='productsTitle'>
                <Image src={item.phone_icon} alt={item.phone_title} className="cardIcon" />
                <Heading tagName="h5" className="h5" title={item.phone_title}/>
              </div>
              <Link to={`tel:${item.phone_number}`}><p>{item.phone_number}</p></Link>
            </div>
          </Col>
          <Col className='productsCards card border-0'>
            <div className='cardsInner card-body '>
              <div className='productsTitle'>
                <Image src={item.email_icon} alt={item.email_title} className="cardIcon" />
                <Heading tagName="h5" className="h5" title={item.email_title}/>
              </div>
              <Link to={`tel:${item.email_address}`}><p>{item.email_address}</p></Link>
            </div>
          </Col>
          </Row>
        </Container>
      ))}
    </>
  )
}

export default FooterTop
