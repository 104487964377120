import React from 'react'
import '../FinancialTools/FinancialTools.css'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../molecules/Images/Image'
import Heading from '../../molecules/Headings/Heading'
import financialBannerImage from '../../../assets/img/financialBanner.jpg';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs'
import FinancialDetails from '../../molecules/FinancialCard/FinancialDetails'
// import Faq from '../../templates/Faq/Faq'
import FinancialHowItWorksData from './FinancialHowItWorks/FinancialHowItWorksData'


const FinancialTools = () => {
  return (
    <>
      
      <section className='position-relative innerBanner'>
        <Image src ={financialBannerImage} alt="About Banner" width="1920" height="450"  />
        <div className='bannerContent'>
          <Container>
            <Row>
              <Col>
                <Heading tagName="h1" className="h1" title="Financial Tools" />
                <Breadcrumbs />
              </Col>
            </Row>
            
          </Container>
        </div>
      </section>
      <section className='financialServices sectionPadding'>
        <Container>
          <Row>
            <FinancialDetails />
          </Row>
        </Container>
      </section>
      <div className='howItWorksHome sectionPadding howItWorkspage'>
        <Container>
            <Row>
                <Col className='text-center'>
                    <Heading tagName="h2" className="h2" title="Why do you need financial tools?" />
                   
                </Col>
            </Row>
        <Row className='mt-4 work--inner'>
          <FinancialHowItWorksData />
        </Row>
        </Container>
    </div>
      {/* <section className='sectionTop faqSection'>
        <Container>
          <Row>
            <Faq />
            
          </Row>
        </Container>
      </section> */}
      
    </>
  )
}

export default FinancialTools
