import React from 'react'
import '../CheckYourEligibility/CheckYourEligibility.css'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../molecules/Images/Image'
import Heading from '../../molecules/Headings/Heading'
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs'
import checkYourEligibilityBanner from '../../../assets/img/checkYourEligibilityBanner.jpg'
import HomeHowItWorks from '../HomePage/HomeHowItWorks/HomeHowItWorks'
import outlineCheckImage from '../../../assets/img/outline-check.svg'
import EligibilityPageForm from '../../molecules/Form/Eligibility/EligibilityPageForm'
import LoanEligibilityFaq from '../../templates/Faq/LoanEligibilityFaq'
  const items = [
    'PAN card',
    'Aadhar Card',
    'Driving License',
    'Last 3 year ITR',
    '6 Months Bank Statement / Salary Slip',
    'Current House lease agreement',
    'Valid water/electricity/ LPG bill',
    'Valid passport',
  ];
const CheckYourEligibility = () => {
  return (
    <>
        
        <section className='position-relative innerBanner'>
            <Image src ={checkYourEligibilityBanner} alt="About Banner" width="1920" height="450"  />
            <div className='bannerContent'>
                <Container>
                <Row>
                    <Col>
                    <Heading tagName="h1" className="h1" title="Check Your Eligibility" />
                    <Breadcrumbs />
                    </Col>
                </Row>
                
                </Container>
            </div>
        </section>
    <section className='sectionPadding documentsSection'>
        <Container>
            <Row>
                <Col className='col-12 col-md-6'>
                    <Heading tagName="h2" className="h2" title="Documents Required For Loans" />
                    <Heading tagName="h6" className="h6" title="Identity-Related Documents Required From Both Applicant And Co-Applicant" />
                    <Heading tagName="h6" className="h6" title="KYC Documents:" />
                    <ul className='p-0'>
                        {items.map((item, index) => (
                            <li key={index} className='d-flex'>
                            <Image src={outlineCheckImage} alt="" />
                            <span>{item}</span>
                            </li>
                        ))}
                    </ul>
                </Col>
                <Col className='col-12 col-md-6'>
                    <div className='journeySection'>
                        <Heading tagName="h3" className="h3" title="Start your journey now" />
                        <EligibilityPageForm />
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
    <HomeHowItWorks />
    <section className='sectionTop faqSection'>
        <Container>
            <Row>
                
                <LoanEligibilityFaq />  
                
            </Row>
        </Container>
    </section>
    </>
  )
}

export default CheckYourEligibility
