import React, { useState, useEffect } from 'react';
import SelectField from '../../../atoms/Select/SelectField ';
import useRequest from '../../../../useRequest';
const CityDropdown = ({ selectedState, onChange, value ,disabled}) => {
  const { data: cityList, sendRequest, isLoading: queryLoading, error: queryError } = useRequest();
  const [cities, setCities] = useState([]);

  useEffect(() => { 
    sendRequest({ path: '/country-city', key: 'country-city',
    // headers: {
    //   'Custom-Header': 'header-value',
    // },
     });
  }, [sendRequest]);

  useEffect(() => {
    if (cityList) {
      const filteredCities = cityList.data.filter((city) => city.state_id === selectedState);
      setCities(filteredCities);
    }
  }, [cityList, selectedState]);

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    console.log('City ' + selectedCity);
    if (onChange && typeof onChange === 'function') {
      onChange(selectedCity);
    } else {
      console.error('onChange is not a valid function');
    }
  };

  if (queryLoading) {
    return <p>Loading...</p>;
  }

  if (queryError) {
    return <p>Error: {queryError.message}</p>;
  }

  return (
    <SelectField
      disabled={disabled}
      label={<span>City<span style={{ color: 'red' }}>*</span></span>}
      id="city"
      className="firstName"
      options={[
        { label: 'Select a city', value: '' },
        ...cities.map((city) => ({ label: city.name, value: city.id })),
      ]}
      value={value}
      onChange={handleCityChange}
    />
  );
};

export default CityDropdown;