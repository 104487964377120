import React from 'react'
import AccordionFaq from './AccordionFaq';
const compoundInterestCalculatorFaqData = [
    { 
        id:1, 
        title: 'How does the Compound Interest Calculator work?', 
        content: 'The calculator uses your principal amount, interest rate, compounding frequency, and tenure to show how interest compounds over time, providing insights into your investment growth.' 
    },
    { 
        id:2, 
        title: 'Can I use the calculator for different types of investments?', 
        content: 'Yes, the calculator is versatile and can be used for various investments like savings accounts, fixed deposits, or any investment with compound interest.' 
    },
    { 
        id:3, 
        title: 'Is the entered data secure?', 
        content: "Absolutely. Your data is treated with the utmost confidentiality, and we prioritize the security and privacy of your financial details."
    },
    { 
        id:4, 
        title: 'Can I adjust the investment period to explore different growth scenarios?', 
        content: 'Yes, you can easily modify the tenure in the calculator to see how changes in the investment period impact the compounded growth of your savings.' 
    },
  
];
const CompoundInterestCalculatorFaqData = () => {
  return (<>{compoundInterestCalculatorFaqData.map((item)=>(
    <AccordionFaq key={item.id} title={item.title} content={item.content} />
  ))}</>)
}

export default CompoundInterestCalculatorFaqData
