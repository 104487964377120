import React from 'react'
import Heading from '../../molecules/Headings/Heading'
import NewsLetterForm from '../../molecules/Form/NewsLetterForm/NewsLetterForm'


const NewsLetter = () => {
  return (
    <>
      <div className='newsLetter'>
        <Heading tagName="h4" className="h4" title="Subscribe To Our Newsletter" />
        <p className='white'>We will create your personal account in which you can track the change in your rating.</p>
        <NewsLetterForm  />
      </div>
    </>
  )
}

export default NewsLetter
