import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import iconPark from '../../../assets/img/icon-park.svg'
import Image from '../Images/Image'
import Heading from '../Headings/Heading'
import Row from 'react-bootstrap/esm/Row'
import { Link } from 'react-router-dom'
// import { Link } from 'react-router-dom'


const ServicesCard = ({ title, id, description, imageUrl, lists, linkTo, linkApply}) => {
  
  return (
    <>
      <Row className='sectionTop'>
        <Col className='col-12 col-md-6'>
          <figure>
              <Image src={imageUrl} alt={title} className="serviceImage" />
          </figure>
        </Col>
        <Col className='col-12 col-md-6 '  >
          <Heading tagName="h2" className="h2" title={title} />
          <p className="mt-3 mb-0">{description}</p>
          <ul className='mt-3 w-100 servicesList'>
              {lists.map((item, index) => (
                <li key={index}><Image src={iconPark} alt="icon-park" />{item}</li>
              ))}
          </ul>
          
          <div className='mt-3 w-100 d-flex'>
            <Link onClick={(e)=>{
              e.preventDefault();
              localStorage.setItem('serviceId', id)
              window.location= linkTo
            }} className="btnfilled btn-custom colorChnage" to={linkTo}>Check your EMI</Link>
            <Link to={linkApply} className="btnfilled btn-custom ms-3 ">Apply Now</Link>
          </div>

        </Col>
      </Row>
    </>
  )
}

export default ServicesCard
