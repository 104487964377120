import React, {useState, useEffect} from 'react'
import useRequest from '../../../useRequest';
import UspCard from './UpsCard'
const UspCardDeatils = () => {
    const { data: uspList, sendRequest, isLoading: queryLoading, error: queryError } = useRequest();
    useEffect(() => {
      sendRequest({ path: '/our-usp-list', key: 'our-usp-list',
      // headers: {
      //   'Custom-Header': 'header-value',
      // },
       });
    }, [sendRequest]);
    const [allUsp, setAllUsp]= useState([]);
    useEffect(()=>{
        if(uspList){
          setAllUsp(uspList.data)
        }
    }, [uspList]);
    if (queryLoading) {
      return <p>Loading...</p>;
  }
  
  if (queryError) {
      return <p>Error: {queryError.message}</p>;
  }
    return (
      <>
      {allUsp.length > 0 && allUsp.map((item)=>(
        <UspCard key={item.id} id={item.order_position} title={item.usp_name} description={item.usp_detail} />
      ))}
      
      </>
    )
}

export default UspCardDeatils
