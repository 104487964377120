import React from 'react'
import '../HomePage/Home.css'
import HeroBanner from '../../templates/HeroSection/HeroBanner'
import Products from './Products/Products'
import UspSection from './UspSection/UspSection'
import HomeHowItWorks from './HomeHowItWorks/HomeHowItWorks';
// import HomeBlog from './HomeBlog/HomeBlog';
import HomeServices from './HomeServices/HomeServices';
import HomeCheckYourEligibility from './HomeCheckYourEligibility/HomeCheckYourEligibility'


const Home = () => {
  
  return (
    <>
      <HeroBanner />
      <Products /> 
      <HomeServices />
      <HomeCheckYourEligibility />
      <UspSection />
      <HomeHowItWorks />
      {/* <HomeBlog isHomePage={true}  /> */}
    </>
  )
}

export default Home
