import React from 'react'
import {Link} from 'react-router-dom'
const HeaderButton = () => {
  return (
    <>
      <ul className='headerButton'>
        <li><Link to="#" className="btnOutline button-1 btn-custom">Free CIBIL Score</Link></li>
        <li><Link to="/apply-now" className="btnfilled button-2 btn-custom">Apply Now</Link></li>
      </ul>
    </>
  )
}

export default HeaderButton
