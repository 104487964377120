import React from 'react'
import '../HeroSection/HeroSection.css'
import HeroData from '../../molecules/HeroCard/HeroData';
const HeroBanner = () => {
 
  return (
    <div className="slider">  

            <HeroData />

    </div>
  )
}

export default HeroBanner
