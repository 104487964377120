
import React from 'react'
import UspCard from './UpsCard'

const salaryAdvanceFeaturesData=[
    {
        id:1,
        title: 'Quick and Convenient Access to Funds:  ',
        description: "Whether it's a small unexpected expense or a larger financial gap, we let you choose an amount that suits your immediate needs and repay it on your next payday.",
    },
    {
        id:2,
        title: 'Transparent Fee Structure: ',
        description: 'Our Salary Advance service comes with a clear and upfront fee structure, ensuring that you know the cost of the advance without any hidden fees or surprises.',
    },
    {
        id:3,
        title: 'No Credit Check: ',
        description: "Grownet's Salary Advance service doesn't require a credit check, making it accessible to individuals with varying credit histories.",
    },
    {
        id:4,
        title: 'Secure and Confidential: ',
        description: 'Rest assured that your personal and financial information is treated with the utmost security and confidentiality. ',
    },
]
const SalaryAdvanceFeaturesData = () => {
  return (
    <>
      {salaryAdvanceFeaturesData.map((item, index)=>(
        <UspCard key={index} {...item}/>
      ))}
    </>
  )
}

export default SalaryAdvanceFeaturesData
