import React from 'react'
import Image from '../../molecules/Images/Image'
import footerLogo from '../../../assets/img/logo-white.svg'

import ContactInfo from './ContactInfo'
const FooterAddress = () => {
  return (
    <>
        <Image src={footerLogo} alt="Logo" width="200" height="70" className="FooterLogo" />
        <div className='footerInfo'>
            <p>Top learning experiences that create more talent in the world.</p>

        </div>
      
        <ContactInfo />
      
    </>
  )
}

export default FooterAddress
