import React from 'react'
import Card from './Cards';
import creditCardImageOne from '../../../assets/img/creditCard-1.svg';
import creditCardImageTwo from '../../../assets/img/creditCard-2.svg';
import creditCardImageThree from '../../../assets/img/creditCard-3.svg';
import creditCardImageFour from '../../../assets/img/creditCard-4.svg';
const creditCardAdvantagesDetails = [
    {
        id:1,
        title: 'Simplified Finances: ',
        description: 'Consolidate your credit card debts into a single payment, making it easier to keep track of your finances.',
        imageUrl: creditCardImageOne,
 
    },
    {
        id:2,
        title: 'Save on Interest Payments: ',
        description: 'Benefit from lower interest rates and save more on interest payments, helping you become debt-free sooner.',
        imageUrl: creditCardImageTwo,
    },
    {
        id:3,
        title: 'Flexible Financial Management: ',
        description: 'Choose a repayment plan that aligns with your financial goals, providing you with the flexibility to manage your finances effectively.',
        imageUrl: creditCardImageThree,
      },
      
      {
        id:4,
        title: 'Quick and Convenient Access: ',
        description: 'Our streamlined application process and quick approval mean you can access the benefits of a Credit Card Balance Transfer without unnecessary delays.',
        imageUrl: creditCardImageFour,
      },
      
]
const CreditCardAdvantagesDetails = () => {
  return (
    <>
        {creditCardAdvantagesDetails.map((card, index) => (
            <Card key={index} {...card} />
        ))}
    </>
  )
}

export default CreditCardAdvantagesDetails
