import React from 'react'
import Heading from '../../molecules/Headings/Heading'
import { Link } from 'react-router-dom'
const Support = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className='footerLinks'>
      <Heading className="h4" tagName="h4" title="Support" />
      <ul className='links'>
        <li><Link to="/terms-and-condition" onClick={scrollToTop}>Terms & Condition </Link></li>
        <li><Link to="/privacy-policy" onClick={scrollToTop} >Privacy Policy </Link></li>
        <li><Link to="/help-center" onClick={scrollToTop}>Help Center </Link></li>
       
      </ul>
    </div>
  )
}

export default Support
 


 