import React from 'react'
// import loanCalculatorImage from '../../../assets/img/loan-calculator.svg';
import loanEligibilityImage from '../../../assets/img/loan-eligibility.svg';
import homeLoanCalculatorImage from '../../../assets/img/home-loan-calculator.svg';
import businessLoanCalculatorImage from '../../../assets/img/business-loan-calculator.svg';
import compoundInterestImage  from '../../../assets/img/compound-interest.svg';
import loanPrepaymentCalculatorImage from '../../../assets/img/loan-prepayment-calculator.svg';
import rdCalculatorImage from '../../../assets/img/rd-calculator.svg';
import fdCalcuatorImage from '../../../assets/img/fd-calcuator.svg';
import FinancialCard from './FinancialCard';
const financialDetails=[
    // {
    //     id:1,
    //     title: 'Loan Calculator ',
    //     description: 'See how fees and expenses associated with a variety of funds can impact the value of you as an investor.',
    //     imageUrl: loanCalculatorImage,
    //     linkTo:"/check-your-eligibility"
    // },
    {
        id:1,
        title: 'Loan Eligibility',
        description: 'Discover your loan eligibility swiftly with our in-house user-friendly tool, paving the way for financial empowerment.',
        imageUrl: loanEligibilityImage,
        linkTo:"/check-your-eligibility"
    },
    {
        id:2,
        title: 'Home Loan EMI Calculator ',
        description: 'Navigate home loan planning effortlessly using our EMI Calculator, ensuring precise and informed decisions.',
        imageUrl: homeLoanCalculatorImage,
        linkTo:"/financial-tools/home-loan-emi-calculator"
    },
    {
        id:3,
        title: 'Business Loan EMI Calculator  ',
        description: 'Streamline your business loan journey with our indigenous tool - EMI Calculator, making repayment planning straightforward and effective.',
        imageUrl: businessLoanCalculatorImage,
        linkTo:"/financial-tools/business-loan-emi-calculator"
    },
    {
        id:4,
        title: 'Compound Interest Calculator ',
        description: "Harness the benefits of compounding effortlessly with Grownet's Compound Interest Calculator, facilitating informed financial decisions.",
        imageUrl: compoundInterestImage,
        linkTo:"/financial-tools/compound-interest-calculator"
    },
    {
        id:5,
        title: 'Loan Prepayment Calculator',
        description: 'Optimize loan prepayments with this tool that helps you take a strategic approach to managing and reducing your outstanding loans.',
        imageUrl: loanPrepaymentCalculatorImage,
        linkTo:"/financial-tools/loan-prepayment-calculator"
    },
    {
        id:6,
        title: 'RD Calculator ',
        description: 'Secure your financial future by planning recurring deposits effortlessly with our RD Calculator, ensuring disciplined savings.',
        imageUrl: rdCalculatorImage,
        linkTo:"/financial-tools/rd-calculator"
    },
    {
        id:7,
        title: 'FD Calculator ',
        description: 'Maximize your savings potential with FD Calculator which offers a simple yet powerful tool for effective financial planning.',
        imageUrl: fdCalcuatorImage,
        linkTo:"/financial-tools/fd-calculator"
    }
]

const FinancialDetails = () => {
  return (
    <>
      {financialDetails.map((item, index) => (
        <FinancialCard key={index} {...item} />
        ))}
    </>
    
  )
}

export default FinancialDetails





