import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const breadcrumbs = [
    { name: 'Home', path: '/' },
    ...pathnames.map((path, index) => {
      const name = path.replace(/-+/g, ' ').trim(); // Replace multiple hyphens with a space
      return {
        name,
        path: `/${pathnames.slice(0, index + 1).join('/')}`,
      };
    }),
  ];
  return (
    <div className="breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) => {
        const { name, path } = breadcrumb;
        const isLast = index === breadcrumbs.length - 1;

        return (
          <span key={index}>
            {!isLast ? (
              <Link to={path}>{name}</Link>
            ) : (
              <span>{name}</span>
            )}
            {!isLast && <span> &gt; </span>}
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
