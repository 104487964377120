import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../../molecules/Images/Image'
import Heading from '../../../molecules/Headings/Heading'
import Breadcrumbs from '../../../atoms/Breadcrumbs/Breadcrumbs'
import financialInnerImage from '../../../../assets/img/financialInnerImage.jpg'
import BusinessLoanCalculator from '../../../templates/BusinessLoanCalculator/BusinessLoanCalculator'
import AboutImage1 from "../../../../assets/img/About-1.png"
import AboutIcon1 from "../../../../assets/img/About-icon1.svg"
import AboutIcon2 from "../../../../assets/img/About-icon2.svg"
import AboutIcon3 from "../../../../assets/img/About-icon3.svg"
import AboutIcon4 from "../../../../assets/img/About-icon4.svg"
import BusinessLoanEMICalculatorFaq from '../../../templates/Faq/BusinessLoanEMICalculatorFaq'
const BusinessLoanEMICalculator = () => {
    return (
        <>
            
            <section className='position-relative innerBanner'>
                <Image src={financialInnerImage} alt="About Banner" width="1920" height="450" />
                <div className='bannerContent'>
                    <Container>
                        <Row>
                            <Col>
                                <Heading tagName="h1" className="h1" title="Business Loan EMI Calculator" />
                                <Breadcrumbs />
                            </Col>
                        </Row>
                        
                    </Container>
                </div>
            </section>
            <section className='calulatorSection sectionPadding'>
                <Container>
                    <Row className='calulator--inner'>
                        <Heading tagName="h2" className="h2" title="Business Loan EMI Calculator" />
                        <Heading tagName="h4" className="h4" title="Navigate Business Finances with Ease" />
                        <p>You can use Grownet's Business Loan EMI Calculator to analyze your repayment schedule and make strategic financial decisions for your business. You just need to feed your loan amount, interest rate, and tenure to instantly calculate EMIs for your business loan. Our intuitive interface provides a comprehensive breakdown, empowering you to manage your business finances confidently.</p>
                    </Row>
                    <Row className='calulator--inner'>
                        <Col className='col-12'>
                            <BusinessLoanCalculator />
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className='homeAbout sectionPadding'>
                <Container>
                    <Row className='AboutTop'>
                        <Col className='col-12 col-lg-6'>
                            <figure className='mb-0'>
                                <Image src={AboutImage1} alt="About" width="650" height="500" className="aboutImage" />
                            </figure>
                        </Col>
                        <Col className='col-12 col-lg-6'>
                            <Heading tagName="h2" className="h2" title="Why Use Business Loan EMI Calculator?" />
                            <p>Our Business Loan EMI Calculator is designed to simplify your business loan journey and help you make informed decisions to plan your business finances effectively.</p>
                            <ul className='about--list'>
                                <li>
                                    <Image src={AboutIcon1} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Precise & Accurate EMI Calculations" />
                                </li>
                                <li>
                                    <Image src={AboutIcon2} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Clear Breakdown for Financial Planning" />
                                </li>
                                <li>
                                    <Image src={AboutIcon3} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Ease of Use Ensured Through User-friendly Interface" />
                                </li>
                                <li>
                                    <Image src={AboutIcon4} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Management Of Business Finances With Ease" />
                                </li>

                            </ul>
                        </Col>
                    </Row>

                </Container>

            </div>
            <section className='sectionPadding faqSection'>
                <Container>
                    <Row>
                        <BusinessLoanEMICalculatorFaq />
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default BusinessLoanEMICalculator
