import React from 'react'
import '../HowItWorks/HowItWorks.css'
import Image from '../../molecules/Images/Image'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Heading from '../../molecules/Headings/Heading'
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs'
import howItBannerImage from '../../../assets/img/howItImage.jpg'
import WorkPageCard from '../../molecules/WorkCard/WorkPageCard'


const HowItWorks = () => {
 
  return (
    <>
    
      <section className='position-relative innerBanner'>
        <Image src ={howItBannerImage} alt="About Banner" width="1920" height="450"  />
        <div className='bannerContent'>
          <Container>
            <Row>
              <Col>
                <Heading tagName="h1" className="h1" title="How It Works" />
                <Breadcrumbs />
              </Col>
            </Row>
            
          </Container>
        </div>
      </section>
      <section className='sectionTop  worksSection'>
        <Container>
          
            <WorkPageCard />
          
        </Container>
      </section>
      {/* <div className='facilitiesSection sectionPadding'>
        <Container>
          <Row className='usp--inner'>
              <Col md={9} className='text-center'>
                  <Heading tagName="h2" className="h2" title="We Can Give Best Facilities For Business" />
                  <p className='white'>Achieve all your goals and aspirations; with the right kind of help, exactly when you need it.</p>
              </Col>
          </Row>
          <Row className='mt-4 uspCard'>
            <HowItWorksData />
          </Row>
        </Container>
      </div>
      <section className='sectionTop faqSection'>
        <Container>
          <Row>
            <Faq />
          </Row>
        </Container>
      </section> */}
      
    </>
  )
}

export default HowItWorks
