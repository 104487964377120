import React from 'react'
import Image from '../../../molecules/Images/Image';
import Col from 'react-bootstrap/esm/Col';
import Heading from '../../../molecules/Headings/Heading'

const FinancialHowItWorks = ({ title, imageUrl, description  }) => {
    return (
        <>
            <Col className='workCards  card border-0 '>
              <div className='workInner card-body text-center'>
                  <Image src={imageUrl} alt={title} className="cardIcon" />
                  <Heading tagName="h5" className="h5" title={title} />
                  {/* <p className='white'>{description}</p> */}
              </div>
            </Col>
         
        </>
      )
}

export default FinancialHowItWorks
