import React from 'react';
import { Link } from 'react-router-dom';

const Heading = ({ title, tagName, link, className, span }) => {
    const Tag = tagName;
    return (
        <>
            {link ? <Tag className={className}><Link to={link}>{title}</Link></Tag> : <Tag className={className}>{title}</Tag> }
        </>
    )
}

export default Heading
