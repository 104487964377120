import React from 'react'
import ServiceData from '../../../molecules/ServicesCard/ServiceData'
import Container from 'react-bootstrap/esm/Container';

const HomeServices = () => {
  return (
    <>
      <section className='homeServices sectionBottom'>
        <Container>
            <ServiceData />
        </Container>
      </section>
    </>
  )
}

export default HomeServices
