import React from 'react'
import Nav from 'react-bootstrap/Nav';
import { links } from '../../../utils/Constant';
import MenuItem from './MenuItem';
// import NavDropdown from 'react-bootstrap/NavDropdown';
const Menu = () => {
  return (
    <>
      <Nav >
      <ul>
          {links.map((link) => (
            <MenuItem key={link.id} item={link} />
          ))}
      </ul>
      </Nav>
    </>
  )
}

export default Menu;
