import React from 'react'
import '../Products/Products.css'
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Heading from '../../../molecules/Headings/Heading';
import CardData from '../../../molecules/Card/CardData';
const Products = () => {
  return (
    <div className='productsSection sectionPadding'>
        <Container >
            <Row>
                <Col className='productTop'>
                    <Heading tagName="h2" className="h2" title="Our Products" />
                    <p className='mb-0'>Compare rates, crunch numbers and get expert guidance for life’s biggest financial moments.</p>
                </Col>
            </Row>
            <Row className='mt-4'>
                <CardData />
            </Row>
        </Container>
      
    </div>
  )
}

export default Products
