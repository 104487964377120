
import React from 'react'
import Heading from '../Headings/Heading';
import Image from '../Images/Image';
import Col from 'react-bootstrap/esm/Col';

const CardWork = ({id, title, subHeading, imageUrl, description  }) => {
 
  return (
    <>
      
        <Col className='workCards card border-0 col-md-3 ' key={id}>
          <div className='workInner card-body text-center'>
              <Image src={imageUrl} alt={subHeading} className="cardIcon" />
              <Heading tagName="h5" className="h5" title={subHeading} />
          </div>
        </Col>
     
    </>
  )
}

export default CardWork
