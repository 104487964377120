export const links = [
    {
      id: 1,
      text: "Home",
      url: "/",
    },
    {
      id: 2,
      text: "About",
      url: "/about",
    },
    {
      id: 3,
      text: "Services",
      url: "/services",
      submenu: [
        {
          id:8,
          text: "Travel Loan",
          url: "/services/travel-loan",
        },
        {
          id:9,
          text: "Education Loan",
          url: "/services/education-loan",
        },
        {
          id:10,
          text: "Salary Advance",
          url: "/services/salary-advance",
        },
        {
          id:11,
          text: "Credit Card Balance Transfer",
          url: "/services/credit-card-balance-transfer",
        },
      ]
    },
  
    {
      id: 4,
      text: "How It Works",
      url: "/how-it-works",
    },
    {
        id: 5,
        text: "Contact",
        url: "/contact",
    },
    {
      id: 6,
      text: "Careers",
      url: "/career",
  },
    // {
    //   id: 7,
    //   text: "News ",
    //   url: "/news",
    // },
    // {
    //   id: 8,
    //   text: "Financial Tools",
    //   url: "/financial-tools",
    //   submenu: [
    //     {
    //       id:12,
    //       text: "Loan Eligibility",
    //       url: "/check-your-eligibility",
    //     },
    //     {
    //       id:13,
    //       text: "Home Loan EMI Calculator",
    //       url: "/financial-tools/home-loan-emi-calculator",
    //     },
    //     {
    //       id:14,
    //       text: "Business Loan EMI Calculator",
    //       url: "/financial-tools/business-loan-emi-calculator",
    //     },
    //     {
    //       id:15,
    //       text: "Compound Interest Calculator",
    //       url: "/financial-tools/compound-interest-calculator",
    //     },
    //     {
    //       id:16,
    //       text: "Loan Prepayment Calculator",
    //       url: "/financial-tools/loan-prepayment-calculator",
    //     },
    //     {
    //       id:17,
    //       text: "RD Calculator",
    //       url: "/financial-tools/rd-calculator",
    //     },
    //     {
    //       id:18,
    //       text: "FD Calculator",
    //       url: "/financial-tools/fd-calculator",
    //     },
    //   ]
    // },
  ];

  const apiUsername = process.env.REACT_APP_API_USERNAME;
  const apiPassword = process.env.REACT_APP_API_PASSWORD;
  
  export { apiUsername, apiPassword };