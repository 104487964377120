import React from 'react'
import Image from '../../molecules/Images/Image'
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import careerBannerImage from '../../../assets/img/Career.jpg';
import Heading from '../../molecules/Headings/Heading';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
const Career = () => {
  return (
    <>
        
        <section className='position-relative innerBanner'>
            <Image src ={careerBannerImage} alt="About Banner" width="1920" height="450"  />
            <div className='bannerContent'>
            <Container>
                <Row>
                    <Col>
                        <Heading tagName="h1" className="h1" title="Join Our Team" />
                        <Breadcrumbs />
                    </Col>
                    
                </Row>
                
            </Container>
            </div>
        </section>
        {/* <section className='sectionTop  careerInfo'>
            <Container>
                <Row>
                    <Col className='col-12 '>
                        
                        <Heading tagName="h4" className="h4" title="Help us revolutionize the insights industry." />
                        <p>Grownet Finance is on a mission to transform the landscape of financial services. We believe that gaining genuine insights into customer behavior is the key to enhancing financial strategies and providing exceptional services. Recognized as a rapidly expanding force in the finance industry, and ranked as the #1 fastest-growing finance company in India, we are eager to welcome new members to our team. We are seeking individuals who are driven, compassionate, and inherently curious to join us on our journey.</p>
                        <p>A career at Grownet Finance entails being part of an ambitious, customer-focused, and hardworking team that also values compassion and empathy. We offer a range of benefits, including a competitive salary, a relaxed work environment, flexible paid time off, and health insurance.</p>
                        <p>All positions will initially be remote until we can ensure a safe return to the office. While we prefer local candidates in Pune, we are open to remote hires for individuals who align with our vision and values.</p>
                        <p>If you are searching for an opportunity to make a significant impact in a rapidly growing finance-focused startup, we look forward to meeting you at Grownet Finance.</p>
                    </Col>
                </Row>
            
            
            </Container>
        </section> */}
        <section className='sectionTop  Opportunities'>
            <Container>
                <Row>
                    <Col className='col-12 '>
                        <Heading tagName="h2" className="h2" title="Career Opportunities" />
                        <div className='OpportunitiesList'>
                            <div className='jobsList'>
                                <Heading tagName="h3" className="h3" title="Technology proficient business analyst"/>
                                <p className='mb-4'>We are seeking a highly skilled and technology-proficient Business Analyst to join our dynamic team. The ideal candidate will possess a strong background in both business and technology, with the ability to bridge the gap between business needs and technological solutions. The Technology Proficient Business Analyst will play a crucial role in understanding business processes, identifying opportunities for improvement, and translating requirements into actionable technology solutions.</p>
                                <Heading tagName="h4" className="h4" title="Key Responsibilities:"/>
                                <div className='responsibilities'>
                                    <Heading tagName="h6" className="h6" title="Technology Integration:" />
                                    <ul>
                                        <li>Leverage a deep understanding of technology to assess and recommend appropriate solutions that align with business goals.</li>
                                        <li>Collaborate with IT teams to ensure seamless integration of technology solutions with existing systems and infrastructure.</li>
                                    </ul>
                                </div>
                                <div className='responsibilities'>
                                    <Heading tagName="h6" className="h6" title="Data Analysis:" />
                                    <ul>
                                        <li>Analyze large sets of data to derive meaningful insights and support data-driven decision-making.</li>
                                        <li>Work closely with data engineers and scientists to design and implement data solutions that meet business requirements.</li>
                                    </ul>
                                </div>
                                <div className='responsibilities'>
                                    <Heading tagName="h6" className="h6" title="User Acceptance Testing (UAT):" />
                                    <ul>
                                        <li>Collaborate with end-users to define and execute UAT plans.</li>
                                        <li>Ensure that implemented solutions meet business requirements and are user-friendly.</li>
                                    </ul>
                                </div>
                                <div className='responsibilities'>
                                    <Heading tagName="h6" className="h6" title="Continuous Improvement:" />
                                    <ul>
                                        <li>Stay informed about emerging technologies and industry trends.</li>
                                        <li>Identify opportunities for process improvement and recommend innovative solutions.</li>
                                    </ul>
                                </div>
                                <div className='responsibilities mt-4'>
                                    <Heading tagName="h5" className="h5" title="Qualifications:" />
                                    <ul>
                                        <li>Bachelor’s degree in Business, Information Technology, or a related field.</li>
                                        <li>Proven experience as a Business Analyst with a focus on technology-driven projects.</li>
                                        <li>Strong analytical and problem-solving skills.</li>
                                    </ul>
                                </div>
                                <div className='responsibilities mt-4'>
                                    <Heading tagName="h5" className="h5" title="Technical Skills:" />
                                    <ul>
                                        <li>Familiarity with data analysis tools (e.g., SQL, Python, R).</li>
                                        <li>Understanding of software development life cycle (SDLC) and agile methodologies.</li>
                                        <li>Experience with business intelligence and reporting tools.</li>
                                        <li>Knowledge of ERP systems and enterprise-level applications.</li>
                                    </ul>
                                </div>
                                <p className='mt-4'>If you are a tech-savvy Business Analyst with a passion for driving business success through technology, we invite you to apply for this exciting opportunity. Join our team and contribute to the growth and transformation of our organization.</p>
                            </div>
                            <div className='jobsList'>
                                <Heading tagName="h4" className="h4" title="Job Profile"/>
                                <p className='mb-4'>We are looking for an experienced and innovative Product Manager to Build our Travel Loans portfolio and drive business development initiatives. The successful candidate will be responsible for developing and managing a suite of travel loan products, as well as identifying and capitalizing on business growth opportunities within the travel finance sector. The ideal candidate will possess a deep understanding of consumer lending, a strategic mindset, and a proven track record of successfully bringing financial products to market.</p>
                                <Heading tagName="h4" className="h4" title="Key Responsibilities:"/>
                                <div className='responsibilities'>
                                    <Heading tagName="h6" className="h6" title="Product Development:" />
                                    <ul>
                                        <li>Lead the end-to-end product development lifecycle for travel loans, from ideation to launch.</li>
                                        <li>Collaborate with cross-functional teams including marketing, technology, and risk management to design and enhance travel loan products.</li>
                                    </ul>
                                </div>
                                <div className='responsibilities'>
                                    <Heading tagName="h6" className="h6" title="Business Development:" />
                                    <ul>
                                        <li>Identify and cultivate strategic partnerships within the travel industry to expand the reach of travel loan products.</li>
                                        <li>Develop and maintain relationships with key stakeholders, including travel agencies, tour operators, and financial institutions.</li>
                                    </ul>
                                </div>
                                <div className='responsibilities'>
                                    <Heading tagName="h6" className="h6" title="Market Positioning:" />
                                    <ul>
                                        <li>Define and execute the go-to-market strategy for travel loan products.</li>
                                        <li>Position the travel loan offerings competitively in the market, considering pricing, features, and customer value proposition.</li>
                                    </ul>
                                </div>
                                <div className='responsibilities'>
                                    <Heading tagName="h6" className="h6" title="Qualifications:" />
                                    <ul>
                                        <li>Bachelor’s degree in Business, Finance, Marketing, or a related field. MBA is a plus.</li>
                                        <li>Proven experience as a Product Manager in the financial services industry, with a focus on lending products.</li>
                                        <li>Strong understanding of travel industry dynamics and consumer behavior.</li>
                                        <li>Excellent analytical and strategic thinking skills.</li>
                                        <li>Demonstrated ability to drive business growth and achieve financial targets.</li>
                                        <li>Effective communication and stakeholder management skills.</li>
                                    </ul>
                                </div>
                                <p className='mt-4'>If you are a results-driven Product Manager with a passion for shaping innovative financial products in the travel sector, we invite you to apply for this exciting opportunity. Join our team and play a key role in expanding our presence in the travel finance market.</p>
                            </div>
                            
                        </div>
                    </Col>
                </Row>
            
            
            </Container>
        </section>
        <section className='sectionTop opportunities'>
            <Container>
                <Row>
                    <Col className='col-12 '>
                        <div className='opportunitiesInner sectionPadding'>
                            <Heading tagName="h2" className="h2" title="We are always happy to connect with talented people."/>
                            <div className='d-flex emailButton justify-content-center'><Link to="mailto:info@grownetfinance.com" className="btnemail button-1 btn-custom">email us</Link></div>
                        </div>
                    </Col>
                </Row>
            
            
            </Container>
        </section>
    </>
  )
}

export default Career
