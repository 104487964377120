import React from 'react'

const Input = ({type, value, name, onChange, placeholder, id, label, className,  minLength, maxLength, pattern, disabled}) => {
  return (
    <>
      <label>{label}</label>
      <input type={type} name={name} value={value } placeholder={placeholder} onChange={onChange} id={id} className={className} min={minLength} max={maxLength} pattern={pattern} disabled={disabled} />
    </>
  )
}

export default Input
