import React, {useState, useEffect} from 'react'

import Card from './Cards'
import useRequest from '../../../useRequest';

const ContactDetails = () => {
  const { data: officeBranch, sendRequest, isLoading: queryLoading, error: queryError } = useRequest();
  useEffect(() => {
    sendRequest({ path: '/office-branch-list', key: 'office-branch-list',
    // headers: {
    //   'Custom-Header': 'header-value',
    // },
     });
  }, [sendRequest]);
  const [allOffice, setAllOffice]= useState([]);
  useEffect(()=>{
      if(officeBranch){
        setAllOffice(officeBranch.data)
      }
  }, [officeBranch]);
  if (queryLoading) {
    return <p>Loading...</p>;
}

if (queryError) {
    return <p>Error: {queryError.message}</p>;
}
  return (
    <>
    {allOffice.length > 0 && allOffice.map((item)=>(
      <Card 
        key={item.id}
        imageUrl={item.landmark_icon}
        title={item.location_name} 
        phoneNumber={item.phone_number}
        description={item.address}
      />
     
      ))}
    </>
  )
}

export default ContactDetails
