import React from 'react';
import Modal from 'react-modal';
import Image from '../../molecules/Images/Image';
import Heading from '../Headings/Heading';
import closeCircleImage from '../../../assets/img/close-circle.svg'
const TeamModal = ({ isOpen, onRequestClose, title, description, imageUrl, memberDetail }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Team Member Modal"
    >
      <div className='modalTeam'>
        <div className='modalHeader'>
            <Heading tagName="h5" className="h5" title={title} />
            <button onClick={onRequestClose}><Image src={closeCircleImage} alt="close" width="30" height="30" /></button>
        </div>
        <div className='modalBody'>
            <figure><Image src={imageUrl} alt={title} className="teamImage" /></figure>
            <h6>{description}</h6>
            <p>{memberDetail}</p>

        </div>
        
      </div>
    </Modal>
  );
};

export default TeamModal;