import React, { useState } from 'react';
import Input from '../../../atoms/Input/Input';
import Button from '../../../atoms/Button/Button';
import axios from 'axios';
import iconCheck from '../../../../assets/img/icon-check.svg'
import closeCircleImage from '../../../../assets/img/close-circle-otp.svg'
import Image from '../../Images/Image'
const ValidatePhoneOTP = ({ phone }) => {
  const [enteredOTP, setEnteredOTP] = useState('');
  const [otpErrorMessages, setOtpErrorMessages] = useState();
  const [otpSuccessMessage, setOTPSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValidationDone, setIsValidationDone] = useState(false);

  const validateOTPHandler = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const requiredFields = ['enteredOTP'];
    const newErrorMessages = {};

    requiredFields.forEach((field) => {
      const value = enteredOTP.trim();
      if (!value) {
        newErrorMessages[field] = `${field.replace(/_/g, ' ')} is required.`;
      }
    });

    if (Object.keys(newErrorMessages).length > 0) {
      setOtpErrorMessages(newErrorMessages);
      return;
    }

    try {
      const phoneOtpData = {
        phone: phone, 
        otp: enteredOTP, 
      };
     
      const response = await axios.post('https://losapi.grownetfinance.com/api/v1/auth/user/verify', phoneOtpData );
   
  
      if (response.status === 200 ) {
        setIsLoading(false);
        if (response.data.result === true) {
          setOTPSuccessMessage("OPT Verified");
          setOtpErrorMessages();
          setIsValidationDone(true);
          //onValidateOTP();
      
          
        } else if (response.data.status === false) {
          setIsLoading(false);
          setOtpErrorMessages(response.data.result.message);
          setIsValidationDone(true);
          
        } else {
          setIsLoading(false);
          throw new Error(response.data.message);
        }

      } else {
        setIsLoading(false);
        throw new Error('Failed to validate OTP');
      }
    } catch (error) {


      //alert(error.response.data.message);
      setIsLoading(false);
      setOtpErrorMessages(error.response.data.message);
      setOTPSuccessMessage();
      // console.log("API" + error)
      // if (axios.isAxiosError(error)) {
      //   console.error('API Error:', error.response?.data || error.message);
      // } else {
      //   console.error('Error:', error.message);
      // }
    }
  };
  return (
    <>
      {isLoading? "Loading...":
        <>
          <div className='position-relative withicon w-100'>
            <Input
              type="hidden"
              name="phone"
              value={phone}
            />
            <Input
              type="text"
              name="otp"
              placeholder="Enter OTP*"
              onChange={(e) => setEnteredOTP(e.target.value)}
              value={enteredOTP}
              label={<span>OTP <span style={{ color: 'red' }}>*</span></span>}
              disabled={isValidationDone}
            />
            {otpSuccessMessage && !otpErrorMessages && (
              <Image src={iconCheck} alt="Check Icon" className="check-icon" />
            )}
            {otpErrorMessages && !otpSuccessMessage  && (
              <Image src={closeCircleImage} alt="Check Icon" className="check-icon" />
            )}
            
          </div>
          {enteredOTP && (
            <Button
              onClick={validateOTPHandler}
              label="Validate OTP"
              className="submit"
              disabled={otpSuccessMessage || isValidationDone}
            />
          )}

          {otpErrorMessages && (
            <p className={`message errormessageApply`}>{otpErrorMessages}</p>
          )}
          {otpSuccessMessage && (
            <p className={`message successMessageApply`}>{otpSuccessMessage}</p>
          )}
        </>
      }

     

    </>
  );
}

export default ValidatePhoneOTP;
