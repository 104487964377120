import React from 'react'
import AccordionFaq from './AccordionFaq';
const lomeLoanEMICalculatorFaqData = [
    { 
        id:1, 
        title: 'How accurate are the EMI calculations from the Home Loan EMI Calculator?', 
        content: 'The calculator uses advanced algorithms for precise EMI calculations, ensuring accuracy in your repayment estimates.' 
    },
    { 
        id:2, 
        title: 'Can I use the EMI Calculator for other types of loans?', 
        content: 'While specifically designed for home loans, our calculator can provide EMI estimates for various loan types with similar repayment structures.' 
    },
    { 
        id:3, 
        title: 'Is the information entered into the calculator secure?', 
        content: "Yes, your data is treated with the utmost confidentiality. We prioritize the security and privacy of your financial details."
    },
    { 
        id:4, 
        title: 'How can I adjust the tenure to see different EMI scenarios?', 
        content: 'Our calculator allows you to easily adjust the loan tenure, providing instant updates on how changes impact your monthly installments.' 
    },
  
];
const HomeLoanEMICalculatorFaqData = () => {
  return (<>{lomeLoanEMICalculatorFaqData.map((item)=>(
    <AccordionFaq key={item.id} title={item.title} content={item.content} />
  ))}</>)
}

export default HomeLoanEMICalculatorFaqData
