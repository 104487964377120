import React from 'react'
import Card from './Cards';
import educationImageOne from '../../../assets/img/education-1.svg';
import educationImageTwo from '../../../assets/img/education-2.svg';
import educationImageThree from '../../../assets/img/education-3.svg';
import educationImageFour from '../../../assets/img/education-4.svg';
const educationAdvantagesDetails = [
    {
        id:1,
        title: 'Empower Your Academic Journey:  ',
        description: 'Our Education Loan is your key to unlocking a world of opportunities & empowers you to pursue the education you desire without financial constraints.',
        imageUrl: educationImageOne,
 
    },
    {
        id:2,
        title: 'Budget-Friendly Education: ',
        description: 'Our competitive interest rates and flexible repayment terms make education financing not only accessible but also budget-friendly.',
        imageUrl: educationImageTwo,
    },
    {
        id:3,
        title: 'Focus on Studies, Not Finances: ',
        description: 'You can focus on your studies without the stress of immediate financial obligations. Our grace period for repayment gives you the space to transition smoothly into your post-education phase.',
        imageUrl: educationImageThree,
      },
      
      {
        id:4,
        title: 'Secure Your Future, Today:  ',
        description: "Invest in your future with our Education Loan. It's not just financing; it's a step towards securing a brighter tomorrow through quality education.",
        imageUrl: educationImageFour,
      },
      
]
const EducationAdvantagesDetails = () => {
  return (
    <>
        {educationAdvantagesDetails.map((card, index) => (
            <Card key={index} {...card} />
        ))}
    </>
  )
}

export default EducationAdvantagesDetails
