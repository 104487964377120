import React from 'react'
import financialPlanningImage from '../../../../assets/img/financialPlanning.svg';
import debtManagementImage from '../../../../assets/img/debtManagement.svg';
import wealthGrowthImage from '../../../../assets/img/wealthGrowth.svg';
import SavingsImage from '../../../../assets/img/Savings.svg';
import FinancialImage from '../../../../assets/img/Financial.svg';
import FinancialHowItWorks from './FinancialHowItWorks';
const financialHowItWorksData = [
    {
        id:1,
        title: 'Precise Financial Planning ',
        description: 'Financial tools provide accurate calculations and projections, enabling individuals to plan and budget with precision, and also help in making informed decisions based on factors like - loan eligibility, calculating EMIs, or estimating returns.',
        imageUrl: financialPlanningImage,
 
    },
    {
        id:2,
        title: 'Strategic Debt Management ',
        description: 'Tools like - Loan Calculators empower individuals to strategize debt management, optimize repayments, reduce interest costs, and achieve financial freedom sooner.',
        imageUrl: debtManagementImage,
    },
    {
        id:3,
        title: 'Wealth Growth and Compounding ',
        description: 'Compound Interest Calculators help individuals understand the power of compounding & by visualizing the growth of investments users can decide to maximize wealth accumulation.',
        imageUrl: wealthGrowthImage,
      },
      
      {
        id:4,
        title: 'Disciplined Savings ',
        description: 'Tools like - RD & FD Calculators encourage disciplined savings & also help individuals plan and visualize growth, fostering financial discipline.',
        imageUrl: SavingsImage,
      },
      {
        id:5,
        title: 'Customized Financial Solutions',
        description: 'Financial tools cater to individual needs, providing customized solutions based on specific financial goals and circumstances.',
        imageUrl: FinancialImage,
      },
      
]
const FinancialHowItWorksData = () => {
  return (
    <>
      {financialHowItWorksData.map((item, index)=>(
        <FinancialHowItWorks key={index} {...item}/>
      ))}
    </>
  )
}

export default FinancialHowItWorksData
