import React from 'react'
import '../ApplyNow/ApplyNow.css'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../molecules/Images/Image'
import Heading from '../../molecules/Headings/Heading'
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs'
import applyNowImage from '../../../assets/img/ApplynowImage.jpg'
import ApplyNowForm from '../../molecules/Form/ApplyNowForm/ApplyNowForm'

const ApplyNow = () => {
  return (
    <>  
        
        <section className='position-relative innerBanner'>
            <Image src ={applyNowImage} alt="About Banner" width="1920" height="450"  />
            <div className='bannerContent'>
            <Container>
                <Row>
                <Col>
                    <Heading tagName="h1" className="h1" title="Apply Now" />
                    <Breadcrumbs />
                </Col>
                </Row>
                
            </Container>
            </div>
        </section>
        <section className='sectionPadding applyForm'>
            <Container>
                <Row>
                    <ApplyNowForm />
                </Row>
            </Container>
        </section>
    </>
  )
}

export default ApplyNow
