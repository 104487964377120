import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../../molecules/Images/Image'
import Heading from '../../../molecules/Headings/Heading'
import Breadcrumbs from '../../../atoms/Breadcrumbs/Breadcrumbs'
import financialInnerImage from '../../../../assets/img/financialInnerImage.jpg'
import HomeLoanCalculator from '../../../templates/HomeLoanCalculator.jsx/HomeLoanCalculator'
import HomeLoanEMICalculatorFaq from '../../../templates/Faq/HomeLoanEMICalculatorFaq'
import AboutImage1 from "../../../../assets/img/About-1.png"
import AboutIcon1 from "../../../../assets/img/About-icon1.svg"
import AboutIcon2 from "../../../../assets/img/About-icon2.svg"
import AboutIcon3 from "../../../../assets/img/About-icon3.svg"
import AboutIcon4 from "../../../../assets/img/About-icon4.svg"
const HomeLoanEMICalculator = () => {
    return (
        <>
           
            <section className='position-relative innerBanner'>
                <Image src={financialInnerImage} alt="About Banner" width="1920" height="450" />
                <div className='bannerContent'>
                    <Container>
                        <Row>
                            <Col>
                                <Heading tagName="h1" className="h1" title="Home Loan EMI Calculator" />
                                <Breadcrumbs />
                            </Col>
                        </Row>
                        
                    </Container>
                </div>
            </section>
            <section className='calulatorSection sectionPadding'>
                <Container>
                    <Row className='calulator--inner'>
                        <Heading tagName="h2" className="h2" title="Home Loan EMI Calculator" />
                        <Heading tagName="h4" className="h4" title="Plan Your Dream Home with Precision" />
                        <p>Our Home Loan EMI Calculator is a powerful tool to help you make informed decisions for your home loan requirements. You just need to input your loan amount, interest rate, and tenure to instantly calculate your EMIs. The user-friendly interface provides a clear breakdown of your repayment schedule, empowering you to plan your finances effectively.</p>
                    </Row>
                    <Row className='calulator--inner'>
                        <Col className='col-12'>
                            <HomeLoanCalculator />
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className='homeAbout sectionPadding'>
                <Container>
                    <Row className='AboutTop'>
                        <Col className='col-12 col-lg-6'>
                            <figure className='mb-0'>
                                <Image src={AboutImage1} alt="About" width="650" height="500" className="aboutImage" />
                            </figure>
                        </Col>
                        <Col className='col-12 col-lg-6'>
                            <Heading tagName="h2" className="h2" title="Why Choose Grownet's Home Loan EMI Calculator?" />
                            <Heading tagName="h4" className="h4" title="Take the first step towards homeownership" />
                            <p>Use our Home Loan EMI Calculator to visualize your repayment journey and make informed financial decisions.</p>
                            <ul className='about--list'>
                                <li>
                                    <Image src={AboutIcon1} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Accurate & Precise EMIs Calculations" />
                                </li>
                                <li>
                                    <Image src={AboutIcon2} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Clear and detailed EMI breakdown" />
                                </li>
                                <li>
                                    <Image src={AboutIcon3} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="User-friendly Interface for effortless calculations" />
                                </li>
                                <li>
                                    <Image src={AboutIcon4} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Confident Finance Planning For Your Dream Home" />
                                </li>

                            </ul>
                        </Col>
                    </Row>

                </Container>

            </div>
            <section className='sectionPadding faqSection'>
                <Container>
                    <Row>
                        <HomeLoanEMICalculatorFaq />
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default HomeLoanEMICalculator
