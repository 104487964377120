import React from 'react'
import UspCard from './UpsCard'

const educationFeaturesData=[
    {
        id:1,
        title: 'Tailored Loan Amounts: ',
        description: 'Customize your education financing to match the unique requirements of your chosen academic pursuit that aligns seamlessly with your educational goals.',
    },
    {
        id:2,
        title: 'Competitive Interest Rates: ',
        description: 'Enjoy cost-effective education loans at competitive interest rates to make quality education accessible without compromising your financial stability.',
    },
    {
        id:3,
        title: 'Quick Approval and Disbursement: ',
        description: 'Time is of the essence when it comes to education. Understanding that we ensure a swift approval process, and once approved, funds are disbursed promptly.',
    },
    {
        id:4,
        title: 'Online Account Management: ',
        description: 'Stay in control of your education financing. Manage your Education Loan conveniently through our online account management platform. Monitor your loan status, view repayment schedules, and access important information at your convenience.',
    },
]
const EducationFeaturesData = () => {
  return (
    <>
      {educationFeaturesData.map((item, index)=>(
        <UspCard key={index} {...item}/>
      ))}
    </>
  )
}

export default EducationFeaturesData
