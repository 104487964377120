import React, {useState, useEffect} from 'react'
import useRequest from '../../../useRequest';
import Team from '../TeamCard/Team'
import TeamModal from './TeamModal';
const TeamData = () => {
  const { data: teamMember, sendRequest, isLoading: queryLoading, error: queryError } = useRequest();
  useEffect(() => {
    
    sendRequest({ path: '/team-member-list', key: 'team-member-list',
    // headers: {
    //   'Custom-Header': 'header-value',
    // },
     });
  }, [sendRequest]);
  const [allTeamMember, setAllTeamMember]= useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState("");
  useEffect(()=>{
      if(teamMember){
        setAllTeamMember(teamMember.data)
      }
  }, [teamMember]);
  if (queryLoading) {
    return <p>Loading...</p>;
  }

  if (queryError) {
      return <p>Error: {queryError.message}</p>;
  }
  const openModal = (teamMember) => {
    setSelectedTeamMember(teamMember);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedTeamMember('');
  };
  return (
    <>
      {allTeamMember.length > 0 && allTeamMember.map((item)=>(
        
        <Team 
          key={item.id} 
          imageUrl={item.image_url} 
          title={item.member_name} 
          description={item.designation} 
          onClick={() => openModal(item)}
        />
        
        
      ))}
      <TeamModal 
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title={selectedTeamMember?.member_name}
        description={selectedTeamMember?.designation}
        imageUrl={selectedTeamMember?.image_url}
        memberDetail={selectedTeamMember?.member_detail}
      />
    </>
  )
}

export default TeamData
