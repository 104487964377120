import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../../molecules/Images/Image'
import Heading from '../../../molecules/Headings/Heading'
import Breadcrumbs from '../../../atoms/Breadcrumbs/Breadcrumbs'
import travelLoanImage from '../../../../assets/img/travelLoanImage.jpg'
import FaqServices from '../../../templates/FaqServices/FaqServices'
import LoanCardDetails from '../../../molecules/Card/LoanCardDetails'
import ServicesSingleCard from '../../../molecules/UpsCard/ServicesSingleCard'
import TravelLoanCalculator from '../../../templates/TravelLoanCalculator/TravelLoanCalculator'
const TravelLoan = () => {
  return (
    <>
      
      <section className='position-relative innerBanner'>
        <Image src={travelLoanImage} alt="About Banner" width="1920" height="450" />
        <div className='bannerContent'>
          <Container>
            <Row>
              <Col>
                <Heading tagName="h1" className="h1" title="Travel Loan" />
                <Breadcrumbs />
                
              </Col>
            </Row>
            
          </Container>
        </div>
      </section>
      <section className='calulatorSection sectionPadding'>
        <Container>
          <Row className='calulator--inner'>
            <h2 className='h2'>Travel Loan Calculator - <span style={{'color':'#EF8200'}}>Check Your EMI</span></h2>
            <Heading tagName="h4" className="h4" title="Your Passport to Financial Planning" />
            <p>At Grownet, we understand that your travel aspirations are unique, and so are your financial needs. That's why we have developed our indigenous Travel Loan Calculator—an intuitive tool designed to empower you with the information you need to plan your dream journey. This calculator provides personalized loan estimates based on your preferred loan amount, interest rate, and repayment period, ensuring that your travel plans align with your financial goals.</p>
          </Row>
          <Row className='calulator--inner'>
            <Col className='col-12'>
              <TravelLoanCalculator />
            </Col>
          </Row>
        </Container>
      </section>
      <section className='facilitiesSection sectionPadding'>
        <Container>
          <Row className='usp--inner'>
            <Col md={9} className='text-center'>
              <Heading tagName="h2" className="h2" title="Features" />
              <p className='white'>Explore the world with confidence, backed by Grownet's flexible and competitive travel financing solutions.</p>
              <p className='white'>Let your travel aspirations become a reality. </p>
            </Col>
          </Row>
          <Row className='mt-4 uspCard'>
            <ServicesSingleCard />
          </Row>
        </Container>
      </section>
      <section className='loanDetailsSection sectionTop'>
        <Container>
          <Row className='usp--inner'>
            <Col md={9} className='text-center'>
              <Heading tagName="h2" className="h2" title="Advantages" />
              <p>Embark on a seamless journey to the destinations you have always envisioned. Unleash the explorer in you, as your next adventure awaits! </p>
            </Col>
          </Row>
          <Row className='mt-4 '>
            <LoanCardDetails />
          </Row>
        </Container>
      </section>
      <section className='sectionTop faqSection'>
        <Container>
          <Row>
            <FaqServices />
          </Row>
        </Container>
      </section>
    </>
  )
}

export default TravelLoan
