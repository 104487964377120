import React, {useState, useEffect} from 'react'
// import { useQuery } from 'react-query'
import ServicesCard from './ServicesCard'
import useRequest from '../../../useRequest';

const ServiceData = () => {
  const { data: servicesPage, sendRequest, isLoading: queryLoading, error: queryError } = useRequest();
  useEffect(() => {
    
    sendRequest({ path: '/all-services', key: 'All services',
    // headers: {
    //   'Custom-Header': 'header-value',
    // },
     });
    
  }, [sendRequest]);
  const [allServicesPage, setAllServicesPage]= useState([]);
  useEffect(()=>{
      if(servicesPage){
        setAllServicesPage(servicesPage.data)
      }
  }, [servicesPage]);
  if (queryLoading) {
    return <p>Loading...</p>;
  }

  if (queryError) {
    return <p>Error: {queryError}</p>;
  }
  return (
    <>
      {allServicesPage.length > 0 && allServicesPage.map((item)=>(
        <ServicesCard 
          key={item.id} 
          imageUrl={item.service_image} 
          title={item.name} 
          description={item.description} 
          lists={item.data.gn_service_feature.map((feature) => feature.feature)} 
          linkTo={`/services/${item.url}`}
          id={item.id}
          linkApply={`/services/${item.application_url}`}
          
        />
      ))}
      
    </>
  )
}

export default ServiceData
