import React from 'react'

const TextArea = ({onChange, textValue, className, placeholder}) => {
  return (
    <>
      <textarea
        value={textValue}
        onChange={onChange}
        placeholder={placeholder}
        className={className}
      />
    </>
  )
}

export default TextArea
