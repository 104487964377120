
import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../../molecules/Images/Image'
import Heading from '../../../molecules/Headings/Heading'
import Breadcrumbs from '../../../atoms/Breadcrumbs/Breadcrumbs'
import salaryAdvanceImage from '../../../../assets/img/salaryAdvanceImage.jpg'
import FaqServices from '../../../templates/FaqServices/FaqServices'
import SalaryAdvanceCalculator from '../../../templates/SalaryAdvanceCalculator/SalaryAdvanceCalculator'
import SalaryAdvanceFeaturesData from '../../../molecules/UpsCard/SalaryAdvanceFeaturesData'
import SalaryAdvanceAdvantagesDetails from '../../../molecules/Card/SalaryAdvanceAdvantagesDetails'
const SalaryAdvance = () => {
  return (
    <>
      
      <section className='position-relative innerBanner'>
        <Image src={salaryAdvanceImage} alt="About Banner" width="1920" height="450" />
        <div className='bannerContent'>
          <Container>
            <Row>
              <Col>
                <Heading tagName="h1" className="h1" title="Salary Advance" />
                <Breadcrumbs />
              </Col>
            </Row>
            
          </Container>
        </div>
      </section>
      <section className='calulatorSection sectionPadding'>
        <Container>
          <Row className='calulator--inner'>
            <h2 className='h2'>Salary Advance - <span style={{'color':'#EF8200'}}>Check Your EMI</span></h2>  
            <p>Experience financial flexibility with Grownet's Salary Advance service, designed to provide immediate relief when you need it most.</p>
          </Row>
          <Row className='calulator--inner'>
            <Col className='col-12'>
              <SalaryAdvanceCalculator />
            </Col>
          </Row>
        </Container>
      </section>
      <section className='facilitiesSection sectionPadding'>
        <Container>
          <Row className='usp--inner'>
            <Col md={9} className='text-center'>
              <Heading tagName="h2" className="h2" title="Salary Advance: Features" />
              <p className='white'>Bridge the financial gaps and navigate life's uncertainties with confidence.</p>
            </Col>
          </Row>
          <Row className='mt-4 uspCard'>
            <SalaryAdvanceFeaturesData />
          </Row>
        </Container>
      </section>
      <section className='loanDetailsSection sectionTop'>
        <Container>
          <Row className='usp--inner'>
            <Col md={9} className='text-center'>
              <Heading tagName="h2" className="h2" title="Advantages" />
              {/* <p>Achieve all your goals and aspirations; with the right kind of help, exactly when you need it.</p> */}
            </Col>
          </Row>
          <Row className='mt-4 '>
            <SalaryAdvanceAdvantagesDetails />
          </Row>
        </Container>
      </section>
      <section className='sectionTop faqSection'>
        <Container>
          <Row>
            <FaqServices />
          </Row>
        </Container>
      </section>
    </>
  )
}

export default SalaryAdvance
