
import React from 'react'
import Image from '../../molecules/Images/Image'
import Heading from '../../molecules/Headings/Heading';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import helpCenterImage from '../../../assets/img/Helpcenter.jpg';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
const HelpCenter = () => {
  return (
    <>
        <section className='position-relative innerBanner'>
            <Image src ={helpCenterImage} alt="About Banner" width="1920" height="450"  />
            <div className='bannerContent'>
            <Container>
                <Row>
                    <Col>
                        <Heading tagName="h1" className="h1" title="Help Center" />
                        <Breadcrumbs />
                    </Col>
                </Row>
                
            </Container>
            </div>
        </section>
        <section className='sectionPolicy sectionTop'>
            <Container>
                <Row>
                    <Col className='col-12'>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Grownet Finance - Help Center" />
                            <p className='mb-0'>Our Company is fully aligned with the need for a Support Center to help our clients with their requisite information in the most satisfying manner. We as a regulated entity, a Non-Banking Financial Company (NBFC) in India, have set up a Help Center for providing efficient customer support and resolving queries effectively.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Dedicated Support Channels: " />
                            <p className='mb-0'>The company offers multiple channels for customers to reach out for support, including phone, email, and live chat to be available soon. These channels are displayed on our website and all other communication channels.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Knowledge about Products: " />
                            <p className='mb-0'>A comprehensive knowledge base that contains articles, FAQs, and tutorials covering various aspects of our products/services, account management, loan procedures, interest rates, repayment options, etc are shown on our website and shall be updated as and when necessary.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Tracking System:" />
                            <p className='mb-0'>The company follows a system to manage customer inquiries and complaints efficiently. Each incoming query is assigned to a dedicated staff for quick and satisfactory resolution.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Service Level Agreements (SLAs):" />
                            <p className='mb-0'>The company follows a Customer Grievances Redressal policy with a defined timeline to address the query as well as well defined escalation mechanism. The detailed policy is available on the company website.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Customer Feedback Mechanism: " />
                            <p className='mb-0'>The company is extremely sensitive to customer feedback to keep improving the process, product and technology to meet the expectations of the clients. We genuinely welcome your feedback on support experience to know about customer satisfaction levels and areas for improvement.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Training for Support Staff:" />
                            <p className='mb-0'>The company provides comprehensive training to our support staff on product knowledge, troubleshooting techniques, communication skills, and handling customer inquiries effectively. Regular training sessions and updates are shared to keep the team informed about new products, policies, and procedures.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Escalation Procedures:" />
                            <p className='mb-0'>As we Board board-approved policy, the company lays adequate focus on client feedback and has defined clear escalation procedures for handling complex issues or unresolved complaints.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Data Security and Compliance:" />
                            <p className='mb-0'>The customer data is handled securely and in a strictly compliant manner with relevant data protection regulations such as GDPR and India's data protection laws. The company has implemented measures to safeguard sensitive information and regularly reviews our security protocols.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Multilingual Support:" />
                            <p className='mb-0'>Presently the company offers multiple languages including Hindi, English, and Marathi to offer support to the clients. We shall keep adding more regional languages in sync with our growth plan across geographies.</p>    
                        </div>
                        <div>
                            <Heading tagName="h3" className="h3" title="Continuous Improvement:" />
                            <p className='mb-0'>Various performance indicators are implemented to monitor response time, resolution time, customer satisfaction scores, and first-contact resolution rate. We analyze trends and customer feedback to identify areas for improvement and implement necessary changes to enhance the overall support experience.</p>    
                        </div>
                        
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}

export default HelpCenter
