import React, { useState, useEffect } from 'react';
import SelectField from '../../../atoms/Select/SelectField ';
import useRequest from '../../../../useRequest';
const LoanType = ({ onChange, value }) => {
  const { data: loanData, sendRequest, isLoading: queryLoading, error: queryError } = useRequest();
  const [loanTypes, setLoanTypes] = useState([]);
  useEffect(() => { 
    sendRequest({ path: '/services-list', key: 'services-list',
    // headers: {
    //   'Custom-Header': 'header-value',
    // },
     });
  }, [sendRequest]);
  
  useEffect(() => {
    if (loanData) {
      setLoanTypes(loanData.data);
    }
  }, [loanData]);

  const handleSelectChange = (event) => {
      const selectedOption = event.target.value;
      if (onChange && typeof onChange === 'function') {
        onChange(selectedOption);
      } else {
        console.error('onChange is not a valid function');
      }
  };

  if (queryLoading) {
    return <p>Loading...</p>;
  }

  if (queryError) {
    return <p>Error: {queryError.message}</p>;
  }

  return (
    <SelectField
      label={<span>Type Of Loan <span style={{ color: 'red' }}>*</span></span>}
      options={[
        { label: 'Select a loan type', value: '' },
        ...loanTypes.map((type) => ({ label: type.service_name, value: type.service_name })),
      ]}
      value={value}
      onChange={handleSelectChange}
    />
  );
};

export default LoanType;
