import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../../molecules/Images/Image'
import Heading from '../../../molecules/Headings/Heading'
import Breadcrumbs from '../../../atoms/Breadcrumbs/Breadcrumbs'
import financialInnerImage from '../../../../assets/img/financialInnerImage.jpg'
import PrepaymentCalculator from '../../../templates/PrepaymentCalculator/PrepaymentCalculator'
import AboutImage1 from "../../../../assets/img/About-1.png"
import AboutIcon1 from "../../../../assets/img/About-icon1.svg"
import AboutIcon2 from "../../../../assets/img/About-icon2.svg"
import AboutIcon3 from "../../../../assets/img/About-icon3.svg"
import AboutIcon4 from "../../../../assets/img/About-icon4.svg"
import LoanPrepaymentCalculatorFaq from '../../../templates/Faq/LoanPrepaymentCalculatorFaq'
const LoanPrepaymentCalculator = () => {
    return (
        <>
            
            <section className='position-relative innerBanner'>
                <Image src={financialInnerImage} alt="About Banner" width="1920" height="450" />
                <div className='bannerContent'>
                    <Container>
                        <Row>
                            <Col>
                                <Heading tagName="h1" className="h1" title="Loan Prepayment Calculator" />
                                <Breadcrumbs />
                            </Col>
                        </Row>
                        
                    </Container>
                </div>
            </section>
            <section className='calulatorSection sectionPadding'>
                <Container>
                    <Row className='calulator--inner'>
                        <Heading tagName="h2" className="h2" title="Loan Prepayment Calculator" />
                        <Heading tagName="h4" className="h4" title="Strategize Your Path to Financial Freedom" />
                        <p>Grownet's Loan Prepayment Calculator tool is designed to empower you to manage and pay off your loans efficiently. You can just input your outstanding loan amount, interest rate, remaining tenure, and the prepayment amount to calculate how prepayments impact your loan. Our calculator provides insights into potential interest savings and repayment periods. </p>
                    </Row>
                    <Row className='calulator--inner'>
                        <Col className='col-12'>
                           <PrepaymentCalculator /> 
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className='homeAbout sectionPadding'>
                <Container>
                    <Row className='AboutTop'>
                        <Col className='col-12 col-lg-6'>
                            <figure className='mb-0'>
                                <Image src={AboutImage1} alt="About" width="650" height="500" className="aboutImage" />
                            </figure>
                        </Col>
                        <Col className='col-12 col-lg-6'>
                            <Heading tagName="h2" className="h2" title="Why Use Loan Prepayment Calculator?" />
                            <p>Make informed decisions to accelerate your journey to financial freedom. Take a proactive step towards financial freedom by using our Loan Prepayment Calculator to strategize your loan repayments and optimize your financial journey.</p>
                            <ul className='about--list'>
                                <li>
                                    <Image src={AboutIcon1} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Strategic Planning" />
                                </li>
                                <li>
                                    <Image src={AboutIcon2} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Discover Potential Savings Options" />
                                </li>
                                <li>
                                    <Image src={AboutIcon3} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Explore Different Prepayment Scenarios." />
                                </li>
                                <li>
                                    <Image src={AboutIcon4} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Financial Empowerment" />
                                </li>

                            </ul>
                        </Col>
                    </Row>

                </Container>

            </div>
            <section className='sectionPadding faqSection'>
                <Container>
                    <Row>
                        <LoanPrepaymentCalculatorFaq />
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default LoanPrepaymentCalculator
