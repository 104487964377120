import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../../molecules/Images/Image'
import Heading from '../../../molecules/Headings/Heading'
import Breadcrumbs from '../../../atoms/Breadcrumbs/Breadcrumbs'
import financialInnerImage from '../../../../assets/img/financialInnerImage.jpg'
import RecurringDepositsCalculator from '../../../templates/RecurringDepositsCalculator/RecurringDepositsCalculator'
import AboutImage1 from "../../../../assets/img/About-1.png"
import AboutIcon1 from "../../../../assets/img/About-icon1.svg"
import AboutIcon2 from "../../../../assets/img/About-icon2.svg"
import AboutIcon3 from "../../../../assets/img/About-icon3.svg"
import AboutIcon4 from "../../../../assets/img/About-icon4.svg"
import RDCalculatorFaq from '../../../templates/Faq/RDCalculatorFaq'
const RdCalculator = () => {
    return (
        <>  
           
            <section className='position-relative innerBanner'>
                <Image src={financialInnerImage} alt="About Banner" width="1920" height="450" />
                <div className='bannerContent'>
                    <Container>
                        <Row>
                            <Col>
                                <Heading tagName="h1" className="h1" title="RD Calculator" />
                                <Breadcrumbs />
                            </Col>
                        </Row>
                        
                    </Container>
                </div>
            </section>
            <section className='calulatorSection sectionPadding'>
                <Container>
                    <Row className='calulator--inner'>
                        <Heading tagName="h2" className="h2" title="RD Calculator" />
                        <Heading tagName="h4" className="h4" title="Secure Your Future with Disciplined Savings" />
                        <p>Our Recurring Deposit (RD) Calculator is key to planning disciplined savings and securing your financial future. To use it efficiently, you just need to feed your monthly deposit, interest rate, tenure, and compounding frequency to calculate the maturity amount of your recurring deposit. Our calculator provides a clear projection of your savings, empowering you to plan your financial goals.</p>
                    </Row>
                    <Row className='calulator--inner'>
                        <Col className='col-12'>
                            <RecurringDepositsCalculator />
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className='homeAbout sectionPadding'>
                <Container>
                    <Row className='AboutTop'>
                        <Col className='col-12 col-lg-6'>
                            <figure className='mb-0'>
                                <Image src={AboutImage1} alt="About" width="650" height="500" className="aboutImage" />
                            </figure>
                        </Col>
                        <Col className='col-12 col-lg-6'>
                            <Heading tagName="h2" className="h2" title="Why Choose Grownet's RD Calculator?" />
                            <p>Take control of your savings journey. Use Grownet's RD Calculator to plan and visualize the growth of your recurring deposits, helping you achieve your financial milestones.</p>
                            <ul className='about--list'>
                                <li>
                                    <Image src={AboutIcon1} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Accurate Projections of RD Maturity Amount" />
                                </li>
                                <li>
                                    <Image src={AboutIcon2} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Explore Different Savings Scenarios." />
                                </li>
                                <li>
                                    <Image src={AboutIcon3} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Transparent Results" />
                                </li>
                                <li>
                                    <Image src={AboutIcon4} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Achieve Disciplined Savings" />
                                </li>

                            </ul>
                        </Col>
                    </Row>

                </Container>

            </div>
            <section className='sectionPadding faqSection'>
                <Container>
                    <Row>
                        <RDCalculatorFaq />
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default RdCalculator
