import React, { useEffect, useState } from 'react'
import axios from 'axios'

import Col from 'react-bootstrap/esm/Col'
import Heading from '../../Headings/Heading'
import LoanType from '../Eligibility/LoanType'
import Input from '../../../atoms/Input/Input'
import Button from '../../../atoms/Button/Button'
// import GenerateEmailOTP from '../GenerateEmailOTP/GenerateEmailOTP'
//import ValidateOTP from '../ValidateOTP/ValidateOTP'
import StateDropdown from '../StateDropdown/StateDropdown'
import CityDropdown from '../CityDropdown/CityDropdown'
import Modal from 'react-modal';
import iconCheck from '../../../../assets/img/icon-check.svg'
import closeCircleImage from '../../../../assets/img/close-circle-otp.svg'
import Image from '../../Images/Image'
import TermsModal from '../../TermsPopup/TermsPopup'
//import ValidatePhoneOTP from '../ValidateOTP/ValidatePhoneOtp'


// import ReCAPTCHA from 'react-google-recaptcha';
const ApplyNowForm = () => {
  Modal.setAppElement('#root');
  const [loanApplication, setLoanApplication] = useState({
    loan_type: '',
    loan_amount: '',
    monthly_income: '',
    location:'',
    full_name: '',
    email: '',
    phone: '',
    marital_status: '',
    date_of_birth: '',
    employee_status: '',
    zip_code: '',
    pan_no: '',
    aadhar_no: '',
    state: '',
    city: '',
    pan_doc: '',
    pan_back: '',
    aadhar_front: '',
    aadhar_back: '',
    term_and_condition: '',
    privacy_policy: '',
    aadhar_consent: false,
    // address_line_one:'',
    // address_line_two:'',
    // gender:'',
    //pan_consent: false
  });
  const [consetvalue, setConsetvalue]=useState(false)
  const [errorMessages, setErrorMessages] = useState('');
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [emailSuccessMessage, setEmailSuccessMessage] = useState('');
  const [validatedEmail, setValidatedEmail] = useState('');
  const [isValidEmailOtp, setIsValidEmailOtp] = useState(false);
  // const [captchaValue, setCaptchaValue] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);
  /*--Phone*/
  const [phoneError, setPhoneError] = useState('');
  const [otpGeneratedPhone, setOtpGeneratedPhone] = useState(false);
  const [phoneSuccessMessage, setPhoneSuccessMessage] = useState('');
  const [validatedPhone, setValidatedPhone] = useState('');
  const [buttonClickedPhone, setButtonClickedPhone] = useState(false);
  const [isPhoneLastThreeDigit, setIsPhoneLastThreeDigit] = useState(null);
  /*--PanCard*/
  const [panCardError, setPanCardError] = useState('');
  const [panCardSuccessMessage, setPanCardSuccessMessage] = useState('');
  const [validatedPanCard, setValidatedPanCard] = useState('');
  /*--AadharCard*/
  const [aadharError, setAadharError] = useState('');
  const [aadharSuccessMessage, setAadharSuccessMessage] = useState('');
  const [validatedAadharCard, setValidatedAadharCard] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  /*Validate OTP*/
  const [enteredOTP1, setEnteredOTP1] = useState('');
  const [otpErrorMessages1, setOtpErrorMessages1] = useState({});
  const [otpSuccessMessage1, setOTPSuccessMessage1] = useState('');
  const [isValidationDone1, setIsValidationDone1] = useState(false);

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isEnableAll, setIsEnableAll] = useState(true);
  const [isEmailEnableAll, setIsEmailEnableAll] = useState(true);
  /*Phone*/
  const [enteredOTP, setEnteredOTP] = useState('');
  const [otpErrorMessages, setOtpErrorMessages] = useState();
  const [otpSuccessMessage, setOTPSuccessMessage] = useState('');
  const [isValidationDone, setIsValidationDone] = useState(false);
  const [isPhoneNotValidError, setIsPhoneNotValidError] = useState(false);
  const [isValidAllPanDetals, setAllPanDetails] = useState(false);

  const [isPanCardValid, setIsPanCardValid] = useState(false);
  /*submit */
  const [isFormValid, setIsFormValid] = useState(true);


  const [isEnableCountry, setisEnableCountry] = useState(false);
 
  // const [isValid, setIsValid] = useState(false);

  // const [panCardName, setPanCardName] = useState('');
  // const [isOtpVerified, setIsOtpVerified]= useState(false)
  const handleFileChange = (name, file) => {
    setLoanApplication((prevData) => ({
      ...prevData,
      [name]: file,
    }));
  };


  const handleStateChange = (selectedState) => {
    setSelectedState(selectedState);

    setLoanApplication((prevData) => ({
      ...prevData,
      state: selectedState,
    }));
    applyNowHandlar({
      target: {
        name: 'state',
        value: selectedState,
      },
    });
  };

  const handleCityChange = (selectedCity) => {
    setLoanApplication((prevData) => ({
      ...prevData,
      city: selectedCity,

    }));

    applyNowHandlar({
      target: {
        name: 'city',
        value: selectedCity,
      },
    });
  };
  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, '');
    const truncatedValue = numericValue.slice(0, 10);
    applyNowHandlar({
      target: {
        name: 'phone',
        value: truncatedValue,
      },
    });

    setIsPhoneNotValidError(truncatedValue.length !== 10);
    
  };
  const MAX_FILE_SIZE_BYTES = 1024 * 1024; 
const SUPPORTED_FILE_TYPES = [
  'image/jpeg', 'image/png', 
  'image/jpg', 'image/JPG', 'image/JPEG', 'image/PNG'
];
  const applyNowHandlar = (e) => {
    const { name, value } = e.target;
    let errorMessage = '';
    // if (e.target.type === 'file') {
    //   const file = e.target.files[0];
    //   if (file && file.size > MAX_FILE_SIZE_BYTES) {
    //     setErrorMessages((prevMessages) => ({
    //       ...prevMessages,
    //       [name]: 'File size should not exceed 1024 KB.',
    //     }));
    //     return;
    //   } else {
    //     setErrorMessages((prevMessages) => ({
    //       ...prevMessages,
    //       [name]: '',
    //     }));
    //   }
    //   handleFileChange(name, file);
    // } else {
    //   setLoanApplication((prevData) => ({
    //     ...prevData,
    //     [name]: value,
    //   }));
    // }
    if (e.target.type === 'file') {
      const file = e.target.files[0];
  
      if (!file) {
        setErrorMessages((prevMessages) => ({
          ...prevMessages,
          [name]: 'No file selected.',
        }));
        return;
      }
  
      if (!SUPPORTED_FILE_TYPES.includes(file.type)) {
        setErrorMessages((prevMessages) => ({
          ...prevMessages,
          [name]: 'Please select a JPEG, PNG or valid image. ',
        }));
        return;
      }
  
      if (file.size > MAX_FILE_SIZE_BYTES) {
        setErrorMessages((prevMessages) => ({
          ...prevMessages,
          [name]: 'File size should not exceed 1024 KB.',
        }));
        return;
      } else {
        setErrorMessages((prevMessages) => ({
          ...prevMessages,
          [name]: '',
        }));
      }
  
      handleFileChange(name, file);
    } else {
      setLoanApplication((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    if (name === 'loan_amount') {
      const numericValue = Number(value);
      if (isNaN(numericValue)) {
        setErrorMessages((prevMessages) => ({
          ...prevMessages,
          [name]: 'Loan amount must be a numeric value.',
        }));
        return;  
      }
      if (Number(value) > 1000000) {
        errorMessage = 'Loan amount cannot exceed 10 lakh.';
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
        return;  
      }
    }
    if (name === 'date_of_birth') {
      const dob = new Date(value);
      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
  
      if (dob > eighteenYearsAgo) {
        errorMessage = 'Applicant must be at least 18 years old.';
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
        return; 
      }
    }
    if ((name === 'loan_amount' || name === 'monthly_income' || name === 'zip_code') && isNaN(value)) {
      return;
    }
    
    
  
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      [name]: (typeof value === 'string' ? value.trim() : value) ? '' : `${name.replace(/_/g, ' ')} is required.`,
    }));
    setSuccessMessage('');
    setIsPhoneLastThreeDigit('')
  };
  
  // const handleValidationDone = (isEmailValid) => {
  //   setValidatedEmail(isEmailValid);
  //   setIsOtpVerified(true);
  // };
  // const handlePhoneValidationDone = (isPhoneValid, status) => {
  //   if (isPhoneValid && status === 'success') {
  //     setValidatedPhone(true);
  //     setOtpGeneratedPhone(false);
  //   } else {
  //     setValidatedPhone(false);
  //     setPhoneError('Phone validation failed. Please try again.');
  //   }
  // };
  // const handleChangeTrems = (e) => {
  //   const { name, value, checked, type } = e.target;
  //   if (type === 'checkbox') {
  //     setLoanApplication((prevData) => ({
  //       ...prevData,
  //       [name]: checked,
  //     }));

  //   } else {

  //     setLoanApplication((prevData) => ({
  //       ...prevData,
  //       [name]: value,
  //     }));
  //   }



  //   setSuccessMessage('');
    
  // };
  // const handleChangePrivacy = (e) => {
  //   const { name, value, checked, type } = e.target;
  //   if (type === 'checkbox') {
  //     setLoanApplication((prevData) => ({
  //       ...prevData,
  //       [name]: checked,
  //     }));

  //   } else {

  //     setLoanApplication((prevData) => ({
  //       ...prevData,
  //       [name]: value,
  //     }));
  //   }


  //   setSuccessMessage('');
  // };
  const handleChangePanConsent = async  (e) => {
    const { name, checked } = e.target;
    // if (!loanApplication.pan_no) {
    //   return;
    // }
    setLoanApplication((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      consetvalue: '', 
    }));

  
  if (checked) {
    try {   
      const panData = {
        pan: loanApplication.pan_no,
        // name: loanApplication.full_name,
        // pincode: loanApplication.zip_code,
      }
      const response = await axios.post('https://losapi.grownetfinance.com/api/v1/customer/verifyPAN', panData);

      //console.log('Verify PAN Response:', response.data);

      if (response.status === 200 && response.data.result && response.data.result.data.code === '1000') {
          // const { name,email, address_data } = response.data.result.data.pan_data;
          setPanCardError('');
          setPanCardSuccessMessage(response.data.result.data.message)
          setValidatedPanCard(true);
          setConsetvalue(true);
          setErrorMessages('')
          setAllPanDetails(true)
          setIsPanCardValid(true);   

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
              //pan_consent: response.data.result.data.message,
              // name: name,
              // email: email,       
              // pincode: address_data.pincode,
        }));
       
        //setPanCardName(response.data.result.data.pan_data.name);
        if(response.data.result.data.pan_data.name.toUpperCase()!==loanApplication.full_name.toUpperCase()){
          setErrorMessages((prev) => ({ ...prev, full_name: 'Name not matched with Pan card' }));
          setConsetvalue(false)
          setAllPanDetails(false)
          setIsPanCardValid(false);   
          setPanCardError("Pan card details not matched ,enter corret details above")
          setPanCardSuccessMessage('')
        }
        if(response.data.result.data.pan_data.date_of_birth!==loanApplication.date_of_birth){
          setErrorMessages((prev) => ({ ...prev, date_of_birth: 'DOB not matched with Pan card' }));
          setConsetvalue(false)
          setAllPanDetails(false)
          setIsPanCardValid(false);   
          setPanCardError("Pan card details not matched ,enter corret details above")
          setPanCardSuccessMessage('')
        }
        if(response.data.result.data.pan_data.address_data.pincode!==loanApplication.zip_code){
          setErrorMessages((prev) => ({ ...prev, zip_code: 'Zip code not matched with Pan card' }));
          setConsetvalue(false)
          setAllPanDetails(false)
          setIsPanCardValid(false);   
          setPanCardError("Pan card details not matched ,enter corret details above")
          setPanCardSuccessMessage('')
        }

      
        // if(response.data.result.data.pan_data.address_data.state.toUpperCase()!==loanApplication.state.toUpperCase()){
        //   setErrorMessages((prev) => ({ ...prev, state: 'State not matched with Pan card' }));
        //   // setLoanApplication(false)
        //   setConsetvalue(false)
        // }
        

      } else {
          setPanCardError(response.data.result.data.message);
          setPanCardSuccessMessage('');
          setValidatedPanCard(false);
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            //pan_consent: response.data.result.data.message,
          }));
      }
    } catch (error) {
        console.error('Verify PAN API Error:', error.response?.data || error.message);
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          consetvalue: error.response?.data?.message,
        }));
    }
  }
  };
  
  const handleChangeAadharConsent = async (e) => {
    const { name, checked } = e.target;
    if (!loanApplication.aadhar_no.trim()) {
      return;
    }

    setLoanApplication((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      aadhar_consent: '', 
    }));

  
  if (checked) {
    try {
     
      const AadhaarData = {
        aadhaar: loanApplication.aadhar_no
      }
      const response = await axios.post('https://losapi.grownetfinance.com/api/v1/customer/verifyAadhaar', AadhaarData);

      console.log('Verify Aadhaar Response:', response.data);

      if (response.status === 200 && response.data.result.data &&  response.data.result.data) {
          setAadharError('');
          setAadharSuccessMessage(response.data.result.data.message)
          setValidatedAadharCard(true);
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            //aadhar_consent: response.data.result.data.message,
        }));

        const lastThreeDigits = response.data.result.data.aadhaar_data.mobile.slice(-3);
        const lastThreeDigitPhone = loanApplication.phone.slice(-3);
      
        if (lastThreeDigits === lastThreeDigitPhone) { 
          setErrorMessages((prev) => ({
            ...prev,
            phone: '',
          }));
          setSuccessMessage('');
          setPhoneSuccessMessage('')
          setIsPhoneLastThreeDigit('Phone number last three digits match with Aadhaar card');
        } else {
          //alert('hello')
          setErrorMessages((prev) => ({ ...prev, phone: 'Phone number last three digits do not match with Aadhaar card' }));
          setIsPhoneLastThreeDigit('');
          setSuccessMessage('');
          setPhoneSuccessMessage('')
        }
      } else {          
          setAadharError(response.data.result.message);
          setAadharSuccessMessage('');
          setValidatedAadharCard(false);
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            //aadhar_consent: response.data.result.data.message,
          }));
      }
    } catch (error) {
        console.error('Verify Aadhar API Error:', error.response?.data || error.message);
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          consetvalue: error.response?.data?.message,
        }));
      }
    }
  };
  const validatePanCard = (pan) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panRegex.test(pan);
  };

  const handlePanChange = (e) => {
    const { name, value } = e.target;
    setLoanApplication((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      pan_no: '', 
    }));
    setPanCardError(''); 
    setValidatedPanCard(false);
    const alphaNumericValue = e.target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
    const truncatedValue = alphaNumericValue.slice(0, 10);
    applyNowHandlar({
      target: {
        name: 'pan_no',
        value: truncatedValue,
      },
    });

    if (!validatePanCard(truncatedValue) && truncatedValue.length === 10) {
      setErrorMessages((prev) => ({ ...prev, pan_no: 'Invalid PAN number' }));
    } else {
      setErrorMessages((prev) => ({ ...prev, pan_no: '' }));
    }
  };
  const handleAadharChange = (e) => {
    const { name, value } = e.target;
    setLoanApplication((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      aadhar_no: '',
    }));
    setAadharError(''); 
    setValidatedAadharCard(false); 
  };
  const applySubmitHandler = async (e) => {
    e.preventDefault();
    if (loading) return;
    // let errors = {};
    // if (!loanApplication.location) {
    //   errors.location = "Location is required";
    // }
    setLoading(true);
    const requiredFields = [
      'loan_type',
      'loan_amount',
      'monthly_income',
      // 'location',
      'full_name',
      'email',
      'phone',
      'marital_status',
      'date_of_birth',
      'employee_status',
      'zip_code',
      'pan_no',
      'aadhar_no',
      'state',
      'city',
      'pan_doc',
      'pan_back',
      'aadhar_front',
      'aadhar_back',
      'term_and_condition',
      'privacy_policy',
      'aadhar_consent',
      'consetvalue',
      // 'address_line_one',
      // 'gender',
    ];

    const newErrorMessages = {};
    requiredFields.forEach((field) => {
      const value = loanApplication[field];
      if (typeof value === 'string' && !value.trim()) {
        newErrorMessages[field] = `${field.replace(/_/g, ' ')} is required.`;
      }

    });
    setErrorMessages(newErrorMessages);
    
    if (Object.keys(newErrorMessages).length > 0) {
      setLoading(false);
      return;
    }
    /** */
    try {   
      const panData = {
        pan: loanApplication.pan_no,
        // name: loanApplication.full_name,
        // pincode: loanApplication.zip_code,
      }
      const response = await axios.post('https://losapi.grownetfinance.com/api/v1/customer/verifyPAN', panData);

      //console.log('Verify PAN Response:', response.data);

      if (response.status === 200 && response.data.result && response.data.result.data.code === '1000') {
          // const { name,email, address_data } = response.data.result.data.pan_data;
          setPanCardError('');
          setPanCardSuccessMessage(response.data.result.data.message)
          setValidatedPanCard(true);

          setAllPanDetails(true)
          setIsPanCardValid(true);   
          
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
             
        }));
       



        let isValidData= false;


        if(response.data.result.data.pan_data.name.toUpperCase()!==loanApplication.full_name.toUpperCase()){
          setErrorMessages((prev) => ({ ...prev, full_name: 'Name not matched with Pan card' }));
          setConsetvalue(false)
          setAllPanDetails(false)
          setIsPanCardValid(false);   
          setPanCardError("Pan card details not matched ,enter corret details above")
          setPanCardSuccessMessage('')

          setIsFormValid(false)
          isValidData =  true;
        }

        if(response.data.result.data.pan_data.date_of_birth!==loanApplication.date_of_birth){
          setErrorMessages((prev) => ({ ...prev, date_of_birth: 'DOB not matched with Pan card' }));
          setConsetvalue(false)
          setAllPanDetails(false)
          setIsPanCardValid(false);   
          setPanCardError("Pan card details not matched ,enter corret details above")
          setPanCardSuccessMessage('')

          setIsFormValid(false)
          isValidData =  true;
        }
        if(response.data.result.data.pan_data.address_data.pincode!==loanApplication.zip_code){
          setErrorMessages((prev) => ({ ...prev, zip_code: 'Zip code not matched with Pan card' }));
          setConsetvalue(false)
          setAllPanDetails(false)
          setIsPanCardValid(false);   
          setPanCardError("Pan card details not matched ,enter corret details above")
          setPanCardSuccessMessage('')

          setIsFormValid(false)
          isValidData =  true;
        }

        
        if(isValidData){
          alert("Please fill correct information")
          setLoading(false);
          isValidData =  false;
          return
        }
       

       

      } else {
          setPanCardError(response.data.result.data.message);
          setPanCardSuccessMessage('');
          setValidatedPanCard(false);
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            //pan_consent: response.data.result.data.message,
          }));
      }
    } catch (error) {
        console.error('Verify PAN API Error:', error.response?.data || error.message);
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          pan_consent: error.response?.data?.message,
        }));
    }

/** */

    try {
      const formApplyNowData = new FormData();
      formApplyNowData.append('loan_type', loanApplication.loan_type);
      formApplyNowData.append('loan_amount', loanApplication.loan_amount);
      formApplyNowData.append('monthly_income', loanApplication.monthly_income);
      formApplyNowData.append('location', loanApplication.location);
      formApplyNowData.append('full_name', loanApplication.full_name);
      formApplyNowData.append('email', loanApplication.email);
      formApplyNowData.append('phone', loanApplication.phone);
      formApplyNowData.append('marital_status', loanApplication.marital_status);
      formApplyNowData.append('date_of_birth', loanApplication.date_of_birth);
      formApplyNowData.append('employee_status', loanApplication.employee_status);
      formApplyNowData.append('zip_code', loanApplication.zip_code);
      formApplyNowData.append('pan_no', loanApplication.pan_no);
      formApplyNowData.append('aadhar_no', loanApplication.aadhar_no);
      formApplyNowData.append('state', loanApplication.state);
      formApplyNowData.append('city', loanApplication.city);
      formApplyNowData.append('pan_doc', loanApplication.pan_doc);
      formApplyNowData.append('pan_back', loanApplication.pan_back);
      formApplyNowData.append('aadhar_front', loanApplication.aadhar_front);
      formApplyNowData.append('aadhar_back', loanApplication.aadhar_back);
      formApplyNowData.append('term_and_condition', formApplyNowData.term_and_condition);
      formApplyNowData.append('privacy_policy', formApplyNowData.privacy_policy);
      formApplyNowData.append('aadhar_consent', formApplyNowData.aadhar_consent);
      formApplyNowData.append('pan_consent', formApplyNowData.consetvalue);
      // formApplyNowData.append('address_line_one', formApplyNowData.address_line_one);
      // formApplyNowData.append('address_line_two', formApplyNowData.address_line_two);
      // formApplyNowData.append('gender', formApplyNowData.gender);
      

      //console.log('FormData API PARAMS:', loanApplication.email);

      const response = await axios.post('https://api.grownetfinance.com/api/loan-application', formApplyNowData,
        // {
        //   headers: {
        //     'Authorization': process.env.REACT_APP_SECRET_KEY, 
        //   }

        // }
      );

      //console.log('API Response:', response);

      if (response.data === 200) {
        if (response.data.status === true) {
          setSuccessMessage(response.data.message);
          setErrorMessages({});
          setModalIsOpen(true);
          
          
          //console.log('Modal State:', modalIsOpen);
        } else {
          throw new Error(response.data.message);
        }



      } else {
        setSuccessMessage(response.data.message);
        setModalIsOpen(true);
        
        console.log('Modal State:', modalIsOpen);
        setLoanApplication({
          loan_type: '',
          loan_amount: '',
          monthly_income: '',
          location:'',
          full_name: '',
          email: '',
          phone: '',
          marital_status: '',
          date_of_birth: '',
          employee_status: '',
          zip_code: '',
          pan_no: '',
          aadhar_no: '',
          state: '',
          city: '',
          pan_doc: '',
          pan_back: '',
          aadhar_front: '',
          aadhar_back: '',
          term_and_condition:'',
          privacy_policy:'',
          aadhar_consent:'',
          consetvalue:'',
          // address_line_one:'',
          // address_line_two:'',
          // gender:''
        });
        setErrorMessages(response.data.message);
        setIsPhoneNotValidError('')
      }
    } catch (error) {
      console.error('API Error:', error);

    } finally {
      setLoading(false);
    }
  

  };


 const getGeoInfo = (e) => {
    axios.get('https://ipapi.co/json/').then((response) => {
      let blockedCountryLists=['IN'];
      let isBlockedCountry=  blockedCountryLists.some(item => response.data.country === item);
      if(isBlockedCountry){
        setisEnableCountry(false); 
      }else{
        setisEnableCountry(true);
      }
     
    });
  };
  const phoneVerifyHandlar =async (e)=>{
    e.preventDefault();
    setOtpGeneratedPhone(true);
    if (buttonClickedPhone) {
      return;
    }

    setButtonClickedPhone(true);
    const requiredFields = ['phone', loanApplication.phone];
    const newErrorMessages = {};

    requiredFields.forEach((field) => {
      const value = loanApplication.phone.trim();
      if (!value) {
        newErrorMessages[field] = `${field.replace(/_/g, ' ')} is required.`;
      }
    });

    try {
      
      const phoneData = {
        phone: loanApplication.phone,
      };
      const response = await axios.post('https://losapi.grownetfinance.com/api/v1/auth/user/otp', phoneData,
       
      );
      console.log('Response:', response.status);
      if (response.status === 200 && response.data.result === true) {
        setPhoneSuccessMessage("Please check your SMS");
        setValidatedPhone(true);
        setOtpGeneratedPhone(true);
        setIsPhoneLastThreeDigit('')
        //setIsOtpVerified(true);
        setPhoneError(''); 
        setIsValidPhone(false)
      } else {
        setPhoneError("Invalid Phone No");
        setPhoneSuccessMessage('');
        setIsValidPhone(true) 
      }
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      setPhoneError(
        error.response?.data?.message
      );
      setPhoneSuccessMessage(''); 
    } finally {
      setButtonClickedPhone(false);
    }
  }
  const generateOTPHandler = async (e) => {
    e.preventDefault();
    if (buttonClicked) {
      return;
    }
    setButtonClicked(true);
    
    const requiredFields = ['email', loanApplication.email];
    const newErrorMessages = {};

    requiredFields.forEach((field) => {
      const value = loanApplication.email.trim();
      if (!value) {
        newErrorMessages[field] = `${field.replace(/_/g, ' ')} is required.`;
      }
    });

    try {
      const generateOTPFormData = new FormData();
      generateOTPFormData.append('email', loanApplication.email);

      const response = await axios.post('https://api.grownetfinance.com/api/generate-email-otp', generateOTPFormData,
        // {
        //   headers: {
        //     'Authorization': '12345', 
        //   }
        // }
      );
      console.log('Response:', response.data);
      if (response.status === 200 && response.data.status === true) {
        setEmailSuccessMessage(response.data.message);
        setValidatedEmail(true);
        setOtpGenerated(true);
        setEmailError('');
      } else {
        setEmailError(response.data.message || 'Unexpected error occurred');
        setEmailSuccessMessage('');
      }

    }

    catch (error) {
      if (axios.isAxiosError(error)) {
        //console.error('Axios error:', error.response?.data || error.message);
        setEmailError(error.response?.data?.message || error.message);
      } else {
        console.error('Error:', error.message);
        setEmailError(error.message);
      }
      setOtpGenerated(false);
      setEmailSuccessMessage('');
    } finally {
      setButtonClicked(false);
      
    }
  };
  const validateOTPPhoneHandler = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const requiredFields = ['enteredOTP'];
    const newErrorMessages = {};

    requiredFields.forEach((field) => {
      const value = enteredOTP.trim();
      if (!value) {
        newErrorMessages[field] = `${field.replace(/_/g, ' ')} is required.`;
      }
    });

    if (Object.keys(newErrorMessages).length > 0) {
      setOtpErrorMessages(newErrorMessages);
      return;
    }

    try {
      const phoneOtpData = {
        phone: loanApplication.phone, 
        otp: enteredOTP, 
      };
      const response = await axios.post('https://losapi.grownetfinance.com/api/v1/auth/user/verify', phoneOtpData );
      if (response.status === 200 ) {
        setIsLoading(false);
        if (response.data.result === true) {
          setOTPSuccessMessage("Otp Verified Sucessfully");
          setOtpErrorMessages();
          setIsValidationDone(true);
          //onValidateOTP();
          setIsEnableAll(false)
          

        } else if (response.data.status === false) {
          setIsLoading(false);
          setOtpErrorMessages(response.data.result.message);
          setIsValidationDone(true);
          
        } else {
          setIsLoading(false);
          throw new Error(response.data.message);
        }

      } else {
        setIsLoading(false);
        throw new Error('Failed to validate OTP');
      }
    } catch (error) {


      //alert(error.response.data.message);
      setIsLoading(false);
      setOtpErrorMessages(error.response.data.message);
      setOTPSuccessMessage();
      // console.log("API" + error)
      // if (axios.isAxiosError(error)) {
      //   console.error('API Error:', error.response?.data || error.message);
      // } else {
      //   console.error('Error:', error.message);
      // }
    }
  };
  const validateOTPHandler = async (e) => {
    setIsLoading(true);

    e.preventDefault();
    const requiredFields = ['enteredOTP1'];
    const newErrorMessages = {};

    requiredFields.forEach((field) => {
      const value = enteredOTP1.trim();
      if (!value) {
        newErrorMessages[field] = `${field.replace(/_/g, ' ')} is required.`;
      }
    });

    if (Object.keys(newErrorMessages).length > 0) {
      setOtpErrorMessages1(newErrorMessages);
      return;
    }

    try {
      const validateOTPFormData = new FormData();
      validateOTPFormData.append('otp', enteredOTP1);
      validateOTPFormData.append('email', loanApplication.email);
      const response = await axios.post('https://api.grownetfinance.com/api/validate-otp', validateOTPFormData,
      // {
      // headers: {
      //   'Authorization':process.env.REACT_APP_SECRET_KEY, 
      // },
      // }
      );
      
      if (response.status === 200 ) {
        setIsLoading(false);
        if (response.data.status === true) {
          setOTPSuccessMessage1(response.data.message);
          setOtpErrorMessages1('');
          setIsValidationDone1(true);
          //onValidateOTP();
          setIsValidEmail(true);
          setIsValidEmailOtp(true)
          setIsEmailEnableAll(false)

        } else if (response.data.status === false) {
          setIsLoading(false);
          setOtpErrorMessages1({ otp: response.data.message });
          setIsValidationDone1(true);
          setIsValidEmailOtp(false)
          setOTPSuccessMessage1('');
        } else {
          setIsLoading(false);
          setIsValidEmailOtp(false)
          setOTPSuccessMessage1('');
          throw new Error(response.data.message);
        }

      } else {
        setIsLoading(false);
        throw new Error('Failed to validate OTP');
      }
    } catch (error) {
      setIsLoading(false);
      setOTPSuccessMessage1('');
      if (axios.isAxiosError(error)) {
        console.error('API Error:', error.response?.data || error.message);
      } else {
        console.error('Error:', error.message);
      }
    }
  };
  
  const handleCloseModal = (e) => {
    e.preventDefault();
    setModalIsOpen(false);
    setLoanApplication({
      loan_type: '',
      loan_amount: '',
      monthly_income: '',
      location:'',
      full_name: '',
      email: '',
      phone: '',
      marital_status: true,
      date_of_birth: '',
      employee_status: true,
      zip_code: '',
      pan_no: '',
      aadhar_no: '',
      state: '',
      city: '',
      pan_doc: '',
      pan_back: '',
      aadhar_front: '',
      aadhar_back: '',
      term_and_condition:'',
      privacy_policy:'' ,
      aadhar_consent:'',
      consetvalue:'',
      // address_line_one:'',
      // address_line_two:'',
      // gender:'',
    });
    window.location.reload();

  };
  
  useEffect(()=>{
    getGeoInfo()
  }, [])
  
  return (
    <>
      <Col className='col-12'>
        <form className='applyNow' >
          <div className='applyDetails'>
            <div className='Heading'>
              <Heading tagName="h2" className="h2" title="Loan Details" />

            </div>
            <div className='loanDetails'>
              
              <div className='input-group first' >
                <LoanType
                  value={loanApplication.loan_type}
                  onChange={(selectedLoanType) => applyNowHandlar({ target: { name: 'loan_type', value: selectedLoanType } })}
                />
                {errorMessages.loan_type && (
                  <p className='errormessage'>{errorMessages.loan_type}</p>
                )}
              </div>
              <div className='input-group last' >

                <Input
                  type="text"
                  name="loan_amount"

                  placeholder="Loan Amount*"
                  value={loanApplication.loan_amount}
                  onChange={applyNowHandlar}
                  label={<span>Loan Amount <span style={{ color: 'red' }}>*</span></span>}
                  maxLength={7}
                 
                />
                {errorMessages.loan_amount && (
                  <p className='errormessage'>{errorMessages.loan_amount}</p>
                )}
              </div>
              <div className='input-group first' >
                <Input
                  type="text"
                  name="monthly_income"
                  placeholder="Monthly Income"
                  value={loanApplication.monthly_income}
                  onChange={applyNowHandlar}

                  label={<span>Monthly Income <span style={{ color: 'red' }}>*</span></span>}
                />
                {errorMessages.monthly_income && (
                  <p className='errormessage'>{errorMessages.monthly_income}</p>
                )}
              </div>
              {loanApplication.loan_type === 'Travel Loan' && ( 
                <div className='input-group radioButton last'>
                  <label className='mainLabel'>
                    Travel Location<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className='d-flex w-100 align-items-center'>
                    <label className='d-flex position-relative align-items-center radioLabel'>
                      Domestic
                      <input
                        type="radio"
                        name="location"
                        value="Domestic"
                        checked={loanApplication.location === 'Domestic'}
                        onChange={applyNowHandlar}
                      />
                    </label>
                    <label className='d-flex position-relative pe-5 align-items-center radioLabel'>
                      International
                      <input
                        type="radio"
                        name="location"
                        value="International"
                        checked={loanApplication.location === 'International'}
                        onChange={applyNowHandlar}
                      />
                    </label>
                  </div>
                  {/* {errorMessages.location && (
                    <p className='errormessage'>{errorMessages.location}</p>
                  )} */}
                </div>
              )}
              {loanApplication.loan_type === 'Education Loan' && (
              <div className='input-group radioButton last' >
                <label className='mainLabel'>Study Location<span style={{ color: 'red' }}>*</span></label>
                <div className='d-flex w-100 align-items-center'>
                    <label className='d-flex position-relative align-items-center radioLabel'>
                        Domestic
                        <Input
                            type="radio"
                            name="location"
                            value="Domestic"
                            checked={loanApplication.location  === 'Domestic' }
                            onChange={applyNowHandlar}
                        />
                    </label>
                    <label className='d-flex position-relative pe-5 align-items-center radioLabel'>
                        International
                        <Input
                            type="radio"
                            name="location"
                            value="International"
                            checked={loanApplication.location === 'International'}
                            onChange={applyNowHandlar}
                        />
                    </label>
                </div>


                {/* {errorMessages.location && (
                    <p className='errormessage'>{errorMessages.location}</p>
                )} */}
              </div>
              )}   
            </div>
          </div>
          <div className='applyDetails'>
            <div className='Heading'>
              <Heading tagName="h2" className="h2" title="Personal Details" />
            </div>
            <div className='loanDetails'>
              <div className='input-group ' >
                <Input
                  type="text"
                  name="full_name"
                  placeholder="Full Name*"
                  value={loanApplication.full_name}
                  onChange={applyNowHandlar}
                  disabled={isEnableAll || isEmailEnableAll}
                  className="firstName"
                  label={<span>Full Name <span style={{ color: 'red' }}>*</span></span>}
                />
                {errorMessages.full_name && (
                  <p className='errormessage'>{errorMessages.full_name}</p>
                )}
              </div>

              <div className='input-group buttonValidate first' >
                <div className='position-relative withicon w-100'>
                  <Input
                    type="text"
                    name="phone"
                    placeholder="Contact number*"
                    onChange={handlePhoneChange}
                    // onChange={(e) => {
                    //   const numericValue = e.target.value.replace(/\D/g, '');
                    //   const truncatedValue = numericValue.slice(0, 10);
                    //   applyNowHandlar({
                    //     target: {
                    //       name: 'phone',
                    //       value: truncatedValue,
                    //     },
                    //   });
                    
                    // }}
                    value={loanApplication.phone}
                    label={<span>Phone No <span style={{ color: 'red' }}>*</span></span>}
                    minLength={10}
                    maxLength={10}
                    disabled={isValidPhone || isValidationDone}
                  />
                 
                  {validatedPhone && !phoneError && (
                    <Image src={iconCheck} alt="Check Icon" className="check-icon" />
                  )}
                  {phoneError && !validatedPhone && (
                    <Image src={closeCircleImage} alt="Error Icon" className="check-icon" />
                  )}
                  
                  </div>
                  {loanApplication.phone && (
                    
                  <Button
                    type="submit"
                    onClick={phoneVerifyHandlar}
                    label={otpGeneratedPhone ? "Resend" : "Generate OTP"}
                    className={`submit ${buttonClicked ? 'fade' : ''}`}
                    disabled={isValidPhone || validatedPhone === 'status' ||isValidationDone || buttonClicked}
                  />
                )}
                
                {phoneError && (
                  <p className="errormessageApply">{phoneError}</p>
                )}
                {phoneSuccessMessage && (
                  <p className={`message successMessageApply`}>{phoneSuccessMessage}</p>
                )}
                {errorMessages.phone && (
                  <p className="errormessageApply">{errorMessages.phone}</p>
                )}
                {isPhoneNotValidError && !errorMessages.phone && !phoneSuccessMessage &&(
                    <p className="errormessageApply" >Please enter a valid 10-digit phone number.</p>
                  )}
                {isPhoneLastThreeDigit !== null && <p className={`message successMessageApply`}> {isPhoneLastThreeDigit}</p>}
              </div>
              
              {validatedPhone && otpGeneratedPhone && (
                <div className='input-group buttonValidate last'>
                  {/* <ValidatePhoneOTP
                    phone={loanApplication.phone}
                    onValidationDone={handlePhoneValidationDone}
                  /> */}
                  {isLoading? "Loading...":
                    <>
                      <div className='position-relative withicon w-100'>
                        <Input
                          type="hidden"
                          name="phone"
                          value={loanApplication.phone}
                        />
                        <Input
                          type="text"
                          name="otp"
                          placeholder="Phone OTP*"
                          onChange={(e) => setEnteredOTP(e.target.value)}
                          value={enteredOTP}
                          label={<span>Phone OTP <span style={{ color: 'red' }}>*</span></span>}
                          disabled={isValidationDone}
                        />
                        {otpSuccessMessage && !otpErrorMessages && (
                          <Image src={iconCheck} alt="Check Icon" className="check-icon" />
                        )}
                        {otpErrorMessages && !otpSuccessMessage  && (
                          <Image src={closeCircleImage} alt="Check Icon" className="check-icon" />
                        )}
                        
                      </div>
                      {enteredOTP && (
                        <Button
                          onClick={validateOTPPhoneHandler}
                          label="Validate Phone OTP"
                          className="submit"
                          disabled={otpSuccessMessage || isValidationDone}
                        />
                      )}

                      {otpErrorMessages && (
                        <p className={`message errormessageApply`}>{otpErrorMessages}</p>
                      )}
                      {otpSuccessMessage && (
                        <p className={`message successMessageApply`}>{otpSuccessMessage}</p>
                      )}
                    </>
                  }

                </div>
              )}
              <div className='input-group buttonValidate first'>
                <div className='position-relative withicon w-100'>
                  <Input
                    type="text"
                    name="email"
                    placeholder="Email Address*"
                    onChange={applyNowHandlar}
                    value={loanApplication.email}
                    label={<span>Email <span style={{ color: 'red' }}>*</span></span>}
                    disabled={isValidEmail || isEnableAll }
                  />
                  {validatedEmail && !emailError && (
                    <Image src={iconCheck} alt="Check Icon" className="check-icon" />
                  )}
                  {emailError && !validatedEmail && (
                    <Image src={closeCircleImage} alt="Error Icon" className="check-icon" />
                  )}
                </div>
                {loanApplication.email && (
                  
                  <Button
                    type="submit"
                    onClick={generateOTPHandler}
                    label={otpGenerated ? "Resend" : "Generate OTP"}
                    className={`submit ${buttonClicked ? 'fade' : ''}`}
                    disabled={ validatedEmail === 'status' || buttonClicked}
                    
                  />
                )}

                {emailError && (
                  <p className="errormessageApply">{emailError}</p>
                )}
                {emailSuccessMessage && (
                  <p className={`message successMessageApply`}>{emailSuccessMessage}</p>
                )}
                {errorMessages.email && (
                  <p className="errormessageApply">{errorMessages.email}</p>
                )}
              </div>
               {validatedEmail && otpGenerated && (
              <div className='input-group buttonValidate last'>
                  {isLoading? "Loading...":
                    <>
                      <div className='position-relative withicon w-100'>
                        <Input
                          type="hidden"
                          name="email"
                          value={loanApplication.email}
                        />
                        <Input
                          type="text"
                          name="otp"
                          placeholder="Enter OTP*"
                          onChange={(e) => setEnteredOTP1(e.target.value)}
                          value={enteredOTP1}
                          label={<span>OTP <span style={{ color: 'red' }}>*</span></span>}
                          disabled={ isValidEmailOtp}
                        />
                        {otpSuccessMessage1 && !otpErrorMessages1.otp && (
                          <Image src={iconCheck} alt="Check Icon" className="check-icon" />
                        )}
                        {otpErrorMessages1.otp && !otpSuccessMessage1  && (
                          <Image src={closeCircleImage} alt="Check Icon" className="check-icon" />
                        )}
                        
                      </div>
                      {enteredOTP1 && (
                        <Button
                          onClick={validateOTPHandler}
                          label="Validate Email OTP"
                          className="submit"
                          disabled={otpSuccessMessage1 }
                        />
                      )}

                      {otpErrorMessages1.otp && (
                        <p className={`message errormessageApply`}>{otpErrorMessages1.otp}</p>
                      )}
                      {otpSuccessMessage1 && (
                        <p className={`message successMessageApply`}>{otpSuccessMessage1}</p>
                      )}
                    </>
                  }

                </div>
                )}  
              {/* {validatedEmail && otpGenerated && (
                <div className='input-group buttonValidate last'>
                  <ValidateOTP
                    email={loanApplication.email}
                    //onValidationDone={handleValidationDone}
                  />

                </div>
              )} */}
              
              {/* <div className='input-group radioButton first'>
                <label className='mainLabel'>Gender<span style={{ color: 'red' }}>*</span></label>
                <div className='d-flex w-100 align-items-center'>
                  <label className='d-flex position-relative align-items-center radioLabel'>
                    Male
                    <Input
                      type="radio"
                      name="gender"
                      value="Male"
                      disabled={isEnableAll || isEmailEnableAll}
                      checked={loanApplication.gender === 'Male'}
                      onChange={applyNowHandlar}
                    />
                  </label>
                  <label className='d-flex position-relative pe-5 align-items-center radioLabel'>
                    Female
                    <Input
                      type="radio"
                      name="gender"
                      value="Female"
                      disabled={isEnableAll || isEmailEnableAll}
                      checked={loanApplication.gender === 'Female'}
                      onChange={applyNowHandlar}
                    />
                  </label>
                </div>
                {errorMessages.gender && (
                  <p className='errormessage'>{errorMessages.gender}</p>
                )}


              </div> */}

              <div className='input-group radioButton first'>
                <label className='mainLabel'>Marital Status<span style={{ color: 'red' }}>*</span></label>
                <div className='d-flex w-100 align-items-center'>
                  <label className='d-flex position-relative align-items-center radioLabel'>
                    Single
                    <Input
                      type="radio"
                      name="marital_status"
                      value="Single"
                      disabled={isEnableAll || isEmailEnableAll}
                      checked={loanApplication.marital_status}
                      onChange={applyNowHandlar}
                    />
                  </label>
                  <label className='d-flex position-relative pe-5 align-items-center radioLabel'>
                    Married
                    <Input
                      type="radio"
                      name="marital_status"
                      value="Married"
                      disabled={isEnableAll || isEmailEnableAll}
                      checked={loanApplication.marital_status}
                      onChange={applyNowHandlar}
                    />
                  </label>
                </div>
                {errorMessages.marital_status && (
                  <p className='errormessage'>{errorMessages.marital_status}</p>
                )}


              </div>
              <div className='input-group radioButton last'>
                <label className='mainLabel'>Profession <span style={{ color: 'red' }}>*</span></label>
                <div className='d-flex  w-100 align-items-center'>
                  <label className='d-flex position-relative align-items-center radioLabel'>
                    Salaried
                    <Input
                      type="radio"
                      disabled={isEnableAll || isEmailEnableAll}
                      name="employee_status"
                      value="Salaried"
                      checked={loanApplication.employee_status}
                      onChange={applyNowHandlar}
                    />
                  </label>
                  <label className='d-flex position-relative pe-5 align-items-center radioLabel'>
                    Self Business
                    <Input
                      type="radio"
                      disabled={isEnableAll || isEmailEnableAll}
                      name="employee_status"
                      value="Self Business"
                      checked={loanApplication.employee_status}
                      onChange={applyNowHandlar}
                    />
                  </label>
                </div>
                {errorMessages.employee_status && (
                  <p className='errormessage'>{errorMessages.employee_status}</p>
                )}

              </div>
              <div className='input-group first' >
                <Input
                  type="date"
                  name="date_of_birth"
                  disabled={isEnableAll || isEmailEnableAll}
                  value={loanApplication.date_of_birth}
                  onChange={applyNowHandlar}
                  label={<span>Date Of Birth <span style={{ color: 'red' }}>*</span></span>}
                  
                />
                {errorMessages.date_of_birth && (
                  <p className='errormessage'>{errorMessages.date_of_birth}</p>
                )}
              </div>
              <div className='input-group last' >
                <Input
                  type="text"
                  name="zip_code"
                  disabled={isEnableAll || isEmailEnableAll}
                  placeholder="Pin Code"
                  // onChange={applyNowHandlar}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');
                    const truncatedValue = numericValue.slice(0, 6);
                    applyNowHandlar({
                      target: {
                        name: 'zip_code',
                        value: truncatedValue,
                      },
                    });
                  }}
                  value={loanApplication.zip_code}
                  label={<span>Pin Code <span style={{ color: 'red' }}>*</span></span>}

                />
                {errorMessages.zip_code && (
                  <p className='errormessage'>{errorMessages.zip_code}</p>
                )}
              </div>
              
              <div className='input-group first' >
                <label>Country</label>
                <select value="101" onChange={applyNowHandlar}>
                  <option>India</option>
                </select>
              </div>

              <div className='input-group last'>
                <StateDropdown
                  disabled={isEnableAll || isEmailEnableAll}
                  value={loanApplication.state}
                  
                  onSelectState={(selectedState) => {
                    handleStateChange(selectedState);
                    applyNowHandlar({ target: { name: 'state', value: selectedState } });
                  }}
                
                  onChange={(selectedState) => handleStateChange(selectedState)}
                />
                {errorMessages.state && (
                  <p className='errormessage'>{errorMessages.state}</p>
                )}
              </div>


              {selectedState && (
                <div className='input-group first'>
                  <CityDropdown
                    disabled={isEnableAll || isEmailEnableAll}
                    selectedState={selectedState}
                     
                    value={loanApplication.city}
                    onSelectCity={(selectedCity) => {
                      handleCityChange(selectedCity);
                      applyNowHandlar({ target: { name: 'city', value: selectedCity } });
                    }}
                    onChange={(onSelectCity) => handleCityChange(onSelectCity)}

                  />
                  {errorMessages.city && (
                    <p className='errormessage'>{errorMessages.city}</p>
                  )}
                </div>
              )}
              {/* <div className='input-group last' >
                <Input
                  type="text"
                  name="address_line_one"
                  disabled={isEnableAll || isEmailEnableAll}
                  placeholder="Address Line One"
                  onChange={applyNowHandlar}
                  value={loanApplication.address_line_one}
                  label={<span>Address Line One <span style={{ color: 'red' }}>*</span></span>}

                />
                {errorMessages.address_line_one && (
                  <p className='errormessage'>{errorMessages.address_line_one}</p>
                )}
              </div>
              <div className='input-group last' >
                <Input
                  type="text"
                  name="address_line_two"
                  disabled={isEnableAll || isEmailEnableAll}
                  placeholder="Address Line Two"
                  onChange={applyNowHandlar}
                  value={loanApplication.address_line_two}
                  label={<span>Address Line Two </span>}

                />
                {errorMessages.address_line_two && (
                  <p className='errormessage'>{errorMessages.address_line_two}</p>
                )}
              </div> */}

            </div>
          </div>
          <div className='applyDetails'>
            <div className='Heading'>
              <Heading tagName="h2" className="h2" title="KYC Details" />
            </div>
            <div className='loanDetails'>

              <div className='input-group ' >
                <div className='position-relative withicon w-100'>
                  <Input
                    type="text"
                    name="pan_no"
                    placeholder="Pan Number*"
                    value={loanApplication.pan_no}
                    onChange={handlePanChange}
                    label={<span>Pan Number <span style={{ color: 'red' }}>*</span></span>}
                    minLength={10}
                    maxLength={10}
                    pattern="[A-Za-z0-9]*"
                    disabled={isEnableAll || isPanCardValid || isEmailEnableAll}
                  />
                  {validatedPanCard && !panCardError && (
                      <Image src={iconCheck} alt="Check Icon" className="check-icon" />
                    )}
                    {panCardError && !validatedPanCard && (
                      <Image src={closeCircleImage} alt="Error Icon" className="check-icon" />
                    )}
                </div>
                {errorMessages.pan_no && (
                  <p className='errormessage'>{errorMessages.pan_no}</p>
                )}
              </div>
              <div className='input-group checkbox'>
                <label>
                  <input
                    type="checkbox"
                    name="consetvalue"
                    disabled={isEnableAll || isEmailEnableAll}
                    checked={consetvalue}
                    onChange={handleChangePanConsent}
                   
                  />
                  <span>Consent </span>
                </label>
                {panCardError && (
                  <p className="errormessageApply">{panCardError}</p>
                )}
                {panCardSuccessMessage && (
                  <p className={`message successMessageApply`}>{panCardSuccessMessage}</p>
                )}
                {errorMessages.consetvalue && (
                  <p className='errormessage'>{errorMessages.consetvalue}</p>
                )}
                
              </div>
              <div className='input-group first'>
                <Input
                  type="file"
                  name="pan_doc"
                  disabled={isEnableAll || isEmailEnableAll}
                  onChange={applyNowHandlar}
                  label={<span>Pan Card Front <span style={{ color: 'red' }}>*</span></span>}
                />
                {loanApplication.pan_doc && (
                  <div className='imagePrev w-100'>
                    <img src={URL.createObjectURL(loanApplication.pan_doc)} alt="Pan Card" />
                  </div>
                )}
                {errorMessages.pan_doc && (
                  <p className='errormessage'>{errorMessages.pan_doc}</p>
                )}

              </div>
              
              <div className='input-group last' >
                <Input
                  type="file"
                  name="pan_back"
                  disabled={isEnableAll || isEmailEnableAll}
                  onChange={applyNowHandlar}
                  label={<span>Pan Card Back</span>}
                />
                {loanApplication.pan_back && (
                  <div className='imagePrev w-100'>
                    <img src={URL.createObjectURL(loanApplication.pan_back)} alt="Pan Back" />
                  </div>
                )}
                {/* {errorMessages.pan_back && (
                  <p className='errormessage'>{errorMessages.pan_back}</p>
                )} */}

              </div>
              <div className='input-group'>
                <div className='position-relative withicon w-100'>
                
                  <Input
                    type="text"
                    name="aadhar_no"
                    placeholder="Aadhar Number*"
                    value={loanApplication.aadhar_no}
                    onChange={handleAadharChange}
                    label={<span>Aadhar Number<span style={{ color: 'red' }}>*</span></span>}
                    minLength={12}
                    maxLength={12}
                    pattern="\d{12}"
                    disabled={validatedAadharCard || isEnableAll || isEmailEnableAll}
                  />
                  {validatedAadharCard && !aadharError && (
                    <Image src={iconCheck} alt="Check Icon" className="check-icon" />
                  )}
                  {aadharError && !validatedAadharCard && (
                    <Image src={closeCircleImage} alt="Error Icon" className="check-icon" />
                  )}
                </div>
                
                {errorMessages.aadhar_no && (
                  <p className='errormessage'>{errorMessages.aadhar_no}</p>
                )}
              </div>
              <div className='input-group checkbox'>
                <label>
                  <input
                    type="checkbox"
                    name="aadhar_consent"
                    checked={loanApplication.aadhar_consent}
                    disabled={isEnableAll || isEmailEnableAll}
                    onChange={handleChangeAadharConsent}
                    
                  />
                  <span>Consent </span>
                </label>
                {aadharError && (
                  <p className="errormessageApply">{aadharError}</p>
                )}
                {aadharSuccessMessage && (
                  <p className='message successMessageApply'>{aadharSuccessMessage}</p>
                )}
                {errorMessages.aadhar_consent && (
                  <p className='errormessage'>{errorMessages.aadhar_consent}</p>
                )}
                
              </div>
              
              <div className='input-group first' >
                <Input
                  type="file"
                  name="aadhar_front"
                  onChange={applyNowHandlar}
                  disabled={isEnableAll || isEmailEnableAll}
                  label={<span>Aadhar Front<span style={{ color: 'red' }}>*</span></span>}
                />
                {loanApplication.aadhar_front && (
                  <div className='imagePrev w-100'>
                    <img src={URL.createObjectURL(loanApplication.aadhar_front)} alt="Aadhar Front" />
                  </div>
                )}
                {errorMessages.aadhar_front && (
                  <p className='errormessage'>{errorMessages.aadhar_front}</p>
                )}

              </div>
              <div className='input-group last' >
                <Input
                  type="file"
                  name="aadhar_back"
                  disabled={isEnableAll || isEmailEnableAll}
                  onChange={applyNowHandlar}
                  label={<span>Aadhar Back<span style={{ color: 'red' }}>*</span></span>}
                />
                {loanApplication.aadhar_back && (
                  <div className='imagePrev w-100'>
                    <img src={URL.createObjectURL(loanApplication.aadhar_back)} alt="Aadhar Back" />
                  </div>
                )}
                {errorMessages.aadhar_back && (
                  <p className='errormessage'>{errorMessages.aadhar_back}</p>
                )}

              </div>
            </div>
            
            <div className='input-group checkbox'>
              <label>
                <input
                  type="checkbox"
                  name="term_and_condition"
                  checked={loanApplication.term_and_condition}
                  disabled={isEmailEnableAll}
                  onChange={applyNowHandlar}
                />
                <span>I agree to be bound by  the company policy on privacy, <TermsModal />.</span>
              </label>
              {errorMessages.term_and_condition && (
                <p className='errormessage'>{errorMessages.term_and_condition}</p>
              )}
            </div>
            <div className='input-group checkbox'>
              <label>
                <input
                  type="checkbox"
                  name="privacy_policy"
                  checked={loanApplication.privacy_policy }
                  disabled={isEmailEnableAll}
                  onChange={applyNowHandlar}
                />
                <span>I give consent to the company to evaluate my eligibility for the proposed loan and company or its associates may contact me in this regard. </span>
              </label>
              {errorMessages.privacy_policy && (
                <p className='errormessage'>{errorMessages.privacy_policy}</p>
              )}
            </div>

            <div className='applyDetails'>
            <div className='input-group submitButton mb-0' >

                  {
                    isEnableCountry?(`No access for your Location`):(<Button
                      type="submit"
                      label={loading? 'Submitting...' : 'Submit'}
                      className={`submit ${loading ? 'fade' : ''}`}
                      onClick={applySubmitHandler}
                      disabled={loading }
                    />)
                  }  
                
              </div>


              {/* {successMessage && (
                  <div className='succesMessage'>
                    {successMessage}
                  </div>
                )} */}

            </div>

          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="Success Modal"
            className="modalSuccess">
            <div className='modalBody'>
              <p>{successMessage}</p>
              <button type='button' className='buttonClose' onClick={(e) => handleCloseModal(e)}>Close</button>
            </div>

          </Modal>

        </form>
      </Col>

    </>
  )
}

export default ApplyNowForm
