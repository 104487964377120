import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Menu from '../Menu/Menu'
import Image from '../../molecules/Images/Image';
import { Link } from 'react-router-dom';
import logo from '../../../assets/img/logo.svg';
import '../Header/Header.css'
import HeaderButton from '../Menu/HeaderButton';
import Marquee from '../../atoms/Marquee/Marquee';
const Header = () => {
  return (
    <>
     <header className='header'>
        <Navbar expand="lg" >
            <Container>
                <Link to="/"><Image className="logo" src={logo} alt="logo" width="196" height="60" /></Link>
                <Navbar.Toggle aria-controls="basicNavbarNav" />
                <Navbar.Collapse id="basicNavbarNav" className="headerMenu">
                    <Menu />
                    <HeaderButton />
                </Navbar.Collapse>
        </Container>
        </Navbar>
     </header>
     <div className='marqueePosition'><Marquee /></div>
    </>
  )
}
export default Header
