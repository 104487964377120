import React from 'react';
import '../Faq/Faq.css';
import Col from 'react-bootstrap/esm/Col';
import Heading from '../../molecules/Headings/Heading';
import Image from '../../molecules/Images/Image';
import callImage from '../../../assets/img/callicon.svg';
import faqImage from '../../../assets/img/faqImage.jpg';
import CompoundInterestCalculatorFaqData from '../../atoms/Accordion/CompoundInterestCalculatorFaqData';

const CompoundInterestCalculatorFaq = () => {
  
  return (
    <>
      <Col className='col-12 col-lg-6 pe-4'>
        <Heading tagName="h2" className="h2" title="Frequently Asked Questions" />
        <CompoundInterestCalculatorFaqData />
      </Col>
      <Col className='col-12 col-lg-6'>
        <div className='imageSection'>
          <figure className='mb-0'><Image src={faqImage} alt="faq" className={faqImage} width="593" height="539" /></figure>
          <div className='queryCall'>
            <Heading className="h5" tagName="h5" title="You need any help? get free consultation" />
            <div className='d-flex align-items-center'>
              <Image alt="call" className="imageIcon" src={callImage} />
              <div>
                <p>Have Any Questions </p>
                <p>(+91) 848 946 9919</p>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
}

export default  CompoundInterestCalculatorFaq;
