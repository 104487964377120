import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from '../../../molecules/Images/Image'
import Heading from '../../../molecules/Headings/Heading'
import Breadcrumbs from '../../../atoms/Breadcrumbs/Breadcrumbs'
import financialInnerImage from '../../../../assets/img/financialInnerImage.jpg'
import FixedDepositCalculator from '../../../templates/FixedDepositCalculator/FixedDepositCalculator'
import AboutImage1 from "../../../../assets/img/About-1.png"
import AboutIcon1 from "../../../../assets/img/About-icon1.svg"
import AboutIcon2 from "../../../../assets/img/About-icon2.svg"
import AboutIcon3 from "../../../../assets/img/About-icon3.svg"
import AboutIcon4 from "../../../../assets/img/About-icon4.svg"
import FDCalculatorFaq from '../../../templates/Faq/FDCalculatorFaq'

const FDCalculator = () => {
    return (
        <>
            
            <section className='position-relative innerBanner'>
                <Image src={financialInnerImage} alt="About Banner" width="1920" height="450" />
                <div className='bannerContent'>
                    <Container>
                        <Row>
                            <Col>
                                <Heading tagName="h1" className="h1" title="FD Calculator" />
                                <Breadcrumbs />
                            </Col>
                        </Row>
                        
                    </Container>
                </div>
            </section>
            <section className='calulatorSection sectionPadding'>
                <Container>
                    <Row className='calulator--inner'>
                        <Heading tagName="h2" className="h2" title="Fixed Deposit" />
                        <Heading tagName="h4" className="h4" title="Maximize Your Savings Potential with Precision" />
                        <p>Our FD Calculator tool helps you maximize your savings with accurate projections for fixed deposits. To get the result, you need to input your principal amount, interest rate, tenure, and compounding frequency to calculate the maturity amount of your fixed deposit. Our calculator provides clear insights into the growth of your savings.</p>
                    </Row>
                    <Row className='calulator--inner'>
                        <Col className='col-12'>
                            <FixedDepositCalculator />
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className='homeAbout sectionPadding'>
                <Container>
                    <Row className='AboutTop'>
                        <Col className='col-12 col-lg-6'>
                            <figure className='mb-0'>
                                <Image src={AboutImage1} alt="About" width="650" height="500" className="aboutImage" />
                            </figure>
                        </Col>
                        <Col className='col-12 col-lg-6'>
                            <Heading tagName="h2" className="h2" title="Why Choose Grownet's FD Calculator?" />
                            <p>Take charge of your savings journey. Use the FD Calculator to plan and visualize the growth of your fixed deposits, ensuring you make the most of your savings.</p>
                            <ul className='about--list'>
                                <li>
                                    <Image src={AboutIcon1} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Accurate Projections of FD Maturity Amount" />
                                </li>
                                <li>
                                    <Image src={AboutIcon2} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Flexible Inputs To Explore Numerous Savings Scenarios." />
                                </li>
                                <li>
                                    <Image src={AboutIcon3} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Transparent Results" />
                                </li>
                                <li>
                                    <Image src={AboutIcon4} alt="icon" width="" height="" className="" />
                                    <Heading tagName="h6" className="h6" title="Financial Planning" />
                                </li>

                            </ul>
                        </Col>
                    </Row>

                </Container>

            </div>
            <section className='sectionPadding faqSection'>
                <Container>
                    <Row>
                        <FDCalculatorFaq />
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default FDCalculator
