import React from 'react'
import Image from '../../molecules/Images/Image'
import Heading from '../../molecules/Headings/Heading';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import termsImage from '../../../assets/img/terms.jpg';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
const TermsAndCondition = () => {
  return (
    <>
      <section className='position-relative innerBanner'>
            <Image src ={termsImage} alt="About Banner" width="1920" height="450"  />
            <div className='bannerContent'>
            <Container>
                <Row>
                    <Col>
                        <Heading tagName="h1" className="h1" title="Terms and Conditions" />
                        <Breadcrumbs />
                    </Col>
                </Row>
                
            </Container>
            </div>
        </section>
        <section className='sectionPolicy sectionTop'>
            <Container>
                <Row>
                    <Col className='col-12'>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Introduction" />
                            <p>These terms and conditions govern the use of the products, services, content, tools, information and functions offered by Grownet Finance Pvt Ltd, a RBI approved Non-Banking Financial Company registered in Pune, Maharashtra that provides loans and other financial solutions to individuals and businesses. </p>
                            <p className='mb-0'>By using our website, applying for any of our products/services, or otherwise interacting with us, you agree to be bound by these terms and conditions. Please read them carefully.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Loan Products" />
                            <p>The interest rates, fees, eligibility criteria and other terms applicable to our various loan products such as Travel Loans, Salary Advance to employees, Education loans etc. are separately provided on the specific product pages. By applying for any loan, you agree to those product specific terms.</p>
                            <p>Minimum eligibility criteria shall apply for each loan product regarding applicant age, income, credit score, collateral etc. Meeting the eligibility criteria does not guarantee loan approval. Final loan approval and the applicable interest rate and fees thereon will be as per Grownet Finance's sole discretion based on internal guidelines and applicant's profile.</p>
                            <p className='mb-0'>Repayment terms of loan products may vary from 15 days to 10 years. Details of repayment frequency, procedure etc. will be communicated in the sanction letter/loan agreement. Timely repayment as per the agreed terms is the obligation of the borrower.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Charges & Fees" />
                            <p>Processing fees and other applicable fees as detailed in the relevant product section will be charged. Taxes as applicable will be charged additionally on all fees/charges.</p>
                            <p className='mb-0'>Any default in repayment as per the agreed repayment schedule will attract additional interest, penal charges, and fees as per Grownet Finance policies. Delayed payment charges may range from 2% to 10% per month on the overdue amount.</p>    
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Credit Reporting" />
                            <p className='mb-0'>Grownet Finance will report details of any customer who avails a loan facility to credit bureaus as per policies and regulations. This information is accessible by other lenders and financial entities on applicant's creditworthiness. Non-payment or delayed payment of dues may adversely impact applicant's credit rating and profile.</p>
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Security" />
                            <p className='mb-0'>As per the type of loan product availed, suitable collateral security of adequate value may be required. Security may be in the form of immovable property, fixed deposits, shares/MF etc. The required security will be communicated during the loan application process.</p>
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Governing Law" />
                            <p className='mb-0'>These terms and conditions and the use of products/services of Grownet Finance shall be governed by the laws of India. The courts of India shall have exclusive jurisdiction over any disputes arising.</p>
                        </div>
                        <div className='mb-4'>
                            <Heading tagName="h3" className="h3" title="Changes to Terms and Conditions" />
                            <p className='mb-0'>Grownet Finance reserves the right to modify or change these terms and conditions at any time without prior notice. Changed terms and conditions shall be updated on the website. Continued use of Grownet Finance products/services after changes implies acceptance of the revised terms and conditions.</p>
                        </div>
                        <div>
                            <Heading tagName="h3" className="h3" title="Contact Us" />
                            <p className='mb-0'>Reach out to us at <a href='mailto:support@grownetfinance.com'>support@grownetfinance.com</a> for any queries or grievances. Our customer service team will respond to you as soon as possible.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}

export default TermsAndCondition
