import React from 'react'
import AccordionFaq from './AccordionFaq';
const fdCalculatorFaqData = [
    { 
        id:1, 
        title: 'How does the FD Calculator work?', 
        content: 'The calculator uses your principal amount, interest rate, tenure, and compounding frequency to project the maturity amount of your fixed deposit.' 
    },
    { 
        id:2, 
        title: 'Can I use the calculator for different FD schemes?', 
        content: "Yes, the calculator is versatile and can be used for various fixed deposit schemes, allowing you to tailor it to your specific financial institution's terms." 
    },
    { 
        id:3, 
        title: 'Can I adjust the tenure to explore different savings scenarios?', 
        content: "Yes, the calculator allows you to easily modify the tenure, providing instant updates on how changes impact the maturity amount of your fixed deposit."
    },
    { 
        id:4, 
        title: 'How frequently can I compound the interest in the FD Calculator?', 
        content: "The calculator provides flexibility in choosing the compounding frequency, including options for annual, semi-annual, quarterly, or monthly compounding." 
    },
  
];
const FDCalculatorFaqData = () => {
  return (<>{fdCalculatorFaqData.map((item)=>(
    <AccordionFaq key={item.id} title={item.title} content={item.content} />
  ))}</>)
}

export default FDCalculatorFaqData
