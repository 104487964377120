import React from 'react'
import AccordionFaq from './AccordionFaq';
const loanEligibilityFaqItems = [
  { id:1, title: 'How does the Loan Eligibility Tool work?', content: 'Our tool analyzes your financial details, assessing factors like income, credit history, and existing debts to determine your eligibility for various loans.' },
  { id:2, title: 'Is the information provided secure?', content: 'Yes, your information is treated with utmost confidentiality. We prioritize the security and privacy of your financial details.' },
  { id:3, title: 'How accurate are the loan eligibility results?', content: "Grownet's tool utilizes advanced algorithms for precise calculations, providing accurate and reliable loan eligibility insights."},
  { id:4, title: 'Can I apply for a loan directly through the tool?', content: 'The tool provides eligibility insights. For loan applications, visit the respective loan pages on our website or contact our dedicated support team for personalized assistance.' },
  // Add more items as needed
];
const LoanEligibilityFaqData = () => {
  return (<>{loanEligibilityFaqItems.map((item)=>(
    <AccordionFaq key={item.id} title={item.title} content={item.content} />
  ))}</>)
}

export default LoanEligibilityFaqData
