import React from 'react'

import Col from 'react-bootstrap/Col';
import Heading from '../Headings/Heading';

const UspCard = ({title, id, description}) => {
 
  return (
    <>
        <Col className='col-12 col-lg-3 col-md-6 upsCards card border-0 text-center pb-md-4' key={id}>
          <div className='upsInner card-body text-center'>
            <div className='upsId'>
            <Heading tagName="h4" className="h4" title={id} />
            </div>
            <Heading tagName="h5" className="h5" title={title} />
            <p>{description}</p>
          </div>
        </Col>
    </>
  )
}

export default UspCard
